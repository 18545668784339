import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseEndpoint = 'http://localhost:3030/api/';
  accessKey = '79488e09832e91b1a2eadf1cf1f70ff6';
  accessSecret = '3b8d9a3264a8e4672857822e639e8a28f5a0b472';
  baseFe = 'http://localhost:4200';
  
  mapBoxKey = "pk.eyJ1IjoiemlhZGFobGFuODgiLCJhIjoiY2x3NHdtYzU0MWFyczJqcXE5cXJoaWh5YSJ9.WKJ13J8dk1qgPHXNwrDswA";
  
  httpOptions = {
   headers: new HttpHeaders({
    'Content-Type':  'application/json',
	  'X-App-Idx': btoa(this.accessKey+':'+this.accessSecret),
    'Access-Control-Allow-Origin': '*'
   })
  };
  
  constructor(private router: Router, private stateSvc: StateService) { }
  
  getEndpointUrl(){
	return this.baseEndpoint;
  }

  getBaseFe(){
    return this.baseFe;
  }
  
  getHttpHeader(){
	//return this.httpOptions;
	return new HttpHeaders({
		'Content-Type':  'application/json',
		'Access-Control-Allow-Origin': '*',		
		'X-App-Idx': btoa(this.accessKey+':'+this.accessSecret),
	   });
  }
  
  getHttpHeaderWithCredential(){
	let tmpUser = this.stateSvc.getLocalSession();
	let sessUser = tmpUser.user;
	return new HttpHeaders({
		'Content-Type':  'application/json',
		'X-App-Idx': btoa(this.accessKey+':'+this.accessSecret),
		'Authorization': 'Bearer ' + btoa(sessUser.session_id+':'+sessUser.client_id),
    'Access-Control-Allow-Origin': '*'
	   });
  }
  
  getNokeyHeaders(){
	return new HttpHeaders({
	  'Content-Type':  'application/json',
	  'X-App-Idx': btoa(this.accessKey+':'+this.accessSecret),
	  'Access-Control-Allow-Origin': '*'
   })
  }
  
  getRawHeaders(){
	let tmpUser = this.stateSvc.getLocalSession();
	let sessUser = tmpUser.user;
	return {
    'X-App-Idx': btoa(this.accessKey+':'+this.accessSecret),
	'Authorization': 'Bearer ' + btoa(sessUser.session_id+':'+sessUser.client_id),
  'Access-Control-Allow-Origin': '*'
   };
  }
  
  getMapBoxKey(){
	return this.mapBoxKey;
  }
  
  /**
   * navigation from 1 page to another
   * @param path that set in app-routing.module.ts
   */
  navigateInternal(path){
	this.router.navigateByUrl(path);
  }
  
  navigateExternal(path){
	window.location.href = path;
  }
  
  navigateNewTab(path){
	window.open(path, '_blank');
  }
}
