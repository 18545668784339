<!-- start page title -->
<div class="row">
	<div class="col-12">
		<div class="page-title-box d-sm-flex align-items-center justify-content-between">
			<h4 class="mb-sm-0">Report - Engine Hours</h4>

		</div>
	</div>
</div>
<!-- end page title -->

<div class="row mb-2">
	<div class="col-lg-12">
		<div class="card">
			<div class="card-body">
				<div class="row g-3">
					<div class="col-xxl">
						<div>
							<label>Vessel</label>
							<select class="form-control" id="idCategory" [(ngModel)]="vessel">
								<option *ngFor="let ves of vesselOpts" [value]="ves.UnitID">{{ ves.Description }}</option>
							</select>
						</div>
					</div><!--end col-->
					<div class="col-xxl">
						<label>From</label>
						<input class="form-control" type="datetime-local" id="from" [(ngModel)]="rptFrom" placeholder="From" step="1">
					</div><!--end col-->
					<div class="col-xxl">
						<label>To</label>
						<input class="form-control" type="datetime-local" id="to" [(ngModel)]="rptTo" placeholder="To" step="1">
					</div><!--end col-->
					<div class="col-xxl-auto col-sm-6 pt-3">
						<button type="button" class="btn btn-dark w-md" (click)="generateReport()" [disabled]="isSubmitting">
							<span class="d-flex align-items-center" *ngIf="isSubmitting">
								<span class="spinner-border flex-shrink-0" role="status">
									<span class="visually-hidden">Loading...</span>
								</span>
								<span class="flex-grow-1 ms-2">
									Loading...
								</span>
							</span>
							<span *ngIf="!isSubmitting">
								<i class="bi bi-search align-baseline me-1"></i> Search
							</span>
						</button>
					</div><!--end col-->
				</div><!--end row-->
			</div>
		</div>
	</div><!--end col-->
</div><!--end row-->

<div class="card" id="card_result" style="min-height: 300px;" *ngIf="results.length > 0">
	<div class="card-header bg-gray">
	  <h3 class="card-title">Report</h3>
	  <div class="card-tools">
		<button type="button" class="btn btn-tool" data-card-widget="collapse">
		  <i class="fas fa-minus"></i>
		</button>
	  </div>
	</div>
	<div class="card-body">
	  <div class="row" >
		<div class="col-md-12">
			<div class="btn-group" role="group" aria-label="Export">
				<button type="button" class="btn btn-secondary" (click)="generateReportExcel()" [disabled]="isSubmittingXls">
					<span class="d-flex align-items-center" *ngIf="isSubmittingXls">
						<span class="spinner-border flex-shrink-0" role="status">
							<span class="visually-hidden">Downloading...</span>
						</span>
						<span class="flex-grow-1 ms-2">
							Downloading...
						</span>
					</span>
					<span *ngIf="!isSubmittingXls">
						Excel
					</span>
				</button>
				<button type="button" class="btn btn-secondary" (click)="generateReportPdf()" [disabled]="isSubmittingPdf">
					<span class="d-flex align-items-center" *ngIf="isSubmittingPdf">
						<span class="spinner-border flex-shrink-0" role="status">
							<span class="visually-hidden">Downloading...</span>
						</span>
						<span class="flex-grow-1 ms-2">
							Downloading...
						</span>
					</span>
					<span *ngIf="!isSubmittingPdf">
						PDF
					</span>
				</button>
			</div>
		</div>
		<div class="col-md-12">
			<table id="example1" class="table table-bordered table-striped table-head-fixed">
			  <thead class="table-aqua">
			  <tr>
				<!-- <th>Vessel</th> -->
				<th>Date</th>
				<th>Engine 1</th>
				<th>Engine 2</th>
				<th>Engine 3</th>
				<th>Auxiliary</th>
			  </tr>
			  </thead>
			  <tbody>
				<tr *ngFor="let res of results">
					<!-- <td>{{ res.vesselName }}</td> -->
					<td>{{ res.date }}</td>
					<td>{{ res.eng1 }}</td>
					<td>{{ res.eng2 }}</td>
					<td>{{ res.eng3 }}</td>
					<td>{{ res.aux2 }}</td>
				</tr>
			  </tbody>
			</table>
		</div>
	  </div>
	</div>
</div>