import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { StateService } from './state.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map,catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private apiservice: ApiService, private http: HttpClient, private stateSvc: StateService) { }
  
  generateReport(data): Observable <any>   {
		let e = this.apiservice.getEndpointUrl();
		let n = this.apiservice.getHttpHeaderWithCredential();
		let r = e + "report";
		let i = data;
		return this.http.post(r, i, {
			headers: n
		})
		.pipe(
			map((results:any) => {
				return results;
			}),
			catchError((error:any) => {
				return throwError(error);
			})
		);
	}

	generateReportFm(data): Observable <any>   {
		let e = this.apiservice.getEndpointUrl();
		let n = this.apiservice.getHttpHeaderWithCredential();
		let r = e + "report-full-movement";
		let i = data;
		return this.http.post(r, i, {
			headers: n
		})
		.pipe(
			map((results:any) => {
				return results;
			}),
			catchError((error:any) => {
				return throwError(error);
			})
		);
	}

	generateReportFc(data): Observable <any>   {
		let e = this.apiservice.getEndpointUrl();
		let n = this.apiservice.getHttpHeaderWithCredential();
		let r = e + "report-fuel-consumption";
		let i = data;
		return this.http.post(r, i, {
			headers: n
		})
		.pipe(
			map((results:any) => {
				return results;
			}),
			catchError((error:any) => {
				return throwError(error);
			})
		);
	}
}
