import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { StateService } from './state.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map,catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private apiservice: ApiService, private http: HttpClient, private stateSvc: StateService) { }
  
	getUsers(data): Observable <any>   {
		let t = this.apiservice.getEndpointUrl() + "users";
		let e = this.apiservice.getHttpHeaderWithCredential();
		return this.http.get(t, {
			headers: e, params: data
		})
		.pipe(
			map((results:any) => {
				return results;
			}),
			catchError((error:any) => {
				return throwError(error);
			})
		);
	}
	
	getUser(uid): Observable <any>   {
		let t = this.apiservice.getEndpointUrl() + "user/" + uid;
		let e = this.apiservice.getHttpHeaderWithCredential();
		return this.http.get(t, {
			headers: e
		})
		.pipe(
			map((results:any) => {
				return results;
			}),
			catchError((error:any) => {
				return throwError(error);
			})
		);
	}
	
	updateUser(data,userId): Observable <any>   {
		let e = this.apiservice.getEndpointUrl();
		let n = this.apiservice.getHttpHeaderWithCredential();
		let r = e + "user/"+userId;
		let i = data;
		return this.http.put(r, i, {
			headers: n
		})
		.pipe(
			map((results:any) => {
				return results;
			}),
			catchError((error:any) => {
				return throwError(error);
			})
		);
	}
	
	updatePassword(data,userId): Observable <any>   {
		let e = this.apiservice.getEndpointUrl();
		let n = this.apiservice.getHttpHeaderWithCredential();
		let r = e + "password/"+userId;
		let i = data;
		return this.http.put(r, i, {headers: n})
		.pipe(
			map((results:any) => {
				return results;
			}),
			catchError((error:any) => {
				return throwError(error);
			})
		);
	}
}
