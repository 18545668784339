import { Component, OnInit } from '@angular/core';
import { StateService } from '../../../services/state.service';
import { ApiService } from '../../../services/api.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  nowUser:any = {};
  isLoggedIn = false;
  availAccounts:any = [];
  
  vesselSummaries:any = {};
  userImage:any = "assets/images/unknown.jpg";
  
  constructor(private stateSvc: StateService, private apiSvc: ApiService, private authSvc: AuthService) { }

  ngOnInit(): void {
	setTimeout(() => {
		if(localStorage.getItem('is_dark_mode') == "1"){
			this.switchMode('dark');
		}
	}, 1500);
	this.stateSvc.isLoggedIn
	.subscribe((result:any) => {
		this.isLoggedIn = result;
		if(this.isLoggedIn){
			let sessionNow = this.stateSvc.getLocalSession();
			if(sessionNow?.user){
				this.nowUser = sessionNow?.user;
				this.availAccounts.push(this.nowUser.Account);
				if(sessionNow.user?.image){
					this.userImage = sessionNow.user.image;
				}
			}
		}
	});
	
	this.stateSvc.summaryStateVessels
	.subscribe((res:any) => {
		this.vesselSummaries = res;
	});
  }
  
  offDropVessels(state, badge){
	let vehicleFils = this.vesselSummaries[state];
	let data = {
		badge: badge,
		vessels: vehicleFils,
		state: state
	};
	this.stateSvc.setShownVessels(data);
  }

  logout(){
    let data = {
		logout: 1
	};
	this.authSvc.logout(data)
	.subscribe((res) => {
		this.stateSvc.logout();
		this.apiSvc.navigateInternal("");
	});
  }

  switchMode(md){
	if(md == 'dark'){console.log('switchMode', md)
		$('html').attr('data-sidebar', 'dark');
		$('html').attr('data-topbar', 'dark');
		$('html').attr('data-theme', 'default');
		$('html').attr('data-bs-theme', 'dark');

		$('.map-overlay').css('background', "#000");
		localStorage.setItem('is_dark_mode', "1");
	}else{
		$('html').attr('data-sidebar', 'light');
		$('html').attr('data-topbar', 'light');
		$('html').attr('data-theme', 'interaction');
		$('html').attr('data-bs-theme', 'light');

		$('.map-overlay').css('background', "#fff");
		localStorage.setItem('is_dark_mode', "0");
	}
  }

}
