<!-- start page title -->
<div class="row">
	<div class="col-12">
		<div class="page-title-box d-sm-flex align-items-center justify-content-between">
			<h4 class="mb-sm-0">Vessels</h4>

		</div>
	</div>
</div>
<!-- end page title -->

<div class="row mb-2">
	<div class="col-xxl">
		<div class="card overflow-hidden">
		  <div class="card-body">
			<div class="avatar-sm float-end">
			  <div class="avatar-title bg-info-subtle text-info fs-3xl rounded">
				<i class="bx bxs-ship bx-sm"></i>
			  </div>
			</div>
			<h5>
			  Total
			</h5>
			<h5 class="text-muted mb-1">{{ infos?.Total }}</h5>
		  </div>
		  <div class="progress progress-sm rounded-0" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
			<div class="progress-bar bg-info" style="width: 100%"></div>
		  </div>
		</div>
	</div><!--end col-->
	<div class="col-xxl">
		<div class="card overflow-hidden">
		  <div class="card-body">
			<div class="avatar-sm float-end">
			  <div class="avatar-title bg-danger-subtle text-danger fs-3xl rounded">
				<i class="bx bxs-ship bx-sm"></i>
			  </div>
			</div>
			<h5>
			  Stopped
			</h5>
			<h5 class="text-muted mb-1">{{ infos?.Stopped }}</h5>
		  </div>
		  <div class="progress progress-sm rounded-0" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
			<div class="progress-bar bg-danger" style="width: 100%"></div>
		  </div>
		</div>
	</div><!--end col-->
	<div class="col-xxl">
		<div class="card overflow-hidden">
		  <div class="card-body">
			<div class="avatar-sm float-end">
			  <div class="avatar-title bg-warning-subtle text-warning fs-3xl rounded">
				<i class="bx bxs-ship bx-sm"></i>
			  </div>
			</div>
			<h5>
			  Idling
			</h5>
			<h5 class="text-muted mb-1">{{ infos?.Idling }}</h5>
		  </div>
		  <div class="progress progress-sm rounded-0" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
			<div class="progress-bar bg-warning" style="width: 100%"></div>
		  </div>
		</div>
	</div><!--end col-->
	<div class="col-xxl">
		<div class="card overflow-hidden">
		  <div class="card-body">
			<div class="avatar-sm float-end">
			  <div class="avatar-title bg-success-subtle text-success fs-3xl rounded">
				<i class="bx bxs-ship bx-sm"></i>
			  </div>
			</div>
			<h5>
			  Transit
			</h5>
			<h5 class="text-muted mb-1">{{ infos?.Transit }}</h5>
		  </div>
		  <div class="progress progress-sm rounded-0" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
			<div class="progress-bar bg-success" style="width: 100%"></div>
		  </div>
		</div>
	</div><!--end col-->
	<div class="col-xxl">
		<div class="card overflow-hidden">
		  <div class="card-body">
			<div class="avatar-sm float-end">
			  <div class="avatar-title bg-success-subtle text-success fs-3xl rounded">
				<i class="bx bxs-ship bx-sm"></i>
			  </div>
			</div>
			<h5>
			  Shifting
			</h5>
			<h5 class="text-muted mb-1">{{ infos?.Shifting }}</h5>
		  </div>
		  <div class="progress progress-sm rounded-0" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
			<div class="progress-bar bg-success" style="width: 100%"></div>
		  </div>
		</div>
	</div><!--end col-->
	<div class="col-xxl">
		<div class="card overflow-hidden">
		  <div class="card-body">
			<div class="avatar-sm float-end">
			  <div class="avatar-title bg-success-subtle text-success fs-3xl rounded">
				<i class="bx bxs-ship bx-sm"></i>
			  </div>
			</div>
			<h5>
			  Pax Transfer
			</h5>
			<h5 class="text-muted mb-1">{{ infos && infos['Pax Transfer'] }}</h5>
		  </div>
		  <div class="progress progress-sm rounded-0" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
			<div class="progress-bar bg-success" style="width: 100%"></div>
		  </div>
		</div>
	</div>
	<div class="col-xxl">
		<div class="card overflow-hidden">
		  <div class="card-body">
			<div class="avatar-sm float-end">
			  <div class="avatar-title bg-success-subtle text-success fs-3xl rounded">
				<i class="bx bxs-ship bx-sm"></i>
			  </div>
			</div>
			<h5>
			  Manoeuvring
			</h5>
			<h5 class="text-muted mb-1">{{ infos?.Manoeuvring }}</h5>
		  </div>
		  <div class="progress progress-sm rounded-0" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
			<div class="progress-bar bg-success" style="width: 100%"></div>
		  </div>
		</div>
	</div><!--end col-->
</div><!--end row-->

<div class="row mb-2">
	<div class="col-lg-12">
		<div class="card">
			<div class="card-body">
				<div class="row g-3">
					<div class="col-xxl">
						<div class="search-box">
							<input type="text" class="form-control search" placeholder="Search by name" (keyup.enter)="filterVessel()" [(ngModel)]="selectedName" [ngModelOptions]="{standalone: true}">
							<i class="ri-search-line search-icon"></i>
						</div>
					</div><!--end col-->
					<div class="col-xxl-2 col-sm-6">
						<div>
							<select class="form-control" [(ngModel)]="selectedStatus" [ngModelOptions]="{standalone: true}" data-choices data-choices-search-false data-choices-removeItem>
								<option value="">Select Status</option>
								<option value="Stopped">Stopped</option>
								<option value="Idling">Idling</option>
								<option value="Transit">Transit</option>
								<option value="Shifting">Shifting</option>
								<option value="Pax_Transfer">Pax Transfer</option>
								<option value="Manoeuvring">Manoeuvring</option>
							</select>
						</div>
					</div><!--end col-->
					<div class="col-xxl-auto col-sm-6">
						<button type="button" class="btn btn-dark w-md" (click)="filterVessel()"><i class="bi bi-search align-baseline me-1"></i> Search</button>
					</div><!--end col-->
				</div><!--end row-->
			</div>
		</div>
	</div><!--end col-->
</div><!--end row-->

<div class="row" id="ticketsList">
	<div class="col-lg-12">
		<div class="card">
			<div class="card-body">
				<div class="">
					<table class="table table-centered align-middle table-custom-effect table-nowrap mb-0">
						<thead class="table-aqua">
							<tr>
								<th scope="col" class="sort cursor-pointer" data-sort="assign"></th>
								<th scope="col" class="sort cursor-pointer" data-sort="ticket_title">Vessel</th>
								<th scope="col" class="sort cursor-pointer" data-sort="client_name">Details</th>
								<th scope="col" class="sort cursor-pointer" data-sort="create_date">Engines</th>
								<th scope="col" class="sort cursor-pointer" data-sort="due_date">Current State</th>
								<th scope="col" class="sort cursor-pointer" data-sort="due_loc">Current Location</th>
								<th scope="col">Action</th>
							</tr>
						</thead>
						<tbody class="list form-check-all">
							<tr *ngFor="let vessel of vessels">
								<td class="assign">
									<a href="apps-tickets-overview.html" class="d-inline-block" data-bs-toggle="tooltip" data-bs-title="Lucian Griffith">
										<img src="assets/images/vessel-bg.jpeg" alt="" class="avatar-md rounded">
									</a>
								</td>
								<td>{{ vessel.Description }}</td>
								<td>Type: {{ vessel.VesselType }}<br>Tonnage: {{ vessel.Tonnage }}<br>IMO: {{ vessel.IMO }}<br>MMSI: {{ vessel.MMSI }}</td>
								<td> Engine 1<br>Engine 2<br>Engine 3<br></td>
								<td>
									<i class="bi bi-cursor-fill text-success align-baseline me-1" *ngIf="vessel.VesselState == 'Shifting' || vessel.VesselState == 'Manoeuvring' || vessel.VesselState == 'Transit' || vessel.VesselState == 'Pax_Transfer' || vessel.VesselState == 'Idling'"></i> 
									<i class="bi bi-stop-circle-fill text-danger align-baseline me-1" *ngIf="vessel.VesselState == 'Stopped'"></i> 
									{{ vessel.VesselState }}
								</td>
								<td>{{ vessel.Location }}</td>
								<td>
									<ul class="d-flex gap-2 list-unstyled mb-0">
										<!--<li>
											<a class="btn btn-subtle-primary btn-icon btn-sm" routerLink="/vessel-view/{{ vessel.UnitID }}"><i class="ph-eye"></i></a>
										</li>-->
										<li>
											<a routerLink="/vessel-edit/{{ vessel.UnitID }}" class="btn btn-icon btn-sm edit-item-btn"><i class="ph-pencil"></i></a>
										</li>
									</ul>
								</td>
							</tr>
							
						</tbody><!-- end tbody -->
					</table><!-- end table -->
					<div class="noresult" style="display: none">
						<div class="text-center py-4">
							<i class="ph-magnifying-glass fs-1 text-primary"></i>
							<h5 class="mt-2">Sorry! No Result Found</h5>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div><!--end col-->
</div><!--end row-->