import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { GeneralService } from '../../../services/general.service';
import { StateService } from '../../../services/state.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnDestroy {

  userCall:any;
  
  name = "";
  email = "";
  phone = "";
  profileLogs:any = [];
  loginLogs:any = [];
  
  userID = "";
  account = "";
  registered = "";
  lastLogin = "";
  role = "";
  
  currentPassword = "";
  password = "";
  repassword = "";

  userImage:any = "assets/images/unknown.jpg";
  
  constructor(private generalSvc: GeneralService, private apiSvc: ApiService, private stateSvc: StateService, private authSvc: AuthService) { }

  ngOnInit(): void {
	this.callUserProfile();
	
	$('.password-addon').click(function(e){
		let elemNow = e.currentTarget.previousSibling;
		let currAttr = elemNow.getAttribute('type');
		if(currAttr == "text"){
			elemNow.setAttribute("type", "password");
		}else{
			elemNow.setAttribute("type", "text");
		}
	});
	
	$("#quickPersonal").validate({
		rules: {
	 		name: {
	 			required: true
	 		}
	 	},
	 	messages: {
	 		username: {
	 			required: "Please enter a Name"
	 		}
	 	},
	 	errorElement: "span",
	 	errorPlacement: function(t, e) {
	 		t.addClass("invalid-feedback");
	 		e.closest(".form-group").append(t);
	 	},
	 	highlight: function(t, e, n) {
	 		$(t).addClass("is-invalid");
	 	},
	 	unhighlight: function(t, e, n) {
	 		$(t).removeClass("is-invalid");
	 	}
	});
	
	$("#quickPwd").validate({
		rules: {
	 		currentPassword: {
	 			required: true
	 		},
			password: {
	 			required: true
	 		},
			repassword: {
	 			required: true,
				equalTo: "#password"
	 		}
	 	},
	 	messages: {
	 		currentPassword: {
	 			required: "Please enter a Current Password"
	 		},
			password: {
	 			required: "Please enter a New Password"
	 		},
			repassword: {
	 			required: "Please enter a Re-type New Password",
				equalTo: "Value must equal to New Password"
	 		}
	 	},
	 	errorElement: "span",
	 	errorPlacement: function(t, e) {
	 		t.addClass("invalid-feedback");
	 		e.closest(".form-group").append(t);
	 	},
	 	highlight: function(t, e, n) {
	 		$(t).addClass("is-invalid");
	 	},
	 	unhighlight: function(t, e, n) {
	 		$(t).removeClass("is-invalid");
	 	}
	})
  }
  
  ngOnDestroy(){
	if(this.userCall){
		this.userCall.unsubscribe();
	}
  }
  
  callUserProfile(){
	this.generalSvc.showLoading();
	this.userCall = this.authSvc.getCurrentSession()
	.subscribe((res) => {
		if(res?.user){
			let user = res?.user;
			this.name = user.UserName;
			this.email = user.Email;
			this.phone = user.PhoneMobile;
			
			this.userID = user.UserID;
			this.account = user.Account;
			this.registered = user.Registered;
			this.lastLogin = user.LastLogin;
			this.role = user.UserRole;
			this.generalSvc.hideLoading();
			
			this.profileLogs = res?.profile_logs;
			this.loginLogs = res?.login_logs;
		}
	});
  }
  
  saveProfile(){
	if($("#quickPersonal").valid()){
		this.generalSvc.showLoading();
		let data = {
			userID: this.userID,
			name: this.name,
			phone: this.phone,
			email: this.email
		};
		
		this.authSvc.updateProfile(data)
		.subscribe((res) => {
			this.generalSvc.hideLoading();
			this.generalSvc.showToast("Profile updated", "success");
		},
		(err)=> {
			this.generalSvc.hideLoading();
			this.generalSvc.handleErr(err);
		});
	}
  }
  
  savePassword(){
	if($("#quickPwd").valid()){
		this.generalSvc.showLoading();
		let data = {
			current_password: this.currentPassword,
			new_password: this.password
		};
		
		this.authSvc.updatePassword(data)
		.subscribe((res) => {
			this.generalSvc.hideLoading();
			this.generalSvc.showToast("Password updated", "success");
		},
		(err)=> {
			this.generalSvc.hideLoading();
			this.generalSvc.handleErr(err);
		});
	}
  }

}
