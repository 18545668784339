<header id="page-topbar">
	<div class="layout-width">
	  <div class="navbar-header">
		<div class="d-flex">
		  <!-- LOGO -->
		  <div class="navbar-brand-box horizontal-logo">
			<a href="index.html" class="logo logo-dark">
			  <span class="logo-sm">
				Sutra Innofuel
			  </span>
			  <span class="logo-lg">
				Sutra Innofuel
			  </span>
			</a>
			<a href="index.html" class="logo logo-light">
			  <span class="logo-sm">
				Sutra Innofuel
			  </span>
			  <span class="logo-lg">
				Sutra Innofuel
			  </span>
			</a>
		  </div>
		  <button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none" id="topnav-hamburger-icon">
			<span class="hamburger-icon">
			  <span></span>
			  <span></span>
			  <span></span>
			</span>
		  </button>
		  <form class="app-search d-none d-md-inline-flex">
			<div class="position-relative">
				<button type="button" title="All Vessels" class="btn btn-icon btn-outline-info position-relative" (click)="offDropVessels('Total', 'info')" data-bs-toggle="offcanvas" data-bs-target="#offcanvasLeft" aria-controls="offcanvasLeft">
					<i class="bx bxs-ship bx-sm"></i> 
					<span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info">{{  vesselSummaries?.Total?.length }}
						
					</span>
				</button>
				<button type="button" title="Stopped" class="btn btn-icon btn-outline-danger position-relative ml-1rem" (click)="offDropVessels('Stopped', 'danger')" data-bs-toggle="offcanvas" data-bs-target="#offcanvasLeft" aria-controls="offcanvasLeft">
					<i class="bx bx-stop bx-sm"></i>  
					<span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{  vesselSummaries?.Stopped?.length }}
						
					</span>
				</button>
				<!-- <button type="button" class="btn btn-icon btn-outline-warning position-relative ml-1rem" (click)="offDropVessels('Idle', 'warning')" data-bs-toggle="offcanvas" data-bs-target="#offcanvasLeft" aria-controls="offcanvasLeft">
					<i class="bx bx-pause bx-sm"></i>  
					<span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">{{  vesselSummaries?.Idle?.length }}
						
					</span>
				</button> -->
				<button type="button" title="Operation" class="btn btn-icon btn-outline-success position-relative ml-1rem" (click)="offDropVessels('Active', 'success')" data-bs-toggle="offcanvas" data-bs-target="#offcanvasLeft" aria-controls="offcanvasLeft">
					<i class="bx bxs-navigation bx-sm"></i>   
					<span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">{{  vesselSummaries?.Active?.length }}
						
					</span>
				</button>
			</div>
		</form>
		</div>
		<div class="d-flex align-items-center">
		  
		  <div class="ms-1 header-item d-none d-lg-flex dropdown-nav">
			<select class="form-control" data-choices data-choices-search-false>
				<option *ngFor="let acc of availAccounts" [value]="acc" selected>{{ acc }}</option>
			</select>
		</div>

		<div class="ms-1 header-item d-none d-sm-flex">
			<button type="button" class="btn btn-icon btn-topbar btn-ghost-dark rounded-circle" data-toggle="fullscreen">
			  <i class='bi bi-arrows-fullscreen fs-lg'></i>
			</button>
		  </div>
		  <div class="dropdown topbar-head-dropdown ms-1 header-item">
			<button type="button" class="btn btn-icon btn-topbar btn-ghost-dark rounded-circle mode-layout" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			  <i class="bi bi-sun align-middle fs-3xl"></i>
			</button>
			<div class="dropdown-menu p-2 dropdown-menu-end" id="light-dark-mode">
			  <a href="javascript://" class="dropdown-item" data-mode="light" (click)="switchMode('light')">
				<i class="bi bi-sun align-middle me-2"></i> Light </a>
			  <a href="javascript://" class="dropdown-item" data-mode="dark" (click)="switchMode('dark')">
				<i class="bi bi-moon align-middle me-2"></i> Dark </a>
			</div>
		  </div>
							
							
		  <div class="dropdown ms-sm-3 header-item topbar-user">
			<button type="button" class="btn shadow-none" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			  <span class="d-flex align-items-center">
				<img class="rounded-circle header-profile-user" [src]="userImage" alt="Header Avatar">
				<span class="text-start ms-xl-2">
				  <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{ nowUser.UserName }}</span>
				  <span class="d-none d-xl-block ms-1 fs-sm user-name-sub-text">{{ nowUser.UserRole }}</span>
				</span>
			  </span>
			</button>
			<div class="dropdown-menu dropdown-menu-end">
			  <!-- item-->
			  <a class="dropdown-item" routerLink="/profile">
				<i class="mdi mdi-account-circle text-muted fs-lg align-middle me-1"></i>
				<span class="align-middle">Profile</span>
			  </a>
			  <div class="dropdown-divider"></div>
			  <a class="dropdown-item" (click)="logout()">
				<i class="mdi mdi-logout text-muted fs-lg align-middle me-1"></i>
				<span class="align-middle" data-key="t-logout">Logout</span>
			  </a>
			</div>
		  </div>
		</div>
	  </div>
	</div>
  </header>