<!-- start page title -->
<div class="row">
	<div class="col-12">
		<div class="page-title-box d-sm-flex align-items-center justify-content-between">
			<h4 class="mb-sm-0">Vessel Info</h4>

		</div>
	</div>
</div>
<!-- end page title -->

<div class="row">
	<!--end col-->
	<div class="col-xxl-3">
		<div class="card overflow-hidden">
			<div class="ratio ratio-16x9">
				<div id="view_map" style="width:100%; height: 100%"></div>
			</div>
			<div class="card-body pt-1">
				<div class="avatar-lg mt-n5 position-relative mx-auto">
					<div class="avatar-title bg-body-secondary rounded shadow">
						<img src="assets/images/vessel-bg.jpeg" alt="" class="avatar-sm">
					</div>
				</div>
				<div class="text-center mt-2">
					<h5 class="mb-1">{{ vessel.Description }}</h5>
					<p class="text-muted mb-4 pb-2">{{ vessel.Type }}</p>
				</div>
				<div class="table-responsive table-card">
					<table class="table align-middle table-borderless mb-0">
						<tbody>
							<tr>
								<th>Account</th>
								<td>{{ vessel.Account }}</td>
							</tr>
							<tr>
								<th>Commissioned</th>
								<td>{{ vessel.Commissioned }}</td>
							</tr>
							<tr>
								<th>Tonnage</th>
								<td>{{ vessel.Tonnage }}</td>
							</tr>
							<tr>
								<th>MMSI</th>
								<td>{{ vessel.MMSI }}</td>
							</tr>
							<tr>
								<th>Communication Mode</th>
								<td>{{ vessel.ComMode }}</td>
							</tr>
							<tr>
								<th>GPS Status</th>
								<td>{{ vessel.GPS }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div><!--end card-->
		<div class="card mt-2">
			<div class="card-header d-flex align-items-center">
				<h5 class="card-title mb-0 flex-grow-1">Activities</h5>
				
			</div>
			
		</div>
	</div>
	<div class="col-xxl-9">
		<div class="row mb-2">
			<div class="col-md-3">
				<div class="card overflow-hidden">
				  <div class="card-body">
					<div class="avatar-sm float-end">
					  <div class="avatar-title bg-info-subtle text-info fs-3xl rounded">
						<i class="bx bxs-ship bx-md"></i>
					  </div>
					</div>
					<p class="text-muted fw-medium text-uppercase mb-1">Status</p>
					<h4>
					  Transit
					</h4>
				  </div>
				  <div class="progress progress-sm rounded-0" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
					<div class="progress-bar bg-info" style="width: 100%"></div>
				  </div>
				</div>
			</div><!--end col-->
			<div class="col-md-3">
				<div class="card overflow-hidden">
				  <div class="card-body">
					<div class="avatar-sm float-end">
					  <div class="avatar-title bg-danger-subtle text-danger fs-3xl rounded">
						<i class="bx bx-tachometer bx-md"></i>
					  </div>
					</div>
					<p class="text-muted fw-medium text-uppercase mb-1">Speed</p>
					<h4>
					  12
					</h4>
				  </div>
				  <div class="progress progress-sm rounded-0" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
					<div class="progress-bar bg-danger" style="width: 100%"></div>
				  </div>
				</div>
			</div><!--end col-->
			<div class="col-md-3">
				<div class="card overflow-hidden">
				  <div class="card-body">
					<div class="avatar-sm float-end">
					  <div class="avatar-title bg-success-subtle text-success fs-3xl rounded">
						<i class="bx bx-globe bx-md"></i>
					  </div>
					</div>
					<p class="text-muted fw-medium text-uppercase mb-1">Bearing</p>
					<h4>
					  82
					</h4>
				  </div>
				  <div class="progress progress-sm rounded-0" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
					<div class="progress-bar bg-success" style="width: 100%"></div>
				  </div>
				</div>
			</div><!--end col-->
			<div class="col-md-3">
				<div class="card overflow-hidden">
				  <div class="card-body">
					<div class="avatar-sm float-end">
					  <div class="avatar-title bg-warning-subtle text-warning fs-3xl rounded">
						<i class="bx bxs-compass bx-md"></i>
					  </div>
					</div>
					<p class="text-muted fw-medium text-uppercase mb-1">Direction</p>
					<h4>
					  East
					</h4>
				  </div>
				  <div class="progress progress-sm rounded-0" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
					<div class="progress-bar bg-warning" style="width: 100%"></div>
				  </div>
				</div>
			</div><!--end col-->
			<!--end col-->
		</div><!--end row-->  
		<div class="row mb-2">
			<div class="col-md-9">
				<p class="fs-md text-muted mb-0">Overview</p>
			</div>
			<div class="col-md-3">
				<div class="dropdown float-end">
					<a class="text-reset dropdown-btn" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<span class="text-muted fs-lg"><i class="bx bxs-filter-alt align-middle"></i></span>
					</a>
					<div class="dropdown-menu dropdown-menu-end" style="">
						<a class="dropdown-item" href="#">Today</a>
						<a class="dropdown-item" href="#">Last 7 Days</a>
						<a class="dropdown-item" href="#">Last 30 Days</a>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<div class="leaflet-map leaflet-gray" style="height: 400px;"></div>
					</div>
				</div>
			</div><!--end col-->
			<div class="col-xl-4">
				
			</div><!--end col-->
		</div><!--end row-->
	</div>
	<!--end col-->
</div>
<!--end row-->