import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footbar',
  templateUrl: './footbar.component.html',
  styleUrls: ['./footbar.component.css']
})
export class FootbarComponent implements OnInit {

  thisyear = 2024;
  constructor() { }

  ngOnInit(): void {
    let nowdate = new Date();
    this.thisyear = nowdate.getFullYear();
  }

}
