<!-- start page title -->
<div class="row">
	<div class="col-12">
		<div class="page-title-box d-sm-flex align-items-center justify-content-between">
			<h4 class="mb-sm-0">Report - Fuel Sensor Density</h4>

		</div>
	</div>
</div>
<!-- end page title -->

<div class="row mb-2">
	<div class="col-lg-12">
		<div class="card">
			<div class="card-body">
				<div class="row g-3">
					<div class="col-xxl">
						<div>
							<label>Vessel</label>
							<select class="form-control" id="idCategory" [(ngModel)]="vessel">
								<option *ngFor="let ves of vesselOpts" [value]="ves.UnitID">{{ ves.Description }}</option>
							</select>
						</div>
					</div><!--end col-->
					<div class="col-xxl">
						<label>From</label>
						<input class="form-control" id="from" type="datetime-local" [(ngModel)]="rptFrom" placeholder="From" step="1">
					</div><!--end col-->
					<div class="col-xxl">
						<label>To</label>
						<input class="form-control" id="to" type="datetime-local" [(ngModel)]="rptTo" placeholder="To" step="1">
					</div><!--end col-->
					<div class="col-auto">
						<label>&nbsp;</label><br><br>
						<input class="" type="checkbox" [(ngModel)]="showMinOnly" value="1"> Show Min Data
					</div><!--end col-->
					<!-- <div class="col-xxl">
						<div class="form-group">
							<label>Interval</label>
							<select class="form-control" id="vess" [(ngModel)]="intervalMin">
								<option *ngFor="let opt of intervalOpts" [value]="opt.id">{{ opt.value }}</option>
							</select>
						</div>
					</div> -->
					<div class="col-xxl-auto col-sm-6 pt-3">
						<button type="button" class="btn btn-dark w-md" (click)="generateReport()" [disabled]="isSubmitting">
							<span class="d-flex align-items-center" *ngIf="isSubmitting">
								<span class="spinner-border flex-shrink-0" role="status">
									<span class="visually-hidden">Loading...</span>
								</span>
								<span class="flex-grow-1 ms-2">
									Loading...
								</span>
							</span>
							<span *ngIf="!isSubmitting">
								<i class="bi bi-search align-baseline me-1"></i> Search
							</span>
						</button>
					</div><!--end col-->
				</div><!--end row-->
			</div>
		</div>
	</div><!--end col-->
</div><!--end row-->

<div class="row mb-3" *ngIf="allResults.length > 0">
	<div class="col-12 col-md-12">
		<div class="card">
			<div class="card-header bg-gray">
			  <h3 class="card-title">Density</h3>
			  <div class="card-tools">
				<button type="button" class="btn btn-tool" data-card-widget="collapse">
				  <i class="fas fa-minus"></i>
				</button>
			  </div>
			</div>
			<div class="card-body">
				<button class="btn btn-primary text-center" type="button" disabled *ngIf="allResults.length > 0 && !lineChartDrawn">
					<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Generating chart...
				</button>
				<div id="lineChart" data-colors='["--tb-primary"]' class="apex-charts" dir="ltr" data-height="340"></div>
				<!--<canvas id="lineChart" style="min-height: 250px; height: 250px; max-height: 340px; max-width: 100%;"></canvas>-->
			</div>
		</div>
	</div>
</div>

<div class="card" id="card_result" style="min-height: 300px;" *ngIf="allResults.length > 0">
	<div class="card-header bg-gray">
	  <h3 class="card-title">Report</h3>
	  <div class="card-tools">
		<button type="button" class="btn btn-tool" data-card-widget="collapse">
		  <i class="fas fa-minus"></i>
		</button>
	  </div>
	</div>
	<div class="card-body">
	  <div class="row" >
		<div class="col-md-12">
			<div class="btn-group" role="group" aria-label="Export">
				<button type="button" class="btn btn-secondary" (click)="generateReportExcel()" [disabled]="isSubmittingXls">
					<span class="d-flex align-items-center" *ngIf="isSubmittingXls">
						<span class="spinner-border flex-shrink-0" role="status">
							<span class="visually-hidden">Downloading...</span>
						</span>
						<span class="flex-grow-1 ms-2">
							Downloading...
						</span>
					</span>
					<span *ngIf="!isSubmittingXls">
						Excel
					</span>
				</button>
				<button type="button" class="btn btn-secondary" (click)="generateReportPdf()" [disabled]="isSubmittingPdf">
					<span class="d-flex align-items-center" *ngIf="isSubmittingPdf">
						<span class="spinner-border flex-shrink-0" role="status">
							<span class="visually-hidden">Downloading...</span>
						</span>
						<span class="flex-grow-1 ms-2">
							Downloading...
						</span>
					</span>
					<span *ngIf="!isSubmittingPdf">
						PDF
					</span>
				</button>
			</div>
		</div>
		<div class="col-md-12">
			<table id="example1" class="table table-bordered table-striped table-head-fixed">
			  <thead class="table-aqua">
			  <tr>
				<th>Vessel</th>
				<th>Date Time</th>
				<th>Engine1 Supply</th>
				<th>Engine1 Return</th>
				<th>Engine2 Supply</th>
				<th>Engine2 Return</th>
				<th>Engine3 Supply</th>
				<th>Engine3 Return</th>
				<th>Auxiliary Supply</th>
				<th>Auxiliary Return</th>
				<th>Bunker</th>
			  </tr>
			  </thead>
			  <tbody>
				<tr *ngFor="let res of results">
					<td>{{ res.vesselName }}</td>
					<td>{{ res.datetimestamp }}</td>
					<td class="text-right">{{ res.EngDen1FWD }}</td>
					<td class="text-right">{{ res.EngDen1RET }}</td>
					<td class="text-right">{{ res.EngDen2FWD }}</td>
					<td class="text-right">{{ res.EngDen2RET }}</td>
                    <td class="text-right">{{ res.EngDen3FWD }}</td>
					<td class="text-right">{{ res.EngDen3RET }}</td>
					<td class="text-right">{{ res.GenDen2FWD }}</td>
					<td class="text-right">{{ res.GenDen2RET }}</td>
					<td class="text-right">{{ res.BunkerDen }}</td>
				</tr>
			  </tbody>
			</table>
		</div>
	  </div>
	  <div class="row mt-3">
		<p *ngIf="dataRender">Rendering {{ allResults.length }} records..</p>
		<p *ngIf="!dataRender && results?.length > 0"><strong>Total {{results.length}} records</strong></p>
	  </div>
	</div>
</div>