<!-- start page title -->
<div class="row">
	<div class="col-12">
		<div class="page-title-box d-sm-flex align-items-center justify-content-between">
			<h4 class="mb-sm-0">Report - Fuel / Hour</h4>

		</div>
	</div>
</div>
<!-- end page title -->

<div class="row mb-2">
	<div class="col-lg-12">
		<div class="card">
			<div class="card-body">
				<div class="row g-3">
					<div class="col-xxl">
						<div>
							<select class="form-control" id="idCategory">
								<option value="">All Vessels</option>
                                        <option *ngFor="let ves of vesselOpts" [value]="ves.UnitID">{{ ves.Description }}</option>
							</select>
						</div>
					</div><!--end col-->
					<div class="col-xxl">
						<div>
							<input class="form-control" type="datetime-local" [(ngModel)]="rptFrom" placeholder="From" (blur)="resaveStartDate()">
						</div>
					</div><!--end col-->
					<div class="col-xxl">
						<input class="form-control" type="datetime-local" [(ngModel)]="rptTo" placeholder="To" (blur)="resaveEndDate()">
					</div><!--end col-->
					<div class="col-xxl-auto col-sm-6">
						<button type="button" class="btn btn-dark w-md" (click)="generateReport()" [disabled]="isSubmitting"><i class="bi bi-search align-baseline me-1"></i> Search</button>
					</div><!--end col-->
				</div><!--end row-->
			</div>
		</div>
	</div><!--end col-->
</div><!--end row-->

<div class="card" id="card_result" style="min-height: 300px;" *ngIf="results.length > 0">
	<div class="card-header bg-gray">
	  <h3 class="card-title">Report</h3>
	  <div class="card-tools">
		<button type="button" class="btn btn-tool" data-card-widget="collapse">
		  <i class="fas fa-minus"></i>
		</button>
	  </div>
	</div>
	<div class="card-body">
	  <div class="row" >
		<div class="col-md-12">
			<table id="example1" class="table table-bordered table-striped table-head-fixed">
			  <thead class="table-aqua">
			  <tr>
				<th>Vessel</th>
				<th>Date</th>
				<th>ENG1 Hours</th>
				<th>ENG1 Liters</th>
				<th>ENG2 Hours</th>
				<th>ENG2 Liters</th>
				<th>ENG3 Hours</th>
				<th>ENG3 Liters</th>
				<th>Aux1 Hours</th>
				<th>Aux1 Liters</th>
				<th>Aux2 Hours</th>
				<th>Aux2 Liters</th>
				<th>ENG Total</th>
				<th>Aux Total</th>
				<th>Daily</th>
			  </tr>
			  </thead>
			  <tbody>
				<tr *ngFor="let res of results">
					<td>{{ res.vesselName }}</td>
					<td>{{ res.datetimestamp }}</td>
					<td>03:00</td>
					<td>{{ res.Eng1NET }}</td>
					<td>01:30</td>
					<td>{{ res.Eng2NET }}</td>
					<td>02:10</td>
					<td>{{ res.Eng3NET }}</td>
					<td>01:42</td>
					<td>{{ res.Gen1NET }}</td>
					<td>00:59</td>
					<td>{{ res.Gen2NET }}</td>
					<td>{{ res.Eng1NET }}</td>
					<td>{{ res.Gen1NET }}</td>
					<td>34</td>
				</tr>
			  </tbody>
			</table>
		</div>
	  </div>
	</div>
</div>