<!-- start page title -->
<div class="row">
	<div class="col-12">
		<div class="page-title-box d-sm-flex align-items-center justify-content-between">
			<h4 class="mb-sm-0">Report - Bunkering</h4>

		</div>
	</div>
</div>
<!-- end page title -->

<div class="row mb-2">
	<div class="col-lg-12">
		<div class="card">
			<div class="card-body">
				<div class="row g-3">
					<div class="col-xxl">
						<div>
							<label>Vessel</label>
							<select class="form-control" id="idCategory" [(ngModel)]="vessel">
								<option *ngFor="let ves of vesselOpts" [value]="ves.UnitID">{{ ves.Description }}</option>
							</select>
						</div>
					</div><!--end col-->
					<div class="col-xxl">
						<label>From</label>
						<input class="form-control" type="datetime-local" id="from" [(ngModel)]="rptFrom" placeholder="From" step="1">
					</div><!--end col-->
					<div class="col-xxl">
						<label>To</label>
						<input class="form-control" type="datetime-local" id="to" [(ngModel)]="rptTo" placeholder="To" step="1">
					</div><!--end col-->
					<!-- <div class="col-xxl">
						<div class="form-group">
							<select class="form-control" id="vess" [(ngModel)]="intervalMin">
								<option *ngFor="let opt of intervalOpts" [value]="opt.id">{{ opt.value }}</option>
							</select>
						</div>
					</div> -->
					<div class="col-xxl-auto col-sm-6 pt-3">
						<button type="button" class="btn btn-dark w-md" (click)="generateReport()" [disabled]="isSubmitting">
							<span class="d-flex align-items-center" *ngIf="isSubmitting">
								<span class="spinner-border flex-shrink-0" role="status">
									<span class="visually-hidden">Loading...</span>
								</span>
								<span class="flex-grow-1 ms-2">
									Loading...
								</span>
							</span>
							<span *ngIf="!isSubmitting">
								<i class="bi bi-search align-baseline me-1"></i> Search
							</span>
						</button>
					</div><!--end col-->
				</div><!--end row-->
			</div>
		</div>
	</div><!--end col-->
</div><!--end row-->

<div class="card" id="card_result" style="min-height: 300px;" *ngIf="results.length > 0">
	<div class="card-header bg-gray">
	  <h3 class="card-title">Report</h3>
	  <div class="card-tools">
		<button type="button" class="btn btn-tool" data-card-widget="collapse">
		  <i class="fas fa-minus"></i>
		</button>
	  </div>
	</div>
	<div class="card-body">
	  <div class="row" >
		<div class="col-md-12">
			<table id="example1" class="table table-bordered table-striped table-head-fixed">
			  <thead class="table-aqua">
			  <tr>
				<th>Vessel</th>
				<th>Date Time</th>
				<th>Total (l)</th>
			  </tr>
			  </thead>
			  <tbody>
				<tr *ngFor="let res of bunkers">
					<td>{{ res.vesselName }}</td>
					<td>{{ res.datetimestamp }}</td>
					<td class="text-right">{{ res.diffbunk1 }}</td>
				</tr>
			  </tbody>
			</table>
		</div>
	  </div>
	</div>
</div>