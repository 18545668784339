import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { GeneralService } from '../../../services/general.service';
import { StateService } from '../../../services/state.service';
import { VesselService } from '../../../services/vessel.service';
import { ReportService } from '../../../services/report.service';

@Component({
  selector: 'app-trip',
  templateUrl: './trip.component.html',
  styleUrls: ['./trip.component.css']
})
export class TripComponent implements OnInit, OnDestroy {

  map:any;
  markers:any = [];
  pois:any = [];
  selectVessel:any = 2;
  coords:any = [];
  pointSelected:any = {};

  from = "2024-04-11 00:00:00";
  to = "2024-04-11 12:00:00";
  fromPicker:any;
  toPicker:any;
  
  vesselsCall:any;
  vessels:any = [];
  points:any = [];
  displayVessels:any = [];
  displayPois:any = [];
  
  showVes = true;
  showPoi = false;
  
  waveHeight:any = "";
  waveDirection:any = "";
  swellHeight:any = "";
  swellDirection:any = "";
  oceanCurrentHeight:any = "";
  oceanCurrentDirection:any = "";
  windSpeed:any = "";
  windDirection:any = "";
  temperature:any = "";
  weather:any = "";

  searchVessel = "";
  searchPoi = "";
  mapStyle = "standard";

  geojson:any = {};
  isSubmitting = false;
  rptCall:any;
  showMinOnly = false;
  
  constructor(private generalSvc: GeneralService, private apiSvc: ApiService, private stateSvc: StateService, private vesselSvc: VesselService, private reportSvc: ReportService) { }

  ngOnInit(): void {
    let attrHtml = $('html').attr('data-theme');
    setTimeout(() => {
      if(attrHtml == 'default'){
        $('.map-overlay').css('background-color', "#000");
      }else {
        $('.map-overlay').css('background-color', "#fff");
      }
    }, 300);
    
    mapboxgl.accessToken = this.apiSvc.getMapBoxKey();
    this.map = new mapboxgl.Map({
        container: 'map', // container ID
        center: [103.332442, 4.87285], // starting position [lng, lat]
        zoom: 7, // starting zoom,
    });
    
    setTimeout(() => {
      this.callVessels();
      
      // $('#from').val(this.generalSvc.getTodayStart());
      // this.from = $('#from').val();

      // $('#to').val(this.generalSvc.getToday());
      // this.to = $('#to').val();
      // $('#to').attr('min', this.from);
      // $('#from').attr('max', this.to);


      this.from = this.generalSvc.getTodayStart();
	    this.to = this.generalSvc.getToday();

      this.fromPicker = $('#from').flatpickr({
        enableTime: true,
        enableSeconds: true,
        dateFormat: "d/m/Y H:i:s",
        defaultDate: new Date(this.from),
        onChange: (selectedDates, dateStr, instance) => {
          this.from = dateStr;
          this.stateSvc.setReportStartDate(this.from);
          this.toPicker.set('minDate', this.from);
        }
      });
      this.from = $('#from').val();
    
      this.toPicker = $('#to').flatpickr({
        enableTime: true,
        enableSeconds: true,
        dateFormat: "d/m/Y H:i:s",
        defaultDate: new Date(this.to),
        onChange: (selectedDates, dateStr, instance) => {
          this.to = dateStr;
          this.stateSvc.setReportEndDate(this.to);
          this.fromPicker.set('maxDate', this.to);
        }
      });
      this.to = $('#to').val();
      this.fromPicker.set('maxDate', this.to);
	    this.toPicker.set('minDate', this.from);
      
      this.generateReport();
    }, 1200);
  }
  
  ngOnDestroy(){
    if(this.vesselsCall){
      this.vesselsCall.unsubscribe();
    }
    if(this.map){
      this.map.remove();
      this.map = null;
    }
    if(this.rptCall){
      this.rptCall.unsubscribe();
    }
  }
  
  callVessels(){
    this.vesselsCall = this.stateSvc.sessionVessels
    .subscribe((result:any) => {
      this.vessels = result.vessels;
      this.displayPois = result.points;
      if(this.displayPois?.length > 0){
        this.displayPois.forEach((poi) => {
          poi.shown = 1;
          let popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
            poi.Name
          );
  
          let colorDef = "#ADD8E6";
          let iconUrl = this.apiSvc.getBaseFe() + "/assets/images/POI_Platform.png";
          if(poi.Type == "Port"){
            colorDef = "#FF7F50";
            iconUrl = this.apiSvc.getBaseFe() + "/assets/images/POI_Port.png";
          }
  
          let elMkr = document.createElement('div');
          elMkr.style.backgroundImage = 'url('+iconUrl+')';
          elMkr.style.width = '32px';
          elMkr.style.height = '32px';
          elMkr.style.backgroundSize = '100%';
          
          let marker1 = new mapboxgl.Marker(elMkr)
          .setLngLat([poi.Lng, poi.Lat])
          .setPopup(popup)
          .addTo(this.map);
          
          poi.marker = marker1;
          
          this.pois.push(marker1);
        });
      }
    });
  }
  
	toggleMap(type){
		this.map.setStyle('mapbox://styles/mapbox/' + type);
    this.mapStyle = type;
	}

  generateReport(){
    let startDate = new Date(this.generalSvc.convertLastDate(this.from));
    let toDate = new Date(this.generalSvc.convertLastDate(this.to));

    if(startDate > toDate){
      this.generalSvc.showToast('To date must be greater than From Date', "error");
      return;
    }

    if(this.showMinOnly){
      // Calculating the time difference of two dates
      let differenceInTime = toDate.getTime() - startDate.getTime();
      // Calculating the no. of days between two dates
      let differenceInDays = differenceInTime / (1000 * 3600 * 24);
      if(differenceInDays > 1){
        this.generalSvc.showToast('Date range selection cannot be more than 1 days', "error");
        return;
      }
    }

    this.isSubmitting = true;
    let dataRpt = {
      vessel: this.selectVessel,
      from: this.generalSvc.convertLastDate(this.from),
      to: this.generalSvc.convertLastDate(this.to),
      interval: 1,
      canKivInterval: true,
      showMin: this.showMinOnly
    };
    if(this.points?.length > 0){
      this.points.forEach((mrk) => {
        mrk.remove();
      });
      this.points = [];

      this.map.removeLayer("LineString");
      this.map.removeSource('LineString');
    }
    this.rptCall = this.reportSvc.generateReport(dataRpt)
    .subscribe((res) => {
      this.coords = res?.coords;
      let dtlCoords = res?.detailCoords;
      if(res?.coords?.length > 0){
        this.geojson = {
          'type': 'FeatureCollection',
          'features': [
              {
                  'type': 'Feature',
                  'geometry': {
                      'type': 'LineString',
                      'coordinates': this.coords
                  },
                  'properties': {}
              }
          ]
        };
        this.map.addSource('LineString', {
            'type': 'geojson',
            'data': this.geojson
        });
        this.map.addLayer({
            'id': 'LineString',
            'type': 'line',
            'source': 'LineString',
            'layout': {
                'line-join': 'round',
                'line-cap': 'round'
            },
            'paint': {
                'line-color': '#BF93E4',
                'line-width': 5
            }
        });
        const bounds = new mapboxgl.LngLatBounds(
          this.coords[0],
          this.coords[0]
        );
        this.coords?.forEach((crd, ky) => {
          if(ky == 0){
            this.map.flyTo({
              center: crd
            });

            let iconFlagUrl = this.apiSvc.getBaseFe() + "/assets/images/Flag_Start.png";
            let elMkr = document.createElement('div');
            elMkr.style.backgroundImage = 'url('+iconFlagUrl+')';
            elMkr.style.width = '32px';
            elMkr.style.height = '32px';
            elMkr.style.backgroundSize = '100%';
            let tmpThis = this;
            elMkr.addEventListener('click', function() {
              tmpThis.pointSelected = dtlCoords[ky];
              $('#exampleModalgrid').modal('show');
            });

            let popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
              '<p>Date Time: '+dtlCoords[ky].Updated+'<br>GPS Status: '+dtlCoords[ky].GPS+'<br>Bearing: '+dtlCoords[ky].Bearing+"&deg;"+'<br>Heading: '+dtlCoords[ky].Direction+'<br>State: '+dtlCoords[ky].VesselState+'<br>Speed: '+dtlCoords[ky].Speed+' knot</p>'
            );
            
            let marker1 = new mapboxgl.Marker(elMkr)
            .setLngLat([crd[0], crd[1]])
            .addTo(this.map);

            this.points.push(marker1);
          }

          let popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
            '<p>Date Time: '+dtlCoords[ky].Updated+'<br>GPS Status: '+dtlCoords[ky].GPS+'<br>Bearing: '+dtlCoords[ky].Bearing+"&deg;"+'<br>Heading: '+dtlCoords[ky].Direction+'<br>State: '+dtlCoords[ky].VesselState+'<br>Speed: '+dtlCoords[ky].Speed+' knot</p>'
          );

          let vesselImg = "icon_arrow.png";
          if(dtlCoords[ky].Is_engine_on){
            if(dtlCoords[ky].VesselState == "Stopped"){
              vesselImg = "icon_arrow_stopped.png";
            }else{
              vesselImg = "icon_arrow_moving.png";
            }
          }

          let iconUrl = this.apiSvc.getBaseFe() + "/assets/images/"+vesselImg;

          let elMkr = document.createElement('div');
          elMkr.style.backgroundImage = 'url('+iconUrl+')';
          elMkr.style.width = '16px';
          elMkr.style.height = '16px';
          elMkr.style.backgroundSize = '100%';
          let tmpThis = this;
          elMkr.addEventListener('click', function() {
            tmpThis.pointSelected = dtlCoords[ky];
            $('#exampleModalgrid').modal('show');
          });
          
          let marker1 = new mapboxgl.Marker({element: elMkr, rotation: parseInt(dtlCoords[ky].Bearing)})
          .setLngLat([crd[0], crd[1]])
          .addTo(this.map);

          this.points.push(marker1);

          if((ky + 1) == this.coords?.length){
            let iconFlagUrl = this.apiSvc.getBaseFe() + "/assets/images/Flag_End.png";
            let elMkr = document.createElement('div');
            elMkr.style.backgroundImage = 'url('+iconFlagUrl+')';
            elMkr.style.width = '32px';
            elMkr.style.height = '32px';
            elMkr.style.backgroundSize = '100%';
            let tmpThis = this;
            elMkr.addEventListener('click', function() {
              tmpThis.pointSelected = dtlCoords[ky];
              $('#exampleModalgrid').modal('show');
            });

            let popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
              '<p>Date Time: '+dtlCoords[ky].Updated+'<br>GPS Status: '+dtlCoords[ky].GPS+'<br>Bearing: '+dtlCoords[ky].Bearing+'<br>Heading: '+dtlCoords[ky].Direction+'<br>State: '+dtlCoords[ky].VesselState+'<br>Speed: '+dtlCoords[ky].Speed+'</p>'
            );
            
            let marker1 = new mapboxgl.Marker(elMkr)
            .setLngLat([crd[0], crd[1]])
            .addTo(this.map);

            this.points.push(marker1);
          }

          bounds.extend(crd);
        })
        this.map.fitBounds(bounds, {
          padding: 20
        });
      }else{
        this.generalSvc.showToast('No trip found on the selected date time', "error");
      }
      this.isSubmitting = false;
    }, (err) => {
      this.isSubmitting = false;
    })
  }

  togglePoi(){
    if(!this.showPoi){
      if(this.pois?.length > 0){
        this.pois.forEach((mrk) => {
          mrk.remove();
        });
        this.pois = [];
      }
    }else{
      this.displayPois.forEach((ves) => {
        let popup = null;
        
        popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
        ves.Name
        );

        let colorDef = "#ADD8E6";
        let iconUrl = this.apiSvc.getBaseFe() + "/assets/images/POI_Platform.png";
        if(ves.Type == "Port"){
          colorDef = "#FF7F50";
          iconUrl = this.apiSvc.getBaseFe() + "/assets/images/POI_Port.png";
        }

        let elMkr = document.createElement('div');
        elMkr.style.backgroundImage = 'url('+iconUrl+')';
        elMkr.style.width = '32px';
        elMkr.style.height = '32px';
        elMkr.style.backgroundSize = '100%';
        
        let marker1 = new mapboxgl.Marker(elMkr)
        .setLngLat([ves.Lng, ves.Lat])
        .setPopup(popup)
        .addTo(this.map);
        
        ves.marker = marker1;
        
        this.pois.push(marker1);
      });
    }
    this.showPoi = !this.showPoi;
  }

  changedDate(fld){
    if(fld == 'from'){
      $('#to').attr('min', this.from);
    }else{
      $('#from').attr('max', this.to);
    }
  }

  toggleVes(){
    this.showVes = !this.showVes;
  }

}
