import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { StateService } from '../../services/state.service';
import { VesselService } from '../../services/vessel.service';
import { ReportService } from '../../services/report.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map,catchError } from 'rxjs/operators';

declare var $: any
declare var Chart: any

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  map:any;
  marker:any = {};

  results:any = [];
  resultUsage:any = {};
  resultUsageRaw:any = {};
  resultPercent:any = {};
  rptCall:any;

  vesselsCall:any;
  vessels:any = [];
  activeVessel:any = {};
  activeVesselUnitId:any = 0;

  waveHeight:any = "";
  waveDirection:any = "";
  swellHeight:any = "";
  swellDirection:any = "";
  oceanCurrentHeight:any = "";
  oceanCurrentDirection:any = "";
  windSpeed:any = "";
  windDirection:any = "";
  temperature:any = "";
  weather:any = "";
  weatherIcon:any = "";
  totalCons:any = 0

  allMoves:any = [];
  consChart:any;
  donutChart:any;

  totalDistance = "0";

  constructor(private apiSvc: ApiService, private stateSvc: StateService, private http: HttpClient, private vesselSvc: VesselService, private reportSvc: ReportService) { }

  ngOnInit(): void {
    // const choices = new Choices($('#idCategory')[0]);
    mapboxgl.accessToken = this.apiSvc.getMapBoxKey();
    this.map = new mapboxgl.Map({
        container: 'map', // container ID
        center: [103.332442, 4.87285], // starting position [lng, lat]
        zoom: 7, // starting zoom,
    });
    setTimeout(() => {
      this.callVessels();
    }, 1200);
  }

  ngOnDestroy(): void {
    if(this.vesselsCall){
      this.vesselsCall.unsubscribe();
    }
    if(this.rptCall){
      this.rptCall.unsubscribe();
    }
    if(this.consChart){
      this.consChart.destroy();
    }
    if(this.donutChart){
      this.donutChart.destroy();
    }
    if(this.map){
      this.map.remove();
      this.map = null;
    }
  }

  callVessels(){
    this.vesselsCall = this.stateSvc.sessionVessels
    .subscribe((result:any) => {
      if(this.marker){
        // this.marker.remove();
        // this.marker = {};
      }
      this.vessels = result.vessels;
      if(this.vessels?.length > 0){
        this.vessels.forEach((ves) => {
          if(this.activeVesselUnitId == 0 && ves.UnitID == 2){
            this.selectActiveVessel(ves);
          }
        });
      }
    });
  }

  dropdownVes(e){
    let unitVes = e.target.value;
    this.vessels.forEach((ves) => {
      if(ves.UnitID == unitVes){
        this.selectActiveVessel(ves);
        return;
      }
    })
  }

  selectActiveVessel(ves){
    ves.shown = 1;
    this.activeVessel = ves;

    let vesselImg = "ship-off.png";
    if(ves.is_engine_on){
      if(ves.VesselState == "Stopped"){
        vesselImg = "ship-stopped.png";
      }else{
        vesselImg = "ship-moving.png";
      }
    }

    let elMkr = document.createElement('div');
    let tmpThis = this;
    elMkr.className = "marker";
    elMkr.style.backgroundImage = 'url('+this.apiSvc.getBaseFe()+'/assets/images/'+vesselImg+')';
    elMkr.style.width = '36px';
    elMkr.style.height = '36px';
    elMkr.style.backgroundSize = '100%';
    elMkr.style.borderRadius = "50%";
    elMkr.style.boxShadow = "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)";

    let marker1 = new mapboxgl.Marker(elMkr)
    .setLngLat([ves.Lon, ves.Lat])
    .addTo(this.map);
    
    ves.marker = marker1;
    this.marker = marker1;

    this.getCurrentWeatherPoint(ves.Lat, ves.Lon);

    this.map.flyTo({
      center: [ves.Lon, ves.Lat]
    });

    let currentDay = new Date(ves.Datetimestamp);
    let onlyDate = currentDay.getFullYear() + '-' + ((currentDay.getMonth()+1).toString()).padStart(2,"0") + '-' + ((currentDay.getDate().toString())).padStart(2,"0");

    let dataRpt = {
      vessel: ves.UnitID,
      from: onlyDate + " 00:00:00",
      to: onlyDate + " 23:59:59",
      interval: 15
    };
    if(this.consChart){
      this.consChart.destroy();
    }
    if(this.donutChart){
      this.donutChart.destroy();
    }
    this.rptCall = this.reportSvc.generateReport(dataRpt)
    .subscribe((res) => {
      setTimeout(() => {
        this.results = res?.result;
        this.resultUsage = res?.total;
        // this.resultUsageRaw = res?.totalBeauty;
        this.resultPercent = res?.percent;
        let lineLabel:any = [];
        // this.totalCons = this?.resultUsage?.total;
        let lineDataset:any = [
          {
            label               : 'Engine 1',
            backgroundColor     : 'rgba(0, 123, 255,0.9)',
            fill				: false,
            borderColor         : 'rgba(0, 123, 255,0.8)',
            pointRadius          : false,
            pointColor          : '#007bff',
            pointStrokeColor    : 'rgba(0, 123, 255,1)',
            pointHighlightFill  : '#fff',
            pointHighlightStroke: 'rgba(0, 123, 255,1)',
            data                : []
          },
          {
            label               : 'Engine 2',
            backgroundColor     : 'rgba(253, 126, 20,0.9)',
            fill				: false,
            borderColor         : 'rgba(253, 126, 20,0.8)',
            pointRadius          : false,
            pointColor          : '#fd7e14',
            pointStrokeColor    : 'rgba(253, 126, 20,1)',
            pointHighlightFill  : '#fff',
            pointHighlightStroke: 'rgba(253, 126, 20,1)',
            data                : []
          },
          {
            label               : 'Engine 3',
            backgroundColor     : 'rgba(32, 201, 151,0.9)',
            fill				: false,
            borderColor         : 'rgba(32, 201, 151,0.8)',
            pointRadius          : false,
            pointColor          : '#20c997',
            pointStrokeColor    : 'rgba(32, 201, 151,1)',
            pointHighlightFill  : '#fff',
            pointHighlightStroke: 'rgba(32, 201, 151,1)',
            data                : []
          },{
            label               : 'Auxiliary',
            backgroundColor     : 'rgba(102,16,242,0.9)',
            fill				: false,
            borderColor         : 'rgba(102,16,242,0.8)',
            pointRadius          : false,
            pointColor          : '#6610f2',
            pointStrokeColor    : 'rgba(102,16,242,1)',
            pointHighlightFill  : '#fff',
            pointHighlightStroke: 'rgba(102,16,242,1)',
            data                : []
          }
          ,{
            label               : 'TOTAL',
            backgroundColor     : 'rgba(189, 78, 245,0.9)',
            fill				: false,
            borderColor         : 'rgba(189, 78, 245,0.8)',
            pointRadius          : false,
            pointColor          : '#BDA3F5',
            pointStrokeColor    : 'rgba(189, 78, 245,1)',
            pointHighlightFill  : '#fff',
            pointHighlightStroke: 'rgba(189, 78, 245,1)',
            data                : []
          },
          {
            label               : 'Speed',
            backgroundColor     : 'rgba(52, 235, 222,0.9)',
            fill				: false,
            borderColor         : 'rgba(52, 235, 222,0.8)',
            pointRadius          : false,
            pointColor          : '#34ebde',
            pointStrokeColor    : 'rgba(52, 235, 222,1)',
            pointHighlightFill  : '#fff',
            pointHighlightStroke: 'rgba(52, 235, 222,1)',
            data                : []
          }
        ];
        this.results?.forEach((res) => {
          lineLabel.push(res.datetimestamp);
          lineDataset[0].data.push(res.eng1);
          lineDataset[1].data.push(res.eng2);
          lineDataset[2].data.push(res.eng3);
          lineDataset[3].data.push(res.gen2);
          lineDataset[4].data.push(res.total_cons_beauty);
				  lineDataset[5].data.push(res.Speed);
        });
        
        let areaChartData = {
          labels  : lineLabel,
          datasets: lineDataset
        }

        let areaChartOptions = {
          maintainAspectRatio : false,
          datasetFill: false,
          responsive : true,
          legend: {
          display: false
          },
          scales: {
          xAxes: [{
            gridLines : {
            display : false,
            }
          }],
          yAxes: [{
            gridLines : {
            display : false,
            }
          }]
          }
        }
			
			var options = {
				chart: {
				  type: 'line',
				  height: 340
				},
				colors: ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#BDA3F5", "#03ecfc"],
				series: [{
				  name: 'Eng1',
            data: lineDataset[0].data
          },{
            name: 'Eng2',
            data: lineDataset[1].data
          },{
            name: 'Eng3',
            data: lineDataset[2].data
          },{
            name: 'Auxiliary',
            data: lineDataset[3].data
          },{
            name: 'TOTAL',
            data: lineDataset[4].data
          },{
            name: 'Speed',
            data: lineDataset[5].data
          }],
          xaxis: {
            categories: lineLabel,
            labels: {
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMM \'yy',
              day: 'dd MMM',
              hour: 'HH:mm'
            }
            },
            tickAmount: 12
          },
          yaxis: [{
            seriesName: "TOTAL",
            show: false,
            title: {
              text: '(Liter)'  // Set the y-axis title with units
            }
          }, 
          {
            seriesName: "TOTAL",
            show: false,
            title: {
              text: '(Liter)'  // Set the y-axis title with units
            }
          },
          {
            seriesName: "TOTAL",
            show: false,
            title: {
              text: '(Liter)'  // Set the y-axis title with units
            }
          },
          {
            seriesName: "TOTAL",
            show: false,
            title: {
              text: '(Liter)'  // Set the y-axis title with units
            }
          },
          {
            seriesName: "TOTAL",
            title: {
              text: '(Liter)'  // Set the y-axis title with units
            }
          },
          {
            opposite: true,
            seriesName: "Speed",
            title: {
              text: '(knot)'
            }
          }],
          stroke: {
            curve: 'smooth'
          },
          markers: {
            size: 5
          }
			  }
        if(this.consChart){
          this.consChart.destroy();
        }
			  this.consChart = new ApexCharts(document.querySelector("#consChart"), options);
			  this.consChart.render();
        this.consChart.hideSeries('Eng1');
			  this.consChart.hideSeries('Eng2');
			  this.consChart.hideSeries('Eng3');
			  this.consChart.hideSeries('Auxiliary');
      });
    });
    this.reportSvc.generateReportFm(dataRpt)
    .subscribe((res) => {
      if(res?.totalDistance){
        this.totalDistance = res.totalDistance;
      }
    });
    this.reportSvc.generateReportFc(dataRpt)
    .subscribe((res) => {
      this.resultUsageRaw = res?.usageBeautyByEngines;
      this.totalCons = res?.usageBeautyByEngines?.total;
      var pieOptions = {
				chart: {
				  type: 'pie',
				  height: 250,
				  toolbar: {
					show: true,
					tools: {
						download: true
					}
				  },
				  export: {
					png: {
						filename: "Engine Breakdown"
					}
				  }
				},
				series: [res?.usageByEngines?.eng1,res?.usageByEngines?.eng2,res?.usageByEngines?.eng3,res?.usageByEngines?.gen1],
				labels: [
					'Engine 1',
					'Engine 2',
					'Engine 3',
					'Auxiliary'
				]
      }
      if(this.donutChart){
        this.donutChart.destroy();
      }
      this.donutChart = new ApexCharts(document.querySelector("#pieChart"), pieOptions);
      this.donutChart.render();
    });
  }

  getCurrentWeatherPoint(lat, lng){
    this.vesselSvc.getCurrentWeather(lat, lng)
    .subscribe((res) => {
      this.windSpeed = res?.current?.wind_speed_10m + res?.current_units.wind_speed_10m;
      this.windDirection = res?.current?.wind_direction_10m + res?.current_units.wind_direction_10m;
      this.temperature = res?.current?.temperature_2m + res?.current_units.temperature_2m;
      this.weather = this.vesselSvc.convertWeatherCode(res?.current?.weather_code);
      this.weatherIcon = this.vesselSvc.weatherBg(res?.current?.weather_code);
    });
    this.vesselSvc.getCurrentMarine(lat, lng)
    .subscribe((res) => {
      this.waveHeight = res?.current?.wave_height + res?.current_units.wave_height;
      this.waveDirection = res?.current?.wave_direction + res?.current_units.wave_direction;
      this.swellHeight = res?.current?.swell_wave_height + res?.current_units.swell_wave_height;
      this.swellDirection = res?.current?.swell_wave_direction + res?.current_units.swell_wave_direction;
      this.oceanCurrentHeight = res?.current?.ocean_current_velocity + res?.current_units.ocean_current_velocity;
      this.oceanCurrentDirection = res?.current?.ocean_current_direction + res?.current_units.ocean_current_direction;
    });
  }
}
