import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { GeneralService } from '../../../services/general.service';
import { StateService } from '../../../services/state.service';
import { ReportService } from '../../../services/report.service';

@Component({
  selector: 'app-full-movement',
  templateUrl: './full-movement.component.html',
  styleUrls: ['./full-movement.component.css']
})
export class FullMovementComponent implements OnInit, OnDestroy {

  vesselCall:any;
  datatbl:any;
  isSubmitting = false;
  allSpeeds:any = [];
  allTimes:any = [];

  rptCall:any;
  rptFuel:any;
  speedchart:any;
  linechart:any;

  dataRender = true;
  initSize = 100;
  loaded = 0;
  totalSize = 0;
  allResults:any = [];
  isLoading = false;
  isSubmittingXls = false;
  isSubmittingPdf = false;

  showMinOnly = false;

  constructor(private generalSvc: GeneralService, private apiSvc: ApiService, private stateSvc: StateService, private rptSvc: ReportService) { }

  intervalOpts = [
    {
      id: 1,
      value: 1
    },
    {
      id: 5,
      value: 5
    },
    {
      id: 10,
      value: 10
    },
    {
      id: 15,
      value: 15
    },
    {
      id: 30,
      value: 30
    },
    {
      id: 45,
      value: 45
    },
    {
      id: 60,
      value: 60
    },
  ];
    
  vesselOpts:any = [];
  
  rptType = "Full Movement";
  vessel:any = "2";
  rptFrom = "2024-01-02 00:00:00";
  rptTo = "2024-01-06 23:59:59";
  fromPicker:any = {};
  toPicker:any = {};
  intervalMin = 1;
  excludeZero = false;
  
  results:any = [];
  resultUsage:any = {};
  resultPercent:any = {};
  resultUsageRaw:any = {};
  maxSpeed:any = 0;
  totalDistance:any = 0;
  gpsUptime:any = 100;

  ngOnInit(): void {
    this.callVessels();
    this.rptFrom = this.generalSvc.getTodayStart();
	  this.rptTo = this.generalSvc.getToday();

    if(this.stateSvc.getReportStartDate() != ""){
      this.rptFrom = this.generalSvc.convertLastDate(this.stateSvc.getReportStartDate());
    }
    if(this.stateSvc.getReportEndDate() != ""){
      this.rptTo = this.generalSvc.convertLastDate(this.stateSvc.getReportEndDate());
    }

    this.fromPicker = $('#from').flatpickr({
      enableTime: true,
      enableSeconds: true,
      dateFormat: "d/m/Y H:i:s",
      defaultDate: new Date(this.rptFrom),
      onChange: (selectedDates, dateStr, instance) => {
        this.rptFrom = dateStr;
        this.stateSvc.setReportStartDate(this.rptFrom);
        this.toPicker.set('minDate', this.rptFrom);
      }
    });
    this.rptFrom = $('#from').val();
  
    this.toPicker = $('#to').flatpickr({
      enableTime: true,
      enableSeconds: true,
      dateFormat: "d/m/Y H:i:s",
      defaultDate: new Date(this.rptTo),
      onChange: (selectedDates, dateStr, instance) => {
        this.rptTo = dateStr;
        this.stateSvc.setReportEndDate(this.rptTo);
        this.fromPicker.set('maxDate', this.rptTo);
      }
    });
    this.rptTo = $('#to').val();

    this.stateSvc.setReportStartDate(this.rptFrom);
    this.stateSvc.setReportEndDate(this.rptTo);
    this.fromPicker.set('maxDate', this.rptTo);
	  this.toPicker.set('minDate', this.rptFrom);

    $(window).scroll(() => {
      var scrollPos = $(document).scrollTop();
      let lastPos = $(document).height() - ($(window).height() + scrollPos);
      if(lastPos < 80 && this.allResults.length > 0 && this.dataRender && !this.isLoading){
        this.runLoading();
      }
    });
  }

  ngOnDestroy(){
    if(this.vesselCall){
      this.vesselCall.unsubscribe();
    }
    if(this.rptCall){
      this.rptCall.unsubscribe();
    }
    if(this.datatbl){
      $("#example1").DataTable().destroy();
    }
    if(this.speedchart){
      this.speedchart.destroy();
    }
    if(this.linechart){
      this.linechart.destroy();
    }
    if(this.rptFuel){
      this.rptFuel.unsubscribe();
    }
  }

  callVessels(){
    this.vesselCall = this.stateSvc.sessionVessels
    .subscribe((result:any) => {
      this.vesselOpts = result.vessels;
    });
  }

  generateReport(){
    let startDate = new Date(this.generalSvc.convertLastDate(this.rptFrom));
	  let toDate = new Date(this.generalSvc.convertLastDate(this.rptTo));

    if(startDate > toDate){
      this.generalSvc.showToast('To date must be greater than From Date', "error");
      return;
    }

    if(!this.vessel){
      this.generalSvc.showToast('Please select a vessel', "error");
      return;
    }

    if(!this.showMinOnly){
      // Calculating the time difference of two dates
      let differenceInTime = toDate.getTime() - startDate.getTime();
      // Calculating the no. of days between two dates
      let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
      if(differenceInDays > 31){
        this.generalSvc.showToast('Date range selection cannot be more than 31 days', "error");
        return;
      }
    }else{
      // Calculating the time difference of two dates
      let differenceInTime = toDate.getTime() - startDate.getTime();
      // Calculating the no. of days between two dates
      let differenceInDays = differenceInTime / (1000 * 3600 * 24);
      if(differenceInDays > 1){
        this.generalSvc.showToast('Date range selection cannot be more than 1 days', "error");
        return;
      }
    }

    this.isSubmitting = true;
    this.generalSvc.showLoading();
    let data:any = {
      type: this.rptType,
      vessel: this.vessel,
      from: this.generalSvc.convertLastDate(this.rptFrom),
		  to: this.generalSvc.convertLastDate(this.rptTo),
      interval: this.intervalMin,
      excludeZero: this.excludeZero,
      showMin: this.showMinOnly
    };
    let seriesTimeline:any = [
      {
        name: "Stopped",
        data: [
          {
            x: "State",
            y: [
              0,
              6
            ]
          }
        ]
      },
      {
        name: "Idling",
        data: [
          {
            x: "State",
            y: [
              6,
              8
            ]
          }
        ]
      },
      {
        name: "Transit",
        data: [
          {
            x: "State",
            y: [
              8,
              12
            ]
          }
        ]
      },
      {
        name: "Manoeuvring",
        data: [
          {
            x: "State",
            y: [
              12,
              16
            ]
          }
        ]
      },
      {
        name: "Idling",
        data: [
          {
            x: "State",
            y: [
              16,
              17
            ]
          }
        ]
      },
      {
        name: "Stopped",
        data: [
          {
            x: "State",
            y: [
              17,
              20
            ]
          }
        ]
      }
    ];
    if(this.datatbl){
      $("#example1").DataTable().destroy();
    }
    if(this.speedchart){
      this.speedchart.destroy();
    }
    if(this.linechart){
      this.linechart.destroy();
    }
    this.allResults = [];
    this.results = [];
    this.loaded = 0;
    this.totalSize = 0;
    this.dataRender = true;
	  this.isLoading = false;
    this.rptCall = this.rptSvc.generateReportFm(data)
    .subscribe((res) => {
      $('.filter-card').addClass('collapsed-card');
      this.allResults = res?.result;
      this.resultUsage = res?.total;
      this.resultUsageRaw = res?.totalRaw;
      this.resultPercent = res?.percent;
      this.maxSpeed = res?.speedAvg?.max;
      this.allSpeeds = res?.speeds;
      this.allTimes = res?.timelines;
      this.totalDistance = res?.totalDistance;
      this.gpsUptime = res?.percentByGps?.percent;
      if(res?.result?.length == 0){
        this.generalSvc.showToast('No result found on the selected date time', "error");
        this.isSubmitting = false;
        return;
      }else{
        // res.result.forEach((dt, ix) => {
        // if(ix < this.initSize){
        //     this.results.push(dt);
        //   }else{
        //     return;
        //   }
        // })
      }
      setTimeout(() => {
        this.datatbl = $("#example1").DataTable({
          "responsive": true, "lengthChange": false, "autoWidth": false,
          //"buttons": ["excel", "print", "colvis"], 
          // "pageLength": 25,
          "paging": false,
          "ordering": false,
          "info": false,
          "searching": false
        });//.buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
        this.generalSvc.hideLoading();
        this.isSubmitting = false;
        $('.dataTables_empty').hide();
			  this.runLoading();

        // setTimeout(() => {
        //   res.result.forEach((dt, ix) => {
        //     if(ix >= this.initSize){
        //     this.results.push(dt);
        //     }
        //   });
        //   this.dataRender = false;
        // },500)
        // seriesTimeline = res?.stateArr;

        this.rptFuel = this.rptSvc.generateReport(data)
        .subscribe((resFuel) => {
          let bencmarkVals:any = [];
          let consVals:any = [];
          if(res?.timelines){
            let dataLen = res.timelines.length;
            for(let i = 0;i < dataLen; i++){
              bencmarkVals.push(12);
            }
          }

          if(resFuel?.result){
            resFuel.result.forEach((fuel) => {
              consVals.push(fuel.total_cons_beauty);
            });
          }
          
          var lineOptions = {
            chart: {
              type: 'line',
              height: 400
            },
            series: [
              {
                name: "Speed",
                data: res.speeds,
                type: 'line',
              },
              // {
              //   name: "Benchmark",
              //   data: bencmarkVals,
              //   color: "#FF0000"
              // }
              {
                name: "Consumption",
                data: consVals
              }
            ],
            xaxis: {
              categories: res.timelines,
              labels: {
                datetimeFormatter: {
                  year: 'yyyy',
                  month: 'MMM \'yy',
                  day: 'dd MMM',
                  hour: 'HH:mm'
                }
              },
              tickAmount: 12
            },
            yaxis: [{
              title: {
                text: '(Knot)',
              },
            
            }, {
              opposite: true,
              title: {
                text: '(Liters)'
              }
            }],
            stroke: {
              curve: 'smooth'
            }
          }
          // this.speedchart = new ApexCharts(document.querySelector("#speedChart"), lineOptions);
          // this.speedchart.render();
        },(err) => {
          this.generalSvc.handleErr(err);
        });
        
      },1000);
      // document?.getElementById("card_result")?.scrollIntoView();
    }, (err) => {
      this.isSubmitting = false;
      this.generalSvc.handleErr(err);
      this.generalSvc.hideLoading();
    });
  }
  
  runLoading(){
    this.isLoading = true;
    setTimeout(() => {
      if(this.allResults.length >= this.loaded){
        let endIx = this.loaded + this.initSize;
        this.allResults.forEach((dt, ix) => {
          if(ix >= this.loaded && ix < endIx){
            this.results.push(dt);
            this.isLoading = false;
          }else{
            return;
          }
        })	
        this.loaded = endIx;
      }else{
        this.dataRender = false;
      }
    }, 500);
  }

  generateReportExcel(){
    this.isSubmittingXls = true;
    let data:any = {
      type: this.rptType,
      vessel: this.vessel,
      from: this.generalSvc.convertLastDate(this.rptFrom),
      to: this.generalSvc.convertLastDate(this.rptTo),
      interval: this.intervalMin,
      excludeZero: this.excludeZero,
      file_type: "excel",
      showMin: this.showMinOnly
    };
    this.rptCall = this.rptSvc.generateReportExport(data,"report-full-movement-export")
    .subscribe((res) => {
      let a= document.createElement('a');
      a.target= '_blank';
      a.href= res.url;
      a.click();
      this.isSubmittingXls = false;
    }, (err) => {
      this.generalSvc.handleErr(err);
      this.isSubmittingXls = false;
    });
  }

  generateReportPdf(){
    this.isSubmittingPdf = true;
    let data:any = {
      type: this.rptType,
      vessel: this.vessel,
      from: this.generalSvc.convertLastDate(this.rptFrom),
      to: this.generalSvc.convertLastDate(this.rptTo),
      interval: this.intervalMin,
      excludeZero: this.excludeZero,
      file_type: "pdf",
      showMin: this.showMinOnly
    };
    this.rptCall = this.rptSvc.generateReportExport(data, "report-full-movement-export")
    .subscribe((res) => {
      let a= document.createElement('a');
      a.target= '_blank';
      a.href= res.url;
      a.click();
      this.isSubmittingPdf = false;
    }, (err) => {
      this.generalSvc.handleErr(err);
      this.isSubmittingPdf = false;
    });
  }

}
