<div id="map"></div>
<div class="map-overlay" *ngIf="showVes">
    <div class="listing">
		<div class="offcanvas-body p-0">
			<div class="card-body px-0">
				<div data-simplebar class="card-body py-0" style="max-height: 100%;">
					<div class="row g-3">
						<div class="col-md-12">
							<select class="form-control" [(ngModel)]="selectVessel">
								<option *ngFor="let ves of vessels" [value]="ves.UnitID">{{ ves.Description }}</option>
							</select>
                        </div>
                        <div class="col-md-12">
							<input class="form-control" (change)="changedDate('from')" id="from" [(ngModel)]="from" type="datetime-local" placeholder="From" step="1">
                        </div><!--end col-->
                        <div class="col-md-12">
                            <input class="form-control" (change)="changedDate('to')" id="to" type="datetime-local" [(ngModel)]="to" placeholder="To" step="1">
                        </div><!--end col-->
                        <div class="col-xxl-auto col-sm-6">
                            <button type="button" class="btn btn-dark w-md" [disabled]="isSubmitting" (click)="generateReport()">
								<span class="d-flex align-items-center" *ngIf="isSubmitting">
									<span class="spinner-border flex-shrink-0" role="status">
										<span class="visually-hidden">Loading...</span>
									</span>
									<span class="flex-grow-1 ms-2">
										Loading...
									</span>
								</span>
								<span *ngIf="!isSubmitting">
									<i class="bi bi-search align-baseline me-1"></i> Search
								</span>
							</button>
                        </div><!--end col-->
                    </div><!--end row-->
				</div>
			</div>
		</div>
	</div>
</div>
<div class="d-md-block" style="position:fixed; z-index:1000;right: 0.25rem;top: 5rem;" title="Show/hide Search panel" (click)="toggleVes()">
	<button class="btn p-2 btn-icon shadow-lg" [ngClass]="{'btn-light': !showVes, 'btn-dark':showVes}">
		<i class="bx bx-search bx-sm"></i>
	</button>
</div>
<div class="d-md-block" style="position:fixed; z-index:1000;right: 0.25rem;top: 7.7rem;" title="POIs" (click)="togglePoi()">
	<button class="btn p-2 btn-icon shadow-lg" [ngClass]="{'btn-light': showPoi, 'btn-dark':!showPoi}">
		<i class="bx bxs-map-pin bx-sm"></i>
	</button>
</div>
<div class="d-md-block" style="position:fixed; z-index:1000;right: 0.25rem;top:10.4rem;"title="Vessels" (click)="toggleMap('standard')">
	<button class="btn p-2 btn-icon shadow-lg" [ngClass]="{'btn-dark': mapStyle == 'standard', 'btn-light': mapStyle != 'standard'}" title="Standard">
		<i class="bx bx-map-alt bx-sm"></i>
	</button>
</div>
<div class="d-md-block" style="position:fixed; z-index:1000;right: 0.25rem;top:13.1rem;"title="Vessels" (click)="toggleMap('standard-satellite')">
	<button class="btn p-2 btn-icon shadow-lg" [ngClass]="{'btn-dark': mapStyle != 'standard', 'btn-light': mapStyle == 'standard'}" title="Satellite">
		<i class="bx bx-globe bx-sm"></i>
	</button>
</div>
<!-- Grids in modals -->
<div class="modal fade" id="exampleModalgrid" tabindex="-1" aria-labelledby="exampleModalgridLabel" aria-modal="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalgridLabel">Plot Point Information</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<form action="javascript:void(0);">
					<div class="row g-3">
						<div class="col-xxl-6">
							<div>
								<label for="firstName" class="form-label mb-0 bold-text">Timestamp</label>
								<p class="mb-1">{{ pointSelected.Updated }}</p>
							</div>
						</div><!--end col-->
						<div class="col-xxl-6">
							<div>
								<label for="lastName" class="form-label mb-0 bold-text">Current State</label>
								<p class="mb-1">{{ pointSelected.VesselState }}</p>
							</div>
						</div><!--end col-->
						<div class="col-xxl-6">
							<div>
								<label for="emailInput" class="form-label mb-0 bold-text">Communication</label>
								<p class="mb-1">{{ pointSelected.ComMode }}</p>
							</div>
						</div><!--end col-->
						<div class="col-xxl-6">
							<div>
								<label for="emailInput" class="form-label mb-0">GPS</label>
								<p class="mb-1">{{ pointSelected.GPS }}</p>
							</div>
						</div><!--end col-->
						<div class="col-xxl-6">
							<div>
								<label for="passwordInput" class="form-label mb-0 bold-text">Speed</label>
								<p class="mb-1">{{ pointSelected.Speed }} knot</p>
							</div>
						</div><!--end col-->
						<div class="col-xxl-6">
							<div>
								<label for="passwordInput" class="form-label mb-0 bold-text">Bearing</label>
								<p class="mb-1">{{ pointSelected.Bearing }}&deg;</p>
							</div>
						</div><!--end col-->
						<div class="col-xxl-6">
							<div>
								<label for="passwordInput" class="form-label mb-0 bold-text">Heading</label>
								<p class="mb-1">{{ pointSelected.Direction }}</p>
							</div>
						</div><!--end col-->
						<div class="col-xxl-6">
							<div>
								<label for="passwordInput" class="form-label mb-0 bold-text">Coordinate</label>
								<p class="mb-1">{{ pointSelected.Location }}</p>
							</div>
						</div><!--end col-->
						<div class="col-xxl-6">
							<div>
								<label for="passwordInput" class="form-label mb-0 bold-text">Engine 1</label>
								<p class="mb-1"><span class="badge bg-{{ pointSelected.Engine_1_badge }} rounded-circle align-bottom">&nbsp;</span> {{ pointSelected.Eng1 }}</p>
							</div>
						</div><!--end col-->
						<div class="col-xxl-6">
							<div>
								<label for="passwordInput" class="form-label mb-0 bold-text">Engine 2</label>
								<p class="mb-1"><span class="badge bg-{{ pointSelected.Engine_2_badge }} rounded-circle align-bottom">&nbsp;</span> {{ pointSelected.Eng2 }}</p>
							</div>
						</div><!--end col-->
						<div class="col-xxl-6">
							<div>
								<label for="passwordInput" class="form-label mb-0 bold-text">Engine 3</label>
								<p class="mb-1"><span class="badge bg-{{ pointSelected.Engine_3_badge }} rounded-circle align-bottom">&nbsp;</span> {{ pointSelected.Eng3 }}</p>
							</div>
						</div><!--end col-->
						<div class="col-xxl-6">
							<div>
								<label for="passwordInput" class="form-label mb-0 bold-text">Auxiliary</label>
								<p class="mb-1"><span class="badge bg-{{ pointSelected.Aux_2_badge }} rounded-circle align-bottom">&nbsp;</span> {{ pointSelected.Aux2 }}</p>
							</div>
						</div><!--end col-->
					</div><!--end row-->
				</form>
			</div>
		</div>
	</div>
</div>