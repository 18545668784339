import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { GeneralService } from '../../../services/general.service';
import { StateService } from '../../../services/state.service';

@Component({
  selector: 'app-individu',
  templateUrl: './individu.component.html',
  styleUrls: ['./individu.component.css']
})
export class IndividuComponent implements OnInit, OnDestroy {

  vesselsCall:any;
  
  sizeCard = 6;
  allVessels:any = [];
  shownVessels:any = [];
  points:any = [];
  filterVessel:any = [];
  
  constructor(private generalSvc: GeneralService, private apiSvc: ApiService, private stateSvc: StateService) { }

  ngOnInit(): void {
	mapboxgl.accessToken = this.apiSvc.getMapBoxKey();
	
	setTimeout(() => {
		this.callVessels();
	}, 1200);
  }
  
  ngOnDestroy(){
	if(this.vesselsCall){
		this.vesselsCall.unsubscribe();
	}
  }
  
  callVessels(){
	this.vesselsCall = this.stateSvc.sessionVessels
	.subscribe((result:any) => {
		this.allVessels = result.vessels;
		this.points = result.points;
		let selectedVes = $('.ves-opt').val();
		this.shownVessels = [];
		if(this.allVessels?.length > 0){
			this.allVessels.forEach((ves) => {
				if(selectedVes.indexOf((ves.UnitID).toString()) > -1){
					this.shownVessels.push(ves);
					setTimeout(() => {
						// improve map kalau dah exist, jgn rebuild
						ves.map = new mapboxgl.Map({
							container: 'map-'+ves.UnitID, // container ID
							center: [ves.Lon, ves.Lat], // starting position [lng, lat]
							zoom: 10 // starting zoom
						});
						setTimeout(() => {
							let popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
							  '<h5>'+ves.Description+'</h5>'+'<br><p>Type: '+ves.VesselType+'<br>GPS Status: '+ves.GPS+'<br>Bearing: '+ves.Bearing+"&deg;"+'<br>Heading: '+ves.Direction+'<br>State: '+ves.VesselState+'<br>Speed: '+ves.Speed+' knot</p>'
							);

							let vesselImg = "ship-off.png";
							if(ves.is_engine_on){
								if(ves.VesselState == "Stopped"){
									vesselImg = "ship-stopped.png";
								}else{
									vesselImg = "ship-moving.png";
								}
							}
							
							// let elMkr = marker1.getElement();
							let elMkr = document.createElement('div');
							let tmpThis = this;
							elMkr.className = "marker";
							elMkr.style.backgroundImage = 'url('+this.apiSvc.getBaseFe()+'/assets/images/'+vesselImg+')';
							elMkr.style.width = '42px';
							elMkr.style.height = '42px';
							elMkr.style.backgroundSize = '100%';
							elMkr.style.borderRadius = "50%";
							elMkr.style.boxShadow = "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)";

							let marker1 = new mapboxgl.Marker(elMkr)
							.setLngLat([ves.Lon, ves.Lat])
							.setPopup(popup)
							.addTo(ves.map);
						}, 1500);
					}, 1500);
				}
			});
		}
	});
  }

}
