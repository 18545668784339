<!-- start page title -->
<div class="row">
	<div class="col-12">
		<div class="page-title-box d-sm-flex align-items-center justify-content-between">
			<h4 class="mb-sm-0">Report - Abnormality</h4>

		</div>
	</div>
</div>
<!-- end page title -->

<div class="row mb-2">
	<div class="col-lg-12">
		<div class="card">
			<div class="card-body">
				<div class="row g-3">
					<div class="col-xxl">
						<div>
							<label>Vessel</label>
							<select class="form-control" id="idCategory" [(ngModel)]="vessel">
								<option *ngFor="let ves of vesselOpts" [value]="ves.UnitID">{{ ves.Description }}</option>
							</select>
						</div>
					</div><!--end col-->
					<div class="col-xxl">
						<label>From</label>
						<input class="form-control" id="from" type="datetime-local" [(ngModel)]="rptFrom" placeholder="From" step="1">
					</div><!--end col-->
					<div class="col-xxl">
						<label>To</label>
						<input class="form-control" id="to" type="datetime-local" [(ngModel)]="rptTo" placeholder="To" step="1">
					</div><!--end col-->
					<!-- <div class="col-xxl">
						<div class="form-group">
							<label>Interval</label>
							<select class="form-control" id="vess" [(ngModel)]="intervalMin">
								<option *ngFor="let opt of intervalOpts" [value]="opt.id">{{ opt.value }}</option>
							</select>
						</div>
					</div> -->
					<div class="col-xxl-auto col-sm-6 pt-3">
						<button type="button" class="btn btn-dark w-md" (click)="generateReport()" [disabled]="isSubmitting">
							<span class="d-flex align-items-center" *ngIf="isSubmitting">
								<span class="spinner-border flex-shrink-0" role="status">
									<span class="visually-hidden">Loading...</span>
								</span>
								<span class="flex-grow-1 ms-2">
									Loading...
								</span>
							</span>
							<span *ngIf="!isSubmitting">
								<i class="bi bi-search align-baseline me-1"></i> Search
							</span>
						</button>
					</div><!--end col-->
				</div><!--end row-->
			</div>
		</div>
	</div><!--end col-->
</div><!--end row-->

<div class="card" id="card_result" style="min-height: 300px;" *ngIf="allResults.length > 0">
	<div class="card-header bg-gray">
	  <h3 class="card-title">Abnormality Events Summary</h3>
	  <div class="card-tools">
		<button type="button" class="btn btn-tool" data-card-widget="collapse">
		  <i class="fas fa-minus"></i>
		</button>
	  </div>
	</div>
	<div class="card-body">
	  <div class="row" >
		<div class="col-md-12">
			<div class="btn-group" role="group" aria-label="Export">
				<button type="button" class="btn btn-secondary" (click)="generateReportExcel()" [disabled]="isSubmittingXls">
					<span class="d-flex align-items-center" *ngIf="isSubmittingXls">
						<span class="spinner-border flex-shrink-0" role="status">
							<span class="visually-hidden">Downloading...</span>
						</span>
						<span class="flex-grow-1 ms-2">
							Downloading...
						</span>
					</span>
					<span *ngIf="!isSubmittingXls">
						Excel
					</span>
				</button>
				<button type="button" class="btn btn-secondary" (click)="generateReportPdf()" [disabled]="isSubmittingPdf">
					<span class="d-flex align-items-center" *ngIf="isSubmittingPdf">
						<span class="spinner-border flex-shrink-0" role="status">
							<span class="visually-hidden">Downloading...</span>
						</span>
						<span class="flex-grow-1 ms-2">
							Downloading...
						</span>
					</span>
					<span *ngIf="!isSubmittingPdf">
						PDF
					</span>
				</button>
			</div>
		</div>
		<div class="col-md-12">
			<table id="example1" class="table table-bordered table-striped table-head-fixed">
			  <thead class="table-aqua">
			  <tr>
				<th>Vessel</th>
				<th>Date Time</th>
				<th colspan="4">Consumption</th>
				<th colspan="4">Density</th>
				<th>All Engines Off</th>
				<th>Reporting Missing</th>
			  </tr>
              <tr>
                <th></th>
                <th></th>
                <th>Engine1</th>
                <th>Engine2</th>
                <th>Engine3</th>
                <th>Auxiliary</th>
                <th>Engine1</th>
                <th>Engine2</th>
                <th>Engine3</th>
                <th>Auxiliary</th>
                <th></th>
                <th></th>
              </tr>
			  </thead>
			  <tbody>
				<tr *ngFor="let res of results">
					<td>{{ res.vesselName }}</td>
					<td>{{ res.date }}</td>
					<td class="text-right">
                        <a class="icon-link icon-link-hover text-decoration-underline link-underline link-underline-opacity-75-hover text-secondary" *ngIf="res?.counter?.consumption?.eng1 > 0" (click)="showConsume(res, 'eng1')">
                            {{ res?.counter?.consumption?.eng1 }}    
                        </a>
                        <span *ngIf="res?.counter?.consumption?.eng1 == 0">
                            {{ res?.counter?.consumption?.eng1 }}
                        </span>
                    </td>
					<td class="text-right">
                        <a class="icon-link icon-link-hover text-decoration-underline link-underline link-underline-opacity-75-hover text-secondary" *ngIf="res?.counter?.consumption?.eng2 > 0" (click)="showConsume(res, 'eng2')">
                            {{ res?.counter?.consumption?.eng2 }}    
                        </a>
                        <span *ngIf="res?.counter?.consumption?.eng2 == 0">
                            {{ res?.counter?.consumption?.eng2 }}
                        </span>
                    </td>
					<td class="text-right">
                        <a class="icon-link icon-link-hover text-decoration-underline link-underline link-underline-opacity-75-hover text-secondary" *ngIf="res?.counter?.consumption?.eng3 > 0" (click)="showConsume(res, 'eng3')">
                            {{ res?.counter?.consumption?.eng3 }}    
                        </a>
                        <span *ngIf="res?.counter?.consumption?.eng3 == 0">
                            {{ res?.counter?.consumption?.eng3 }}
                        </span>
                    </td>
					<td class="text-right">
                        <a class="icon-link icon-link-hover text-decoration-underline link-underline link-underline-opacity-75-hover text-secondary" *ngIf="res?.counter?.consumption?.aux > 0" (click)="showConsume(res, 'aux')">
                            {{ res?.counter?.consumption?.aux }}    
                        </a>
                        <span *ngIf="res?.counter?.consumption?.aux == 0">
                            {{ res?.counter?.consumption?.aux }}
                        </span>
                    </td>
                    <td class="text-right">
                        <a class="icon-link icon-link-hover text-decoration-underline link-underline link-underline-opacity-75-hover text-secondary" *ngIf="res?.counter?.density?.eng1 > 0" (click)="showDensity(res, 'eng1')">
                            {{ res?.counter?.density?.eng1 }}    
                        </a>
                        <span *ngIf="res?.counter?.density?.eng1 == 0">
                            {{ res?.counter?.density?.eng1 }}
                        </span>
                    </td>
					<td class="text-right">
                        <a class="icon-link icon-link-hover text-decoration-underline link-underline link-underline-opacity-75-hover text-secondary" *ngIf="res?.counter?.density?.eng2 > 0" (click)="showDensity(res, 'eng2')">
                            {{ res?.counter?.density?.eng2 }}    
                        </a>
                        <span *ngIf="res?.counter?.density?.eng2 == 0">
                            {{ res?.counter?.density?.eng2 }}
                        </span>
                    </td>
					<td class="text-right">
                        <a class="icon-link icon-link-hover text-decoration-underline link-underline link-underline-opacity-75-hover text-secondary" *ngIf="res?.counter?.density?.eng3 > 0" (click)="showDensity(res, 'eng3')">
                            {{ res?.counter?.density?.eng3 }}    
                        </a>
                        <span *ngIf="res?.counter?.density?.eng3 == 0">
                            {{ res?.counter?.density?.eng3 }}
                        </span>
                    </td>
					<td class="text-right">
                        <a class="icon-link icon-link-hover text-decoration-underline link-underline link-underline-opacity-75-hover text-secondary" *ngIf="res?.counter?.density?.aux > 0" (click)="showDensity(res, 'aux')">
                            {{ res?.counter?.density?.aux }}    
                        </a>
                        <span *ngIf="res?.counter?.density?.aux == 0">
                            {{ res?.counter?.density?.aux }}
                        </span>
                    </td>
					<td class="text-right">
                        <a class="icon-link icon-link-hover text-decoration-underline link-underline link-underline-opacity-75-hover text-secondary" *ngIf="res?.counter?.engine_off > 0" (click)="showAllOff(res)">
                            {{ res?.counter?.engine_off }}    
                        </a>
                        <span *ngIf="res?.counter?.engine_off == 0">
                            {{ res?.counter?.engine_off }}
                        </span>
                    </td>
                    <td class="text-right">
                        <a class="icon-link icon-link-hover text-decoration-underline link-underline link-underline-opacity-75-hover text-secondary" *ngIf="res?.counter?.data_missing > 0" (click)="showMissingData(res)">
                            {{ res?.counter?.data_missing }}    
                        </a>
                        <span *ngIf="res?.counter?.data_missing == 0">
                            {{ res?.counter?.data_missing }}
                        </span>
                    </td>
				</tr>
			  </tbody>
			</table>
		</div>
	  </div>
	  <div class="row mt-3">
		<p *ngIf="dataRender">Rendering {{ allResults.length }} records..</p>
		<p *ngIf="!dataRender && results?.length > 0"><strong>Total {{results.length}} records</strong></p>
	  </div>
      <div class="row mt-5" >
        <p *ngIf="selectedDetailEvent">Event: <span>{{ selectedDetailEvent }}</span></p>
        <p *ngIf="selectedDetailEvent">Date: <span>{{ selectedDetailDate }}</span></p>
        <div class="col-md-12">
            <table id="example2" class="table table-bordered table-striped table-head-fixed force-hide">
                <thead class="table-aqua">
                <tr>
                  <th>Reporting Time Missing</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let dmd of detailMissingData">
                      <td>{{ dmd }}</td>
                  </tr>
                </tbody>
            </table>
            <table id="example3" class="table table-bordered table-striped table-head-fixed force-hide">
                <thead class="table-aqua">
                <tr>
                  <th>Vessel</th>
                  <th>Date Time</th>
                  <th>Consumption (l)</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let ao of detailAllOff">
                      <td>{{ ao.vesselName }}</td>
                      <td>{{ ao.datetimestamp }}</td>
                      <td class="text-right">{{ ao.total_cons }}</td>
                  </tr>
                </tbody>
            </table>
            <table id="example4" class="table table-bordered table-striped table-head-fixed force-hide">
                <thead class="table-aqua">
                <tr>
                  <th>Vessel</th>
                  <th>Date Time</th>
                  <th>Engine 1 Supply</th>
                  <th>Engine 1 Return</th>
                  <th>Engine 2 Supply</th>
                  <th>Engine 2 Return</th>
                  <th>Engine 3 Supply</th>
                  <th>Engine 3 Return</th>
                  <th>Auxiliary Supply</th>
                  <th>Auxiliary Return</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let dd of detailDensity">
                    <td>{{ dd.vesselName }}</td>
                    <td>{{ dd.datetimestamp }}</td>
                    <td class="text-right" [ngClass]="{'text-danger': dd.faulty == 'eng1' && dd.faultyType == 'supply'}">{{ dd.EngDen1FWD }}</td>
					<td class="text-right" [ngClass]="{'text-danger': dd.faulty == 'eng1' && dd.faultyType == 'return'}">{{ dd.EngDen1RET }}</td>
					<td class="text-right" [ngClass]="{'text-danger': dd.faulty == 'eng2' && dd.faultyType == 'supply'}">{{ dd.EngDen2FWD }}</td>
					<td class="text-right" [ngClass]="{'text-danger': dd.faulty == 'eng2' && dd.faultyType == 'return'}">{{ dd.EngDen2RET }}</td>
                    <td class="text-right" [ngClass]="{'text-danger': dd.faulty == 'eng3' && dd.faultyType == 'supply'}">{{ dd.EngDen3FWD }}</td>
					<td class="text-right" [ngClass]="{'text-danger': dd.faulty == 'eng3' && dd.faultyType == 'return'}">{{ dd.EngDen3RET }}</td>
					<td class="text-right" [ngClass]="{'text-danger': dd.faulty == 'aux' && dd.faultyType == 'supply'}">{{ dd.GenDen2FWD }}</td>
					<td class="text-right" [ngClass]="{'text-danger': dd.faulty == 'aux' && dd.faultyType == 'return'}">{{ dd.GenDen2RET }}</td>
                  </tr>
                </tbody>
            </table>
            <table id="example5" class="table table-bordered table-striped table-head-fixed force-hide">
                <thead class="table-aqua">
                <tr>
                  <th>Vessel</th>
                  <th>Date Time</th>
                  <th>Speed</th>
                  <th colspan="3">Engine 1</th>
                  <th colspan="3">Engine 2</th>
                  <th colspan="3">Engine 3</th>
                  <th colspan="3">Auxiliary</th>
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Supply (l)</th>
                    <th>Return (l)</th>
                    <th>Percent (%)</th>
                    <th>Supply (l)</th>
                    <th>Return (l)</th>
                    <th>Percent (%)</th>
                    <th>Supply (l)</th>
                    <th>Return (l)</th>
                    <th>Percent (%)</th>
                    <th>Supply (l)</th>
                    <th>Return (l)</th>
                    <th>Percent (%)</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let dd of detailConsume">
                    <td>{{ dd.vesselName }}</td>
                    <td>{{ dd.datetimestamp }}</td>
                    <td>{{ dd.Speed }}</td>
                    <td class="text-right">{{ dd.usageSupply1 }}</td>
					<td class="text-right">{{ dd.usageReturn1 }}</td>
					<td class="text-right" [ngClass]="{'text-danger': dd.faulty == 'eng1'}">{{ dd.usageRatio1 }}</td>
					<td class="text-right">{{ dd.usageSupply2 }}</td>
					<td class="text-right">{{ dd.usageReturn2 }}</td>
					<td class="text-right" [ngClass]="{'text-danger': dd.faulty == 'eng2'}">{{ dd.usageRatio2 }}</td>
                    <td class="text-right">{{ dd.usageSupply3 }}</td>
					<td class="text-right">{{ dd.usageReturn3 }}</td>
					<td class="text-right" [ngClass]="{'text-danger': dd.faulty == 'eng3'}">{{ dd.usageRatio3 }}</td>
                    <td class="text-right">{{ dd.genSupply2 }}</td>
					<td class="text-right">{{ dd.genReturn2 }}</td>
					<td class="text-right" [ngClass]="{'text-danger': dd.faulty == 'aux'}">{{ dd.genRatio2 }}</td>
                  </tr>
                </tbody>
            </table>
        </div>
      <div>
	</div>
</div>