import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { GeneralService } from '../../services/general.service';
import { StateService } from '../../services/state.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, OnDestroy {

  userCall:any;
  users:any = [];
  assignedTable:any;
  
  nowUser:any = {};
  isLoggedIn = false;
  infos:any = {};
  
  filterName = "";
  filterRole = "";
  filterStatus = "";
  
  constructor(private generalSvc: GeneralService, private apiSvc: ApiService, private stateSvc: StateService, private userSvc: UserService) { }

  ngOnInit(): void {
	this.stateSvc.isLoggedIn
	.subscribe((result:any) => {
		this.isLoggedIn = result;
		if(this.isLoggedIn){
			let sessionNow = this.stateSvc.getLocalSession();
			if(sessionNow?.user){
				this.nowUser = sessionNow?.user;
			}
		}
	});
	this.callUsers();
  }
  
  ngOnDestroy(){
	if(this.userCall){
		this.userCall.unsubscribe();
	}
  }
  
  callUsers(){
	this.generalSvc.showLoading();
	if(this.assignedTable){
		$("#example1").DataTable().destroy();
	}
	let data:any = {
		nameOrEmail: this.filterName,
		role: this.filterRole,
		status: this.filterStatus,
	};
	this.userCall = this.userSvc.getUsers(data)
	.subscribe((res) => {
		if(res?.users){
			this.users = res.users;
			this.infos = res?.infos;
			res.users.forEach((user) => {
				if(!user.image){
					user.image = "assets/images/unknown.jpg";
				}
			});
			setTimeout(()=>{
				this.assignedTable = $("#example1").DataTable({
				  "responsive": true, "lengthChange": false, "autoWidth": false, paging: false, searching: false, info: false,
				  "buttons": ["excel", "pdf", "print"]
				}).buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
				this.generalSvc.hideLoading();
			}, 1000);
		}
	});
  }

}
