import { Injectable } from '@angular/core';
import { GeneralService } from './general.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {

	private _isLoggedIn = new BehaviorSubject<any>(false);
	isLoggedIn = this._isLoggedIn.asObservable();
	private _userSession = new BehaviorSubject<any>({});
	userSession = this._userSession.asObservable();
	private _commissionDetailName = new BehaviorSubject<any>("");
	commissionDetailName = this._commissionDetailName.asObservable();
	private _sessionVessels = new BehaviorSubject<any>([]);
	sessionVessels = this._sessionVessels.asObservable();
	private _summaryStateVessels = new BehaviorSubject<any>({});
	summaryStateVessels = this._summaryStateVessels.asObservable();
	private _shownVessels = new BehaviorSubject<any>({});
	shownVessels = this._shownVessels.asObservable();
	reportStartDate:any = "";
	reportEndDate:any = "";
					
  constructor(private generalSvc: GeneralService) { }
  
	setIsLoggedIn(t) {
		this._isLoggedIn.next(t)
	}
	
	getLocalSession() {
		let t:any = localStorage.getItem("user-marine");
		return JSON.parse(t)
	}
	
	checkActiveSession() {
		var t;
		let e = this.getLocalSession();
		if(e?.session_id){
			return true;
		}else{
			return false;
		}
		// return !!(null === (t = null == e ? void 0 : e.user) || void 0 === t ? void 0 : t.session_id)
	}
	
	logout() {
		localStorage.removeItem("user-marine");
		this.setIsLoggedIn(!1);
		this.generalSvc.offSessionLayout()
	}
	
	setUserSession(t) {
		this._userSession.next(t)
	}
	
	setCommissionDetailName(t) {
		this._commissionDetailName.next(t)
	}
	
	setSessionVessels(t) {
		this._sessionVessels.next(t)
	}
	
	setSummaryStateVessels(t){
		this._summaryStateVessels.next(t);
	}
	
	setShownVessels(t){
		this._shownVessels.next(t);
	}

	setReportStartDate(t){
		this.reportStartDate = t;
	}

	setReportEndDate(t){
		this.reportEndDate = t;
	}

	getReportStartDate(){
		return this.reportStartDate;
	}

	getReportEndDate(){
		return this.reportEndDate;
	}
}
