<div class="app-menu navbar-menu" [ngClass]="{'force-hide': !isLoggedIn}">
	<!-- LOGO -->
	<div class="navbar-brand-box">
	  <a href="javascript://" class="logo logo-dark">
		<span class="logo-sm">
			<img src="assets/images/logo-02.png" alt="" height="60">
		</span>
		<span class="logo-lg">
			<img src="assets/images/logo-02.png" alt="" height="60">
		</span>
	  </a>
	  <a href="javascript://" class="logo logo-light">
		<span class="logo-sm">
			<img src="assets/images/logo-02.png" alt="" height="60">
		</span>
		<span class="logo-lg">
			<img src="assets/images/logo-02.png" alt="" height="60">
		</span>
	  </a>
	  <button type="button" class="btn btn-sm p-0 fs-3xl header-item float-end btn-vertical-sm-hover" id="vertical-hover">
		<i class="ri-record-circle-line"></i>
	  </button>
	</div>
	<div id="scrollbar">
	  <div class="container-fluid">
		<div id="two-column-menu"></div>
		<ul class="navbar-nav" id="navbar-nav">
		  <li class="menu-title">
			<span data-key="t-menu">Menu</span>
		  </li>
		  <li class="nav-item">
			<a routerLink="/dashboard" class="nav-link menu-link">
			  <i class="ph-gauge-bold"></i>
			  <span data-key="t-dashboards">Dashboards</span>
			</a>
		  </li>
		  <li class="nav-item">
			<a class="nav-link menu-link collapsed" href="#sidebarMaps" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarMaps">
			  <i class="ph-map-trifold-bold"></i>
			  <span data-key="t-maps">Maps</span>
			</a>
			<div class="collapse menu-dropdown" id="sidebarMaps">
			  <ul class="nav nav-sm flex-column">
				<li class="nav-item">
				  <a routerLink="/map" class="nav-link" data-key="t-google">Full Map</a>
				</li>
				<li class="nav-item">
				  <a routerLink="/map-individu" class="nav-link" data-key="t-vector">Multi-Vessel Tracking</a>
				</li>
				<li class="nav-item">
					<a routerLink="/map-trip" class="nav-link" data-key="t-vector">Trip</a>
				  </li>
			  </ul>
			</div>
		  </li>
		  <li class="nav-item" *ngIf="nowUser.UserRole == 'Admin'">
			<a routerLink="/users" class="nav-link menu-link">
			  <i class="ph-user-circle-bold"></i>
			  <span data-key="t-authentication">Users</span>
			</a>
		  </li>
		  <li class="nav-item">
			<a routerLink="/fleet" class="nav-link menu-link">
			  <i class="ph-boat-bold"></i>
			  <span data-key="t-authentication">Vessels</span>
			</a>
		  </li>
		  <li class="nav-item">
			<a class="nav-link menu-link collapsed" href="#sidebarReports" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarMaps">
			  <i class="ph-notepad-bold"></i>
			  <span data-key="t-reports">Reports</span>
			</a>
			<div class="collapse menu-dropdown" id="sidebarReports">
			  <ul class="nav nav-sm flex-column">
				<li class="nav-item">
				  <a routerLink="/report/full-movement" class="nav-link" data-key="t-fm">Full Movement</a>
				</li>
				<li class="nav-item">
				  <a routerLink="/report/fuel-usage" class="nav-link" data-key="t-fu">Fuel Usage</a>
				</li>
				<li class="nav-item" *ngIf="nowUser.UserRole == 'Admin'">
				  <a routerLink="/report/sensor-total" class="nav-link" data-key="t-fst">Fuel Sensor Total</a>
				</li>
				<li class="nav-item" *ngIf="nowUser.UserRole == 'Admin'">
					<a routerLink="/report/sensor-density" class="nav-link" data-key="t-fst">Fuel Sensor Density</a>
				</li>
				<li class="nav-item">
					<a routerLink="/report/fuel-consumption" class="nav-link" data-key="t-fh">Fuel Consumption</a>
				</li>
				<div class="dropdown-divider"></div>
				<li class="nav-item">
				  <a routerLink="/report/engine-status" class="nav-link" data-key="t-es">Engines Status</a>
				</li>
				<li class="nav-item">
				  <a routerLink="/report/engine-hour" class="nav-link" data-key="t-eh">Engines Hour</a>
				</li>
				<li class="nav-item">
					<a routerLink="/report/bunkering" class="nav-link" data-key="t-eh">Bunkering</a>
				  </li>
			  </ul>
			</div>
		  </li>
		</ul>
	  </div>
	  <!-- Sidebar -->
	</div>
	<div class="sidebar-background"></div>
  </div>