import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { GeneralService } from '../../services/general.service';
import { StateService } from '../../services/state.service';
import { ReportService } from '../../services/report.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit, OnDestroy {

  vesselCall:any;
  datatbl:any;
  isSubmitting = false;

  piechart:any;
  linechart:any;

  rptCall:any;
  dataRender = true;
  initSize = 100;
  loaded = 0;
  totalSize = 0;
  allResults:any = [];
  isLoading = false;
  
  reportTypeOpts = [
	{
		id: "Full Movement",
		name: "Full Movement"
	},
	{
		id: "Fuel Usage",
		name: "Fuel Usage"
	},
	{
		id: "Fuel Sensor Total",
		name: "Fuel Sensor Total"
	},
	{
		id: "Fuel Consumption",
		name: "Fuel Consumption"
	},
	{
		id: "Fuel / Hour",
		name: "Fuel / Hour"
	},
	{
		id: "Engine Hour",
		name: "Engine Hour"
	},
	{
		id: "Engine Status",
		name: "Engine Status"
	}
  ];
  
  intervalOpts = [
	{
		id: 1,
		value: 1
	},{
		id: 15,
		value: 15
	},
	{
		id: 30,
		value: 30
	},
	{
		id: 60,
		value: 60
	},
  ];
  
  vesselOpts:any = [];
  
  rptType = "Fuel Usage";
  vessel:any = "2";
  rptFrom = "2024-01-02 00:00:00";
  rptTo = "2024-01-06 23:59:59";
  fromPicker:any = {};
  toPicker:any = {};
  intervalMin = 60;
  excludeZero = false;
  intervalRanges:any = {
	1 : 1,
	15: 21,
	45: 30,
	60: 30
  };
  
  results:any = [];
  resultUsage:any = {};
  resultPercent:any = {};
  resultUsageRaw:any = {};
  
  constructor(private generalSvc: GeneralService, private apiSvc: ApiService, private stateSvc: StateService, private rptSvc: ReportService) { }

  ngOnInit(): void {
	this.callVessels();
	// $('#from').val(this.generalSvc.getTodayStart());
	// this.rptFrom = $('#from').val();

	// $('#to').val(this.generalSvc.getToday());
	// this.rptTo = $('#to').val();
	this.rptFrom = this.generalSvc.getTodayStart();
	this.rptTo = this.generalSvc.getToday();

	if(this.stateSvc.getReportStartDate() != ""){
		// $('#from').val(this.stateSvc.getReportStartDate());
		// this.rptFrom = $('#from').val();
		this.rptFrom = this.generalSvc.convertLastDate(this.stateSvc.getReportStartDate());
	}
	if(this.stateSvc.getReportEndDate() != ""){
		// $('#to').val(this.stateSvc.getReportEndDate());
    	// this.rptTo = $('#to').val();
		this.rptTo = this.generalSvc.convertLastDate(this.stateSvc.getReportEndDate());
	}

	this.fromPicker = $('#from').flatpickr({
		enableTime: true,
		enableSeconds: true,
		dateFormat: "d/m/Y H:i:s",
		defaultDate: new Date(this.rptFrom),
		onChange: (selectedDates, dateStr, instance) => {
			this.rptFrom = dateStr;
			this.stateSvc.setReportStartDate(this.rptFrom);
			this.toPicker.set('minDate', this.rptFrom);
		}
	});
	this.rptFrom = $('#from').val();

	this.toPicker = $('#to').flatpickr({
		enableTime: true,
		enableSeconds: true,
		dateFormat: "d/m/Y H:i:s",
		defaultDate: new Date(this.rptTo),
		onChange: (selectedDates, dateStr, instance) => {
			this.rptTo = dateStr;
			this.stateSvc.setReportEndDate(this.rptTo);
			this.fromPicker.set('maxDate', this.rptTo);
		}
	});
	this.rptTo = $('#to').val();

	this.stateSvc.setReportStartDate(this.rptFrom);
	this.stateSvc.setReportEndDate(this.rptTo);
	// $('#to').attr('min', this.rptFrom);
	// $('#from').attr('max', this.rptTo);
	this.fromPicker.set('maxDate', this.rptTo);
	this.toPicker.set('minDate', this.rptFrom);
	$(window).scroll(() => {
		var scrollPos = $(document).scrollTop();
    	let lastPos = $(document).height() - ($(window).height() + scrollPos);
		if(lastPos < 80 && this.allResults.length > 0 && this.dataRender && !this.isLoading){
			this.runLoading();
		}
	});
  }
  
  ngOnDestroy(){
	if(this.vesselCall){
		this.vesselCall.unsubscribe();
	}
	if(this.rptCall){
		this.rptCall.unsubscribe();
	}
	if(this.datatbl){
		$("#example1").DataTable().destroy();
	}
	if(this.piechart){
		this.piechart.destroy();
	}
	if(this.linechart){
		this.linechart.destroy();
	}
  }
  
  callVessels(){
	this.vesselCall = this.stateSvc.sessionVessels
	.subscribe((result:any) => {
		this.vesselOpts = result.vessels;
	});
  }
  
  generateReport(){
	let startDate = new Date(this.generalSvc.convertLastDate(this.rptFrom));
	let toDate = new Date(this.generalSvc.convertLastDate(this.rptTo));

	if(startDate > toDate){
		this.generalSvc.showToast('To date must be greater than From Date', "error");
		return;
	}
	if(!this.vessel){
		this.generalSvc.showToast('Please select a vessel', "error");
		return;
	}

	// Calculating the time difference of two dates
	let differenceInTime = toDate.getTime() - startDate.getTime();
	// Calculating the no. of days between two dates
	let differenceInDays = differenceInTime / (1000 * 3600 * 24);
	let maxRange = this.intervalRanges[this.intervalMin];
	if(differenceInDays > maxRange){
		this.generalSvc.showToast('Date range selection cannot be more than '+ maxRange +' days', "error");
		return;
	}

	this.isSubmitting = true;
	this.generalSvc.showLoading();
	let data:any = {
		type: this.rptType,
		vessel: this.vessel,
		from: this.generalSvc.convertLastDate(this.rptFrom),
		to: this.generalSvc.convertLastDate(this.rptTo),
		interval: this.intervalMin,
		excludeZero: this.excludeZero
	};
	if(this.datatbl){
		$("#example1").DataTable().destroy();
	}
	if(this.piechart){
		this.piechart.destroy();
	}
	if(this.linechart){
		this.linechart.destroy();
	}
	this.allResults = [];
	this.results = [];
	this.loaded = 0;
	this.totalSize = 0;
	this.dataRender = true;
	this.isLoading = false;
	this.rptCall = this.rptSvc.generateReport(data)
	.subscribe((res) => {
		$('.filter-card').addClass('collapsed-card');
		this.allResults = res?.result;
		this.resultUsage = res?.total;
		this.resultUsageRaw = res?.totalBeauty;
		this.resultPercent = res?.percent;
		this.totalSize = res?.result.length;
		if(res?.result?.length == 0){
			if(this.intervalMin != 1){
				this.generalSvc.showToast('No result found on the selected date time', "error");
			}else{
				this.generalSvc.showToast('No 1 Minute result found on the selected date time', "error");
			}
			this.isSubmitting = false;
			return;
		}else{
			// res.result.forEach((dt, ix) => {
			// 	if(ix < this.initSize){
			// 	  this.results.push(dt);
			// 	  this.loaded = this.initSize;
			// 	}else{
			// 	  return;
			// 	}
			// })
		}
		setTimeout(() => {
			this.datatbl = $("#example1").DataTable({
			  "responsive": true, "lengthChange": true, "autoWidth": false,
			  "buttons": ["excel", "print", "colvis"], 
			//   "pageLength": 100,
			  "paging": false,
			  "ordering": false,
			  "info": false
			}).buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
			this.generalSvc.hideLoading();
			this.isSubmitting = false;

			$('.dataTables_empty').hide();
			this.runLoading();
			
			// setTimeout(() => {
			// 	res.result.forEach((dt, ix) => {
			// 	  if(ix >= this.initSize){
			// 		this.results.push(dt);
			// 	  }
			// 	});
			// 	this.dataRender = false;
			// },500)

			let lineLabel:any = [];
			let lineDataset:any = [
				{
					label               : 'Engine 1',
					backgroundColor     : 'rgba(0, 123, 255,0.9)',
					fill				: false,
					borderColor         : 'rgba(0, 123, 255,0.8)',
					pointRadius          : false,
					pointColor          : '#007bff',
					pointStrokeColor    : 'rgba(0, 123, 255,1)',
					pointHighlightFill  : '#fff',
					pointHighlightStroke: 'rgba(0, 123, 255,1)',
					data                : []
				},
				{
					label               : 'Engine 2',
					backgroundColor     : 'rgba(253, 126, 20,0.9)',
					fill				: false,
					borderColor         : 'rgba(253, 126, 20,0.8)',
					pointRadius          : false,
					pointColor          : '#fd7e14',
					pointStrokeColor    : 'rgba(253, 126, 20,1)',
					pointHighlightFill  : '#fff',
					pointHighlightStroke: 'rgba(253, 126, 20,1)',
					data                : []
				},
				{
					label               : 'Engine 3',
					backgroundColor     : 'rgba(32, 201, 151,0.9)',
					fill				: false,
					borderColor         : 'rgba(32, 201, 151,0.8)',
					pointRadius          : false,
					pointColor          : '#20c997',
					pointStrokeColor    : 'rgba(32, 201, 151,1)',
					pointHighlightFill  : '#fff',
					pointHighlightStroke: 'rgba(32, 201, 151,1)',
					data                : []
				},{
					label               : 'Auxiliary',
					backgroundColor     : 'rgba(102,16,242,0.9)',
					fill				: false,
					borderColor         : 'rgba(102,16,242,0.8)',
					pointRadius          : false,
					pointColor          : '#6610F2',
					pointStrokeColor    : 'rgba(102,16,242,1)',
					pointHighlightFill  : '#fff',
					pointHighlightStroke: 'rgba(102,16,242,1)',
					data                : []
				}
				,{
					label               : 'TOTAL',
					backgroundColor     : 'rgba(189, 78, 245,0.9)',
					fill				: false,
					borderColor         : 'rgba(189, 78, 245,0.8)',
					pointRadius          : false,
					pointColor          : '#BDA3F5',
					pointStrokeColor    : 'rgba(189, 78, 245,1)',
					pointHighlightFill  : '#fff',
					pointHighlightStroke: 'rgba(189, 78, 245,1)',
					data                : []
				},
				{
					label               : 'Speed',
					backgroundColor     : 'rgba(52, 235, 222,0.9)',
					fill				: false,
					borderColor         : 'rgba(52, 235, 222,0.8)',
					pointRadius          : false,
					pointColor          : '#34ebde',
					pointStrokeColor    : 'rgba(52, 235, 222,1)',
					pointHighlightFill  : '#fff',
					pointHighlightStroke: 'rgba(52, 235, 222,1)',
					data                : []
				}
			];
			this.allResults?.forEach((res) => {
				lineLabel.push(res.datetimestamp);
				lineDataset[0].data.push(res.eng1);
				lineDataset[1].data.push(res.eng2);
				lineDataset[2].data.push(res.eng3);
				lineDataset[3].data.push(res.gen2);
				lineDataset[4].data.push(res.total_cons_beauty);
				lineDataset[5].data.push(res.Speed);
			});
			
			var options = {
				chart: {
				  type: 'line',
				  height: 340
				},
				colors: ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#BDA3F5", "#03ecfc"],
				series: [{
				  name: 'Eng1',
				  data: lineDataset[0].data
				},{
				  name: 'Eng2',
				  data: lineDataset[1].data
				},{
				  name: 'Eng3',
				  data: lineDataset[2].data
				},{
				  name: 'Auxiliary',
				  data: lineDataset[3].data
				},{
				  name: 'TOTAL',
				  data: lineDataset[4].data
				},{
					name: 'Speed',
					data: lineDataset[5].data
				}],
				xaxis: {
				  categories: lineLabel,
				  labels: {
					datetimeFormatter: {
					  year: 'yyyy',
					  month: 'MMM \'yy',
					  day: 'dd MMM',
					  hour: 'HH:mm'
					}
				  },
				  tickAmount: 12
				},
				yaxis: [{
					seriesName: "TOTAL",
					show: false
				}, 
				{
					seriesName: "TOTAL",
					show: false
				},
				{
					seriesName: "TOTAL",
					show: false
				},
				{
					seriesName: "TOTAL",
					show: false
				},
				{
					seriesName: "TOTAL",
					title: {
						text: '(Liter)'  // Set the y-axis title with units
					}
				},
				{
					opposite: true,
					seriesName: "Speed",
					title: {
					  text: '(knot)'
					}
				}],
				stroke: {
					curve: 'smooth'
				},
				markers: {
					size: 5
				}
			  }
			  this.linechart = new ApexCharts(document.querySelector("#lineChart"), options);
			  this.linechart.render();
			  this.linechart.hideSeries('Eng1');
			  this.linechart.hideSeries('Eng2');
			  this.linechart.hideSeries('Eng3');
			  this.linechart.hideSeries('Auxiliary');

			  var pieOptions = {
				chart: {
				  type: 'pie',
				  height: 250,
				  toolbar: {
					show: true,
					tools: {
						download: true
					}
				  },
				  export: {
					png: {
						filename: "Engine Breakdown"
					}
				  }
				},
				colors: ["#008FFB", "#00E396", "#FEB019", "#FF4560"],
				series: [this.resultUsage?.eng1,this.resultUsage?.eng2,this.resultUsage?.eng3,this.resultUsage?.gen2],
				labels: [
					'Engine 1',
					'Engine 2',
					'Engine 3',
					'Auxiliary'
				]
			  }
			  this.piechart = new ApexCharts(document.querySelector("#pieChart"), pieOptions);
			  this.piechart.render();
			
		},1000);
		// document?.getElementById("card_result")?.scrollIntoView();
	}, (err) => {
		this.isSubmitting = false;
		this.generalSvc.handleErr(err);
		this.generalSvc.hideLoading();
	});
  }

  resaveStartDate(){
	this.stateSvc.setReportStartDate(this.rptFrom);
	$('#to').attr('min', this.rptFrom);
  }

  resaveEndDate(){
	this.stateSvc.setReportEndDate(this.rptTo);
	$('#from').attr('max', this.rptTo);
  }

  runLoading(){
	this.isLoading = true;
	setTimeout(() => {
		console.log('all', this.allResults.length);
		console.log('loaded', this.loaded);
		if(this.allResults.length >= this.loaded){
			let endIx = this.loaded + this.initSize;
			this.allResults.forEach((dt, ix) => {
				if(ix >= this.loaded && ix < endIx){
					this.results.push(dt);
					this.isLoading = false;
				}else{
					return;
				}
			})	
			this.loaded = endIx;
		}else{
			this.dataRender = false;
		}
	}, 500);
  }

}
