import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { StateService } from './state.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map,catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VesselService {

  constructor(private apiservice: ApiService, private http: HttpClient, private stateSvc: StateService) { }
  
  getVessels(): Observable <any>   {
		let t = this.apiservice.getEndpointUrl() + "vessels";
		let e = this.apiservice.getHttpHeaderWithCredential();
		return this.http.get(t, {
			headers: e
		})
		.pipe(
			map((results:any) => {
				return results;
			}),
			catchError((error:any) => {
				return throwError(error);
			})
		);
	}
	
	getVessel(uid): Observable <any>   {
		let t = this.apiservice.getEndpointUrl() + "vessel/" + uid;
		let e = this.apiservice.getHttpHeaderWithCredential();
		return this.http.get(t, {
			headers: e
		})
		.pipe(
			map((results:any) => {
				return results;
			}),
			catchError((error:any) => {
				return throwError(error);
			})
		);
	}
	
	updateVessel(data,vesselId): Observable <any>   {
		let e = this.apiservice.getEndpointUrl();
		let n = this.apiservice.getHttpHeaderWithCredential();
		let r = e + "vessel/"+vesselId;
		let i = data;
		return this.http.put(r, i, {
			headers: n
		})
		.pipe(
			map((results:any) => {
				return results;
			}),
			catchError((error:any) => {
				return throwError(error);
			})
		);
	}

	getDashboard(vessel): Observable <any>   {
		let t = this.apiservice.getEndpointUrl() + "movements?vessel=" + vessel + "&from=2024-07-27&to=2024-07-27";
		let e = this.apiservice.getHttpHeaderWithCredential();
		return this.http.get(t, {
			headers: e
		})
		.pipe(
			map((results:any) => {
				return results;
			}),
			catchError((error:any) => {
				return throwError(error);
			})
		);
	}

	getCurrentWeather(lat, lng): Observable <any>   {
		let t = "https://api.open-meteo.com/v1/forecast?latitude="+lat+"&longitude="+lng+"&current=temperature_2m,rain,weather_code,wind_speed_10m,wind_direction_10m";
		return this.http.get(t)
		.pipe(
			map((results:any) => {
				return results;
			}),
			catchError((error:any) => {
				return throwError(error);
			})
		);
	}
	getCurrentMarine(lat, lng): Observable <any>   {
		let t = "https://marine-api.open-meteo.com/v1/marine?latitude="+lat+"&longitude="+lng+"&current=wave_height,wave_direction,wind_wave_height,wind_wave_direction,swell_wave_height,swell_wave_direction,ocean_current_velocity,ocean_current_direction&timezone=Asia%2FSingapore&forecast_days=1";
		return this.http.get(t)
		.pipe(
			map((results:any) => {
				return results;
			}),
			catchError((error:any) => {
				return throwError(error);
			})
		);
	}

	convertWeatherCode(weatherCode){
		let dataArr:any = {
			0: "Clear Sky",
			1: "Mainly clear, partly cloudy",
			2: "Mainly clear, partly cloudy",
			3: "Mainly clear, partly cloudy",
			45: "Fog and depositing rime fog",
			48: "Fog and depositing rime fog",
			51: "Drizzle",
			53: "Drizzle",
			55: "Drizzle",
			56: "Freezing Drizzle",
			57: "Freezing Drizzle",
			61: "Rain", 
			63: "Rain",
			65: "Rain",
			66: "Freezing Rain",
			67: "Freezing Rain",
			71: "Snow fall",
			73: "Snow fall",
			75: "Snow fall",
			77: "Snow grains",
			80: "Rain showers",
			81: "Rain showers",
			82: "Rain showers",
			85: "Snow showers slight and heavy",
			86: "Snow showers slight and heavy",
			95: "Thunderstorm: Slight or moderate",
			96: "Thunderstorm with slight and heavy hail",
			99: "Thunderstorm with slight and heavy hail"
		};

		return dataArr[weatherCode];
	}

	weatherBg(weatherCode){
		let dataArr:any = {
			0: "sun",
			1: "sun",
			2: "sun",
			3: "sun",
			45: "cloud",
			48: "cloud",
			51: "cloud-drizzle",
			53: "cloud-drizzle",
			55: "cloud-drizzle",
			56: "cloud-drizzle",
			57: "cloud-drizzle",
			61: "cloud-rain", 
			63: "cloud-rain",
			65: "cloud-rain",
			66: "cloud-rain",
			67: "cloud-rain",
			71: "Snow fall",
			73: "Snow fall",
			75: "Snow fall",
			77: "Snow grains",
			80: "cloud-rain",
			81: "cloud-rain",
			82: "cloud-rain",
			85: "cloud-snow",
			86: "cloud-snow",
			95: "cloud-lightning",
			96: "cloud-lightning",
			99: "cloud-lightning"
		};

		return dataArr[weatherCode];
	}
}
