import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MapComponent } from './components/map/map.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FleetComponent } from './components/fleet/fleet.component';
import { ReportsComponent } from './components/reports/reports.component';
import { LoginComponent } from './components/auth/login/login.component';
import { ProfileComponent } from './components/auth/profile/profile.component';
import { UsersComponent } from './components/users/users.component';
import { UserViewComponent } from './components/users/user-view/user-view.component';
import { FleetViewComponent } from './components/fleet/fleet-view/fleet-view.component';
import { FleetEditComponent } from './components/fleet/fleet-edit/fleet-edit.component';
import { IndividuComponent } from './components/map/individu/individu.component';
import { FullMovementComponent } from './components/report/full-movement/full-movement.component';
import { SensorTotalComponent } from './components/report/sensor-total/sensor-total.component';
import { FuelHourComponent } from './components/report/fuel-hour/fuel-hour.component';
import { EngineStatusComponent } from './components/report/engine-status/engine-status.component';
import { EngineHourComponent } from './components/report/engine-hour/engine-hour.component';
import { FuelConsumptionComponent } from './components/report/fuel-consumption/fuel-consumption.component';
import { SensorDensityComponent } from './components/report/sensor-density/sensor-density.component';
import { BunkerComponent } from './components/report/bunker/bunker.component';
import { TripComponent } from './components/map/trip/trip.component';
import { AnomaliesComponent } from './components/report/anomalies/anomalies.component';

const routes: Routes = [
	{path: 'map', component: MapComponent},
	{path: 'dashboard', component: DashboardComponent},
	{path: 'fleet', component: FleetComponent},
	{path: 'report/fuel-usage', component: ReportsComponent},
	{path: '', component: LoginComponent},
	{path: 'profile', component: ProfileComponent},
	{path: 'users', component: UsersComponent},
	{path: 'user-view/:id', component: UserViewComponent},
	{path: 'vessel-view/:id', component: FleetViewComponent},
	{path: 'vessel-edit/:id', component: FleetEditComponent},
	{path: 'map-individu', component: IndividuComponent},
	{path: 'report/full-movement', component: FullMovementComponent},
	{path: 'report/sensor-total', component: SensorTotalComponent},
	{path: 'report/fuel-hour', component: FuelHourComponent},
	{path: 'report/engine-status', component: EngineStatusComponent},
	{path: 'report/engine-hour', component: EngineHourComponent},
	{path: 'report/sensor-density', component: SensorDensityComponent},
	{path: 'report/fuel-consumption', component: FuelConsumptionComponent},
	{path: 'report/bunkering', component: BunkerComponent},
	{path: 'map-trip', component: TripComponent},
	{path: 'report/abnormality', component: AnomaliesComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
