import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { GeneralService } from '../../../services/general.service';
import { StateService } from '../../../services/state.service';
import { ReportService } from '../../../services/report.service';

@Component({
  selector: 'app-fuel-hour',
  templateUrl: './fuel-hour.component.html',
  styleUrls: ['./fuel-hour.component.css']
})
export class FuelHourComponent implements OnInit, OnDestroy {

  vesselCall:any;
  datatbl:any;
  isSubmitting = false;
  
  reportTypeOpts = [
    {
      id: "Full Movement",
      name: "Full Movement"
    },
    {
      id: "Fuel Usage",
      name: "Fuel Usage"
    },
    {
      id: "Fuel Sensor Total",
      name: "Fuel Sensor Total"
    },
    {
      id: "Fuel Consumption",
      name: "Fuel Consumption"
    },
    {
      id: "Fuel / Hour",
      name: "Fuel / Hour"
    },
    {
      id: "Engine Hour",
      name: "Engine Hour"
    },
    {
      id: "Engine Status",
      name: "Engine Status"
    }
  ];
  
  intervalOpts = [
    {
      id: 1,
      value: 1
    },
    {
      id: 5,
      value: 5
    },
    {
      id: 10,
      value: 10
    },
    {
      id: 15,
      value: 15
    },
    {
      id: 30,
      value: 30
    },
    {
      id: 45,
      value: 45
    },
    {
      id: 60,
      value: 60
    },
  ];
  
  vesselOpts:any = [];
  
  rptType = "Full Movement";
  vessel = "";
  rptFrom = "2024-01-02 00:00:00";
  rptTo = "2024-01-06 23:59:59";
  intervalMin = 1;
  excludeZero = false;
  
  results:any = [];
  resultUsage:any = {};
  resultPercent:any = {};
  resultUsageRaw:any = {};
  
  constructor(private generalSvc: GeneralService, private apiSvc: ApiService, private stateSvc: StateService, private rptSvc: ReportService) { }

  ngOnInit(): void {
	  this.callVessels();
    this.rptFrom = this.generalSvc.getTodayStart();
    this.rptTo = this.generalSvc.getToday();

    if(this.stateSvc.getReportStartDate() != ""){
      this.rptFrom = this.stateSvc.getReportStartDate();
    }
    if(this.stateSvc.getReportEndDate() != ""){
        this.rptTo = this.stateSvc.getReportEndDate();
    }

    this.stateSvc.setReportStartDate(this.rptFrom);
    this.stateSvc.setReportEndDate(this.rptTo);
  }
  
  ngOnDestroy(){
    if(this.vesselCall){
      this.vesselCall.unsubscribe();
    }
  }
  
  callVessels(){
    this.vesselCall = this.stateSvc.sessionVessels
    .subscribe((result:any) => {
      this.vesselOpts = result.vessels;
    });
  }
  
  generateReport(){
    this.isSubmitting = true;
    this.generalSvc.showLoading();
    let data:any = {
      type: this.rptType,
      vessel: this.vessel,
      from: this.rptFrom,
      to: this.rptTo,
      interval: this.intervalMin,
      excludeZero: this.excludeZero
    };
    if(this.datatbl){
      $("#example1").DataTable().destroy();
    }
    this.rptSvc.generateReport(data)
    .subscribe((res) => {
      $('.filter-card').addClass('collapsed-card');
      this.results = res?.result;
      this.resultUsage = res?.total;
      this.resultUsageRaw = res?.totalRaw;
      this.resultPercent = res?.percent;
      setTimeout(() => {
        this.datatbl = $("#example1").DataTable({
          "responsive": true, "lengthChange": false, "autoWidth": false,
          "buttons": ["excel", "print", "colvis"], "pageLength": 25,
          "ordering": false
        }).buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
        this.generalSvc.hideLoading();
        this.isSubmitting = false;
        
        let arrVess = ['total', 'eng1', 'eng2', 'eng3', 'gen1', 'gen2'];
        let arrcols = ['#39CCCC', '#007bff', '#fd7e14', '#20c997', '#17a2b8', '#6610f2'];
        let totalVess = 0;
        arrVess.forEach((en, ix) => {
          if(this.resultPercent && this.resultPercent[en]){
            $('.knob#fuel-usage-'+en).val(this.resultPercent[en]).trigger('change');
          }
        });
        
        let lineLabel:any = [];
        let lineDataset:any = [
          {
            label               : 'Engine 1',
            backgroundColor     : 'rgba(0, 123, 255,0.9)',
            fill				: false,
            borderColor         : 'rgba(0, 123, 255,0.8)',
            pointRadius          : false,
            pointColor          : '#007bff',
            pointStrokeColor    : 'rgba(0, 123, 255,1)',
            pointHighlightFill  : '#fff',
            pointHighlightStroke: 'rgba(0, 123, 255,1)',
            data                : []
          },
          {
            label               : 'Engine 2',
            backgroundColor     : 'rgba(253, 126, 20,0.9)',
            fill				: false,
            borderColor         : 'rgba(253, 126, 20,0.8)',
            pointRadius          : false,
            pointColor          : '#fd7e14',
            pointStrokeColor    : 'rgba(253, 126, 20,1)',
            pointHighlightFill  : '#fff',
            pointHighlightStroke: 'rgba(253, 126, 20,1)',
            data                : []
          },
          {
            label               : 'Engine 3',
            backgroundColor     : 'rgba(32, 201, 151,0.9)',
            fill				: false,
            borderColor         : 'rgba(32, 201, 151,0.8)',
            pointRadius          : false,
            pointColor          : '#20c997',
            pointStrokeColor    : 'rgba(32, 201, 151,1)',
            pointHighlightFill  : '#fff',
            pointHighlightStroke: 'rgba(32, 201, 151,1)',
            data                : []
          },
          {
            label               : 'Gen 1',
            backgroundColor     : 'rgba(23, 162, 184,0.9)',
            fill				: false,
            borderColor         : 'rgba(23, 162, 184,0.8)',
            pointRadius          : false,
            pointColor          : '#17a2b8',
            pointStrokeColor    : 'rgba(23, 162, 184,1)',
            pointHighlightFill  : '#fff',
            pointHighlightStroke: 'rgba(23, 162, 184,1)',
            data                : []
          },
          {
            label               : 'Gen 2',
            backgroundColor     : 'rgba(102,16,242,0.9)',
            fill				: false,
            borderColor         : 'rgba(102,16,242,0.8)',
            pointRadius          : false,
            pointColor          : '#6610f2',
            pointStrokeColor    : 'rgba(102,16,242,1)',
            pointHighlightFill  : '#fff',
            pointHighlightStroke: 'rgba(102,16,242,1)',
            data                : []
          }
        ];
        this.results?.forEach((res) => {
          lineLabel.push(res.Updated);
          lineDataset[0].data.push(res.eng1);
          lineDataset[1].data.push(res.eng2);
          lineDataset[2].data.push(res.eng3);
          lineDataset[3].data.push(res.gen1);
          lineDataset[4].data.push(res.gen2);
        });
        
        let areaChartData = {
          labels  : lineLabel,
          datasets: lineDataset
        }

        let areaChartOptions = {
          maintainAspectRatio : false,
          datasetFill: false,
          responsive : true,
          legend: {
          display: false
          },
          scales: {
          xAxes: [{
            gridLines : {
            display : false,
            }
          }],
          yAxes: [{
            gridLines : {
            display : false,
            }
          }]
          }
        }
        // var lineChartCanvas = $('#lineChart').get(0).getContext('2d');
        var lineChartOptions = $.extend(true, {}, areaChartOptions);
        var lineChartData = $.extend(true, {}, areaChartData);

        // var lineChart = new Chart(lineChartCanvas, {
        //   type: 'line',
        //   data: lineChartData,
        //   options: lineChartOptions
        // });
        
        var donutData        = {
          labels: [
            'Engine 1',
            'Engine 2',
            'Engine 3',
            'Gen 1',
            'Gen 2'
          ],
          datasets: [
          {
            data: [this.resultUsageRaw?.eng1,this.resultUsageRaw?.eng2,this.resultUsageRaw?.eng3,this.resultUsageRaw?.gen1,this.resultUsageRaw?.gen2],
            backgroundColor : ['#007bff', '#fd7e14', '#20c997', '#17a2b8', '#6610f2'],
          }
          ]
        };
        var donutOptions     = {
          maintainAspectRatio : false,
          responsive : true,
        };
        
        var pieChartCanvas = $('#pieChart').get(0).getContext('2d')
        var pieData        = donutData;
        var pieOptions     = {
          maintainAspectRatio : false,
          responsive : true,
        };
        //Create pie or douhnut chart
        // You can switch between pie and douhnut using the method below.
        new Chart(pieChartCanvas, {
          type: 'pie',
          data: pieData,
          options: pieOptions
        });
        
      },1000);
      document?.getElementById("card_result")?.scrollIntoView();
    }, (err) => {
      this.isSubmitting = false;
      this.generalSvc.handleErr(err);
      this.generalSvc.hideLoading();
    });
  }

  resaveStartDate(){
    this.stateSvc.setReportStartDate(this.rptFrom);
  }

  resaveEndDate(){
    this.stateSvc.setReportEndDate(this.rptTo);
  }

}
