import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { GeneralService } from '../../services/general.service';
import { StateService } from '../../services/state.service';
import { VesselService } from '../../services/vessel.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map,catchError } from 'rxjs/operators';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, OnDestroy {

  map:any;
  markers:any = [];
  pois:any = [];
  selectVessel:any = {};
  
  vesselsCall:any;
  vessels:any = [];
  points:any = [];
  displayVessels:any = [];
  displayPois:any = [];
  
  showVes = true;
  showPoi = false;
  
  waveHeight:any = "";
  waveDirection:any = "";
  swellHeight:any = "";
  swellDirection:any = "";
  oceanCurrentHeight:any = "";
  oceanCurrentDirection:any = "";
  windSpeed:any = "";
  windDirection:any = "";
  temperature:any = "";
  weather:any = "";

  searchVessel = "";
  searchPoi = "";

  mapStyle = "standard";
  
  constructor(private generalSvc: GeneralService, private apiSvc: ApiService, private stateSvc: StateService, private vesselSvc: VesselService, private http: HttpClient) { }

  ngOnInit(): void {
	let attrHtml = $('html').attr('data-theme');
	setTimeout(() => {
		if(attrHtml == 'default'){
			$('.map-overlay').css('background-color', "#000");
		}else {
			$('.map-overlay').css('background-color', "#fff");
		}
	}, 300);
	
    mapboxgl.accessToken = this.apiSvc.getMapBoxKey();
    this.map = new mapboxgl.Map({
        container: 'map', // container ID
        center: [103.129, 5.331], // starting position [lng, lat]
        zoom: 10, // starting zoom,
    });
	
	setTimeout(() => {
		this.callVessels();
	}, 1200);
  }
  
  ngOnDestroy(){
	if(this.vesselsCall){
		this.vesselsCall.unsubscribe();
	}
	if(this.map){
		this.map.remove();
		this.map = null;
	}
  }
  
  callVessels(){
	this.vesselsCall = this.stateSvc.sessionVessels
	.subscribe((result:any) => {
		if(this.markers?.length > 0){
			this.markers.forEach((mkr) => {
				mkr.remove();
			});
			this.markers = [];
		}
		this.vessels = result.vessels;
		this.displayVessels = result.vessels;
		this.points = result.points;
		this.displayPois = result.points;
		if(this.vessels?.length > 0){
			const bounds = new mapboxgl.LngLatBounds(
				[this.vessels[0].Lon, this.vessels[0].Lat],
				[this.vessels[0].Lon, this.vessels[0].Lat]
			);
			this.vessels.forEach((ves) => {
				ves.shown = 1;

				// let marker1 = new mapboxgl.Marker()
				// .setLngLat([ves.Lon, ves.Lat])
				// .addTo(this.map);

				let vesselImg = "ship-off.png";
				if(ves.is_engine_on){
					if(ves.VesselState == "Stopped"){
						vesselImg = "ship-stopped.png";
					}else{
						vesselImg = "ship-moving.png";
					}
				}
				
				// let elMkr = marker1.getElement();
				let elMkr = document.createElement('div');
				let tmpThis = this;
				elMkr.className = "marker";
				elMkr.style.backgroundImage = 'url('+this.apiSvc.getBaseFe()+'/assets/images/'+vesselImg+')';
				elMkr.style.width = '42px';
				elMkr.style.height = '42px';
				elMkr.style.backgroundSize = '100%';
				elMkr.style.borderRadius = "50%";
				elMkr.style.boxShadow = "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)";
				elMkr.addEventListener('click', function() {
					tmpThis.selectVessel = ves;
					tmpThis.getCurrentWeatherPoint(ves.Lat, ves.Lon);
					$('#offcanvasVesInfo').addClass('show');
				});

				let marker1 = new mapboxgl.Marker(elMkr)
				.setLngLat([ves.Lon, ves.Lat])
				.addTo(this.map);
				
				ves.marker = marker1;
				
				this.markers.push(marker1);

				// if(ves.UnitID == 2){
					// bounds.extend([ves.Lon, ves.Lat]);
				// }
			});
			// this.map.fitBounds(bounds, {
			// 	padding: 70
			// });
		}
		if(this.points?.length > 0){
			this.points.forEach((poi) => {
				poi.shown = 1;
				let popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
				  poi.Name
				);

				let colorDef = "#ADD8E6";
				let iconUrl = this.apiSvc.getBaseFe() + "/assets/images/POI_Platform.png";
				if(poi.Type == "Port"){
					colorDef = "#FF7F50";
					iconUrl = this.apiSvc.getBaseFe() + "/assets/images/POI_Port.png";
				}

				let elMkr = document.createElement('div');
				elMkr.style.backgroundImage = 'url('+iconUrl+')';
				elMkr.style.width = '32px';
				elMkr.style.height = '32px';
				elMkr.style.backgroundSize = '100%';
				
				let marker1 = new mapboxgl.Marker(elMkr)
				.setLngLat([poi.Lng, poi.Lat])
				.setPopup(popup)
				.addTo(this.map);
				
				poi.marker = marker1;
				
				this.pois.push(marker1);
			});
		}
	});
  }
  
  toggleVessel(ves, typeMar){
	if(!ves.shown){
		(ves.marker).remove();
		ves.marker = null;
	}else{
		let popup = null;
		
		let marker1:any = null;
		
		if(typeMar == "poi"){
			 popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
				ves.Name
			  );

			  let colorDef = "#ADD8E6";
			  let iconUrl = this.apiSvc.getBaseFe() + "/assets/images/POI_Platform.png";
			  if(ves.Type == "Port"){
				  colorDef = "#FF7F50";
				  iconUrl = this.apiSvc.getBaseFe() + "/assets/images/POI_Port.png";
			  }

			  let elMkr = document.createElement('div');
			  elMkr.style.backgroundImage = 'url('+iconUrl+')';
			  elMkr.style.width = '32px';
			  elMkr.style.height = '32px';
			  elMkr.style.backgroundSize = '100%';
			  
			  let marker1 = new mapboxgl.Marker(elMkr)
			  .setLngLat([ves.Lng, ves.Lat])
			  .setPopup(popup)
			  .addTo(this.map);
			  
			  ves.marker = marker1;
			  
			  this.pois.push(marker1);
		}else{
			let elMkr = document.createElement('div');
			let tmpThis = this;

			let vesselImg = "ship-off.png";
			if(ves.is_engine_on){
				if(ves.VesselState == "Stopped"){
					vesselImg = "ship-stopped.png";
				}else{
					vesselImg = "ship-moving.png";
				}
			}

			elMkr.className = "marker";
			elMkr.style.backgroundImage = 'url('+this.apiSvc.getBaseFe() + '/assets/images/'+vesselImg+')';
			elMkr.style.width = '42px';
			elMkr.style.height = '42px';
			elMkr.style.backgroundSize = '100%';
			elMkr.style.borderRadius = "50%";
			elMkr.style.boxShadow = "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)";
			elMkr.addEventListener('click', function() {
				tmpThis.selectVessel = ves;
				tmpThis.getCurrentWeatherPoint(ves.Lat, ves.Lon);
				$('#offcanvasVesInfo').addClass('show');
			});

			let marker1 = new mapboxgl.Marker(elMkr)
			.setLngLat([ves.Lon, ves.Lat])
			.addTo(this.map);
			
			ves.marker = marker1;
			
			this.markers.push(marker1);

			this.map.flyTo({
			center: [ves.Lon, ves.Lat]
			});
		}
	}
  }
  
  togglePoi(){
	this.showVes = false;
	this.showPoi = !this.showPoi;
  }
  
  toggleVes(){
	this.showPoi = false;
	this.showVes = !this.showVes;
  }
  
  dismisOffcanvas(){
	$('#offcanvasVesInfo').removeClass('show');
  }
  
  getCurrentWeatherPoint(lat, lng){
	this.vesselSvc.getCurrentWeather(lat, lng)
	.subscribe((res) => {
		this.windSpeed = res?.current?.wind_speed_10m + res?.current_units.wind_speed_10m;
		this.windDirection = res?.current?.wind_direction_10m + res?.current_units.wind_direction_10m;
		this.temperature = res?.current?.temperature_2m + res?.current_units.temperature_2m;
		this.weather = this.vesselSvc.convertWeatherCode(res?.current?.weather_code);
	});
	this.vesselSvc.getCurrentMarine(lat, lng)
	.subscribe((res) => {
		this.waveHeight = res?.current?.wave_height + res?.current_units.wave_height;
		this.waveDirection = res?.current?.wave_direction + res?.current_units.wave_direction;
		this.swellHeight = res?.current?.swell_wave_height + res?.current_units.swell_wave_height;
		this.swellDirection = res?.current?.swell_wave_direction + res?.current_units.swell_wave_direction;
		this.oceanCurrentHeight = res?.current?.ocean_current_velocity + res?.current_units.ocean_current_velocity;
		this.oceanCurrentDirection = res?.current?.ocean_current_direction + res?.current_units.ocean_current_direction;
	});
  }
  
	toggleMap(type){
		this.map.setStyle('mapbox://styles/mapbox/' + type);
		this.mapStyle = type;
	}

	filterVessel(){
		this.displayVessels = [];
		if(this.vessels && this.searchVessel){
			this.vessels.forEach((ves) => {
				if(((ves.Description).toLowerCase()).indexOf((this.searchVessel).toLowerCase()) > -1){
					this.displayVessels.push(ves);
				}
			});
		}else if(this.vessels && !this.searchVessel){
			this.vessels.forEach((ves) => {
				this.displayVessels.push(ves);
			});
		}
	}

	filterPoi(){
		this.displayPois = [];
		if(this.displayPois && this.searchPoi){
			this.points.forEach((ves) => {
				if(((ves.Name).toLowerCase()).indexOf((this.searchPoi).toLowerCase()) > -1){
					this.displayPois.push(ves);
				}
			});
		}else if(this.displayPois && !this.searchPoi){
			this.points.forEach((ves) => {
				this.displayPois.push(ves);
			});
		}
	}

}
