<!-- start page title -->
<div class="row">
	<div class="col-12">
		<div class="page-title-box d-sm-flex align-items-center justify-content-between">
			<h4 class="mb-sm-0">Vessel Info</h4>

		</div>
	</div>
</div>
<!-- end page title -->

<div class="row">
	<!--end col-->
	<div class="col-xxl-3">
		<div class="card overflow-hidden">
			<div>
				<img src="assets/images/vessel-bg.jpeg" alt="" class="card-img-top profile-wid-img object-fit-cover" style="height: 200px;">
				<div>
					<input id="profile-foreground-img-file-input" type="file" class="profile-foreground-img-file-input d-none">
					<label for="profile-foreground-img-file-input" class="profile-photo-edit btn btn-light btn-sm position-absolute end-0 top-0 m-3">
						<i class="ri-image-edit-line align-bottom me-1"></i> Change Image
					</label>
				</div>
			</div>
			<div class="card-body border-top">
				<div class="table-responsive table-card p-2">
					<table class="table align-middle table-borderless">
						<tbody>
							<tr>
								<th>Name</th>
								<td>{{ vessel.Description }}</td>
							</tr>
							<tr>
								<th>Account</th>
								<td>{{ vessel.Account }}</td>
							</tr>
							<tr>
								<th>Commissioned</th>
								<td>{{ vessel.Commissioned }}</td>
							</tr>
							<tr>
								<th>Tonnage</th>
								<td>{{ vessel.Tonnage }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	<div class="col-xxl-9">
		<div class="col-lg-12 mb-2">
			<div class="card">
				<div class="card-body">
					<div class="row g-4">
						<div class="col-xxl-3 col-md-6 border-end-md border-dashed">
							<div class="text-center">
								<p class="text-muted">IMO</p>
								<h5 class="mb-0">{{ vessel.IMO }}</h5>
							</div>
						</div><!--end col-->
						<div class="col-xxl-3 col-md-6 border-end-xxl border-dashed">
							<div class="text-center">
								<p class="text-muted">MMSI</p>
								<h5 class="mb-0">{{ vessel.MMSI }}</h5>
							</div>
						</div><!--end col-->
						<div class="col-xxl-3 col-md-6 border-end-md border-dashed">
							<div class="text-center">
								<p class="text-muted">Communication Mode</p>
								<h5 class="mb-0">{{ vessel.ComMode }}</h5>
							</div>
						</div><!--end col-->
						<div class="col-xxl-3 col-md-6">
							<div class="text-center">
								<p class="text-muted">GPS Status</p>
								<h5 class="mb-0">{{ vessel.GPS }}</h5>
							</div>
						</div><!--end col-->
					</div><!--end row-->
				</div>
			</div><!--end card-->
		</div>
		<div class="d-flex align-items-center flex-wrap gap-2 mb-2">
			<ul class="nav nav-pills arrow-navtabs nav-secondary gap-2 flex-grow-1 order-2 order-lg-1" role="tablist">
				<li class="nav-item" role="presentation">
					<a class="nav-link active" data-bs-toggle="tab" href="#personalDetails" role="tab" aria-selected="true">
						Profile
					</a>
				</li>
				<li class="nav-item" role="presentation" *ngIf="nowUser.UserRole == 'Admin'">
					<a class="nav-link" data-bs-toggle="tab" href="#profileSetting" role="tab" aria-selected="true">
						Settings
					</a>
				</li>
				<li class="nav-item" role="presentation" *ngIf="nowUser.UserRole == 'Admin'">
					<a class="nav-link" data-bs-toggle="tab" href="#profileLogs" role="tab" aria-selected="true">
						Data Changed Logs
					</a>
				</li>
			</ul>
		</div>
		<div class="card">
			<div class="tab-content">

				<div class="tab-pane active" id="personalDetails" role="tabpanel">
					<div class="card-header">
						<h6 class="card-title mb-0">Profile</h6>
					</div>
					<div class="card-body">
						<form id="quickVes">
							<div class="row">
								<div class="col-lg-12">
									<div class="mb-3">
										<label for="firstnameInput" class="form-label">Name</label>
										<input type="text" class="form-control" placeholder="Enter your firstname" [(ngModel)]="name" [ngModelOptions]="{standalone: true}" *ngIf="nowUser.UserRole == 'Admin'">
										<p class="form-control" *ngIf="nowUser.UserRole != 'Admin'">{{ name }}</p>
									</div>
								</div>
								<div class="col-lg-12" *ngFor="let benchmark of benchmarks">
									<div class="mb-3">
										<label for="type" class="form-label">{{ benchmark.label }} ({{benchmark.unit}})</label>
										<input class="form-control" name="{{ benchmark.type }}" id="{{ benchmark.type }}" [(ngModel)]="benchmark.value" [ngModelOptions]="{standalone: true}" *ngIf="nowUser.UserRole == 'Admin'">
										<p class="form-control" *ngIf="nowUser.UserRole != 'Admin'">{{ benchmark.value }}</p>
									</div>
								</div>
								<!--end col-->
								<div class="col-lg-12">
									<div class="hstack gap-2 justify-content-end">
										<button type="submit" class="btn btn-primary" (click)="saveVessel()" *ngIf="nowUser.UserRole == 'Admin'">Updates</button>
										<button type="button" class="btn btn-subtle-danger" routerLink="/fleet">Back</button>
									</div>
								</div>
								<!--end col-->
							</div>
							<!--end row-->
						</form>
					</div>
				</div>
				<div class="tab-pane" id="profileSetting" role="tabpanel" *ngIf="nowUser.UserRole == 'Admin'">
					<div class="card-header">
						<h6 class="card-title mb-0">Setting</h6>
					</div>
					<div class="card-body">
						<form id="quickVes">
							<div class="row">
								<div class="col-lg-12">
									<div class="mb-3">
										<label for="type" class="form-label">Vessel Reporting Interval (Minutes)</label>
										<input class="form-control" name="data_interval" id="data_interval" [(ngModel)]="reportFrequency" [ngModelOptions]="{standalone: true}" *ngIf="nowUser.UserRole == 'Admin'">
										<p class="form-control" *ngIf="nowUser.UserRole != 'Admin'">{{ reportFrequency }}</p>
									</div>
									<div class="mb-3">
										<label for="type" class="form-label">Minimum Consumption Percent (Main Engine)</label>
										<input class="form-control" name="data_interval" id="data_interval" [(ngModel)]="minUsageMe" [ngModelOptions]="{standalone: true}" *ngIf="nowUser.UserRole == 'Admin'">
										<p class="form-control" *ngIf="nowUser.UserRole != 'Admin'">{{ minUsageMe }}</p>
									</div>
									<div class="mb-3">
										<label for="type" class="form-label">Maximum Consumption Percent (Main Engine)</label>
										<input class="form-control" name="data_interval" id="data_interval" [(ngModel)]="maxUsageMe" [ngModelOptions]="{standalone: true}" *ngIf="nowUser.UserRole == 'Admin'">
										<p class="form-control" *ngIf="nowUser.UserRole != 'Admin'">{{ maxUsageMe }}</p>
									</div>
									<div class="mb-3">
										<label for="type" class="form-label">Minimum Consumption Percent (Auxiliary)</label>
										<input class="form-control" name="data_interval" id="data_interval" [(ngModel)]="minUsageAux" [ngModelOptions]="{standalone: true}" *ngIf="nowUser.UserRole == 'Admin'">
										<p class="form-control" *ngIf="nowUser.UserRole != 'Admin'">{{ minUsageAux }}</p>
									</div>
									<div class="mb-3">
										<label for="type" class="form-label">Maximum Consumption Percent (Auxiliary)</label>
										<input class="form-control" name="data_interval" id="data_interval" [(ngModel)]="maxUsageAux" [ngModelOptions]="{standalone: true}" *ngIf="nowUser.UserRole == 'Admin'">
										<p class="form-control" *ngIf="nowUser.UserRole != 'Admin'">{{ maxUsageAux }}</p>
									</div>
									<div class="mb-3">
										<label for="type" class="form-label">Minimum Density</label>
										<input class="form-control" name="data_interval" id="data_interval" [(ngModel)]="densityMin" [ngModelOptions]="{standalone: true}" *ngIf="nowUser.UserRole == 'Admin'">
										<p class="form-control" *ngIf="nowUser.UserRole != 'Admin'">{{ densityMin }}</p>
									</div>
									<div class="mb-3">
										<label for="type" class="form-label">Maximum Density</label>
										<input class="form-control" name="data_interval" id="data_interval" [(ngModel)]="densityMax" [ngModelOptions]="{standalone: true}" *ngIf="nowUser.UserRole == 'Admin'">
										<p class="form-control" *ngIf="nowUser.UserRole != 'Admin'">{{ densityMax }}</p>
									</div>
								</div>
								<div class="col-lg-12">
									<div class="hstack gap-2 justify-content-end">
										<button type="submit" class="btn btn-primary" (click)="saveVessel()" *ngIf="nowUser.UserRole == 'Admin'">Updates</button>
										<button type="button" class="btn btn-subtle-danger" routerLink="/fleet">Back</button>
									</div>
								</div>
								<!--end col-->
							</div>
							<!--end row-->
						</form>
					</div>
				</div>
				<div class="tab-pane" id="profileLogs" role="tabpanel" *ngIf="nowUser.UserRole == 'Admin'">
					<div class="card-header">
						<h6 class="card-title mb-0">Data Changed Log</h6>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-lg-12">
								<div class="table-responsive">
									<table class="table table-borderless align-middle mb-0">
										<thead class="table-aqua">
											<tr>
												<th scope="col">Actor</th>
												<th scope="col">Field</th>
												<th scope="col">Original Value</th>
												<th scope="col">New Value</th>
												<th scope="col">Timestamp</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let dtlog of profileLogs">
												<td>{{ dtlog.actor }}</td>
												<td>{{ dtlog.field_name }}</td>
												<td>{{ dtlog.old_value }}</td>
												<td>{{ dtlog.new_value }}</td>
												<td>{{ dtlog.created_at }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!--end col-->
</div>
<!--end row-->