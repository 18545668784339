<div id="layout-wrapper">
	<app-sidebar></app-sidebar>
	<div class="vertical-overlay"></div>
	<app-navbar *ngIf="isLoggedIn"></app-navbar>
	<div class="main-content" *ngIf="isLoggedIn">
		<div class="page-content" *ngIf="pathUrl != '/map' && pathUrl != '/map-trip'">
			<div class="container-fluid">
				<router-outlet></router-outlet>
			</div>
		</div>
		<div *ngIf="pathUrl == '/map' || pathUrl == '/map-trip'">
			<router-outlet></router-outlet>
		</div>
		<app-footbar *ngIf="isLoggedIn"></app-footbar>
	</div>
</div>
<div id="preloader">
  <div id="status">
	<div class="spinner-border text-primary avatar-sm" role="status">
	  <span class="visually-hidden">Loading...</span>
	</div>
  </div>
</div>
<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasLeft" aria-labelledby="offcanvasLeftLabel">
	<div class="offcanvas-header border-bottom">
		<h5 class="offcanvas-title" id="offcanvasLeftLabel">{{ stateName }} Vessels</h5>
		<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
	</div>
	<div class="offcanvas-body p-0 overflow-hidden">
		<div data-simplebar class="card-body py-0" style="max-height: 95%;">
			<div class="vstack gap-2">
				<div class="py-2 px-3 border border-dashed rounded" *ngFor="let ves of shownVessels">
					<div class="d-flex align-items-center gap-2">
						<div class="flex-shrink-0">
							<img src="assets/images/vessel-bg.jpeg" alt="" class="avatar-md rounded">
						</div>
						<div class="flex-grow-1 overflow-hidden">
							<h6 class="fs-md text-truncate">
								<a data-bs-dismiss="offcanvas" routerLink="/vessel-edit/{{ ves.UnitID }}" class="text-reset">{{ ves.Description }}</a>
								<span class="badge text-bg-{{ ves.badge }} align-middle ms-1">{{ ves.VesselState }}</span>
							</h6>
							<p class="text-muted mb-0">{{ ves.Location }}</p>
							<p class="text-muted mb-0">Last Update: {{ ves.Updated }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!--start back-to-top-->
<button class="btn btn-dark btn-icon" id="back-to-top">
	<i class="bi bi-caret-up fs-3xl"></i>
</button>
<!--end back-to-top-->
<router-outlet *ngIf="!isLoggedIn"></router-outlet>