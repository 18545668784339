import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { GeneralService } from '../../services/general.service';
import { StateService } from '../../services/state.service';
import { VesselService } from '../../services/vessel.service';

@Component({
  selector: 'app-fleet',
  templateUrl: './fleet.component.html',
  styleUrls: ['./fleet.component.css']
})
export class FleetComponent implements OnInit, OnDestroy {

  vesselCall:any;
  vessels:any = [];
  allVessels:any = [];
  assignedTable:any;
  
  nowUser:any = {};
  isLoggedIn = false;
  infos:any = {};
  
  selectedStatus =  "";
  selectedName = "";
  
  constructor(private generalSvc: GeneralService, private apiSvc: ApiService, private stateSvc: StateService, private vesselSvc: VesselService) { }

  ngOnInit(): void {
	this.stateSvc.isLoggedIn
	.subscribe((result:any) => {
		this.isLoggedIn = result;
		if(this.isLoggedIn){
			let sessionNow = this.stateSvc.getLocalSession();
			if(sessionNow?.user){
				this.nowUser = sessionNow?.user;
			}
		}
	});
	
	this.vesselCall = this.stateSvc.sessionVessels
	.subscribe((result:any) => {
		if(this.assignedTable){
			$("#example2").DataTable().destroy();
		}
		this.allVessels = result.vessels;
		this.infos = result.infos;
		this.filterVessel();
		
	});
  }
  
  ngOnDestroy(): void {
	if(this.vesselCall){
		this.vesselCall.unsubscribe();
	}
  }

  filterVes(sel){
	this.selectedStatus = sel;
  }
  
  filterVessel(){
	this.vessels = [];
	if(this.allVessels?.length > 0){
		this.allVessels.forEach((res) => {
			if(this.selectedStatus && res.VesselState != this.selectedStatus){
				return;
			}
			if(this.selectedName && (res.Description.toLowerCase()).indexOf(this.selectedName.toLowerCase()) < 0){
				return;
			}
			this.vessels.push(res);
		});
	}
	if(this.vessels.length == 0){
		$('.noresult').show();
	}else{
		$('.noresult').hide();
		setTimeout(()=>{
			this.assignedTable = $("#example2").DataTable({
			  "responsive": true, "lengthChange": false, "autoWidth": false, paging: false, searching: false, info: false,
			  "buttons": ["excel", "pdf", "print"]
			}).buttons().container().appendTo('#example2_wrapper .col-md-6:eq(0)');
		}, 1000);
	}
  }

}
