<div class="login-box">
    <div class="card">
      <div class="card-header text-center">
        <img src="assets/images/logo-01.png" style="max-height: 64px;">
		  </div>
	  <div class="card-body login-card-body">
        
        <form id="quickForm">
          <div class="mb-3">
				<label for="username" class="form-label">Username <span class="text-danger">*</span></label>
				<div class="position-relative ">
					<input type="text" class="form-control  password-input" name="username" id="username" placeholder="Username *" [(ngModel)]="email" required>
				</div>
			</div>
			<div class="mb-3">
				<label class="form-label" for="password-input">Password <span class="text-danger">*</span></label>
				<div class="position-relative auth-pass-inputgroup mb-3">
					<input type="password" class="form-control pe-5 password-input " name="password" id="password" placeholder="Password *" [(ngModel)]="password" required>
					<button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon"><i class="ri-eye-fill align-middle"></i></button>
				</div>
			</div>
          <div class="row">
            <div class="col-12">
              <button class="btn btn-primary btn-block" (click)="loginAction()" [disabled]="isSubmitting">Sign In</button>
            </div>
            <!-- /.col -->
          </div>
        </form>
      </div>
      <!-- /.login-card-body -->
    </div>
  </div>