import { Component } from '@angular/core';
import { Router, NavigationEnd, NavigationError } from '@angular/router';
import { StateService } from './services/state.service';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { VesselService } from './services/vessel.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Sutra Innofuel';
  pathUrl = "";

  isLoggedIn = false;
  userSess:any = {};
  vesselCall:any;
  
  vessels:any = [];
  shownVessels:any = [];
  stateName = "";
  badgeColor = "";

  intervalJs:any = null;
  
  stateGrps:any = {
	Stopped: [
		'Stopped'
	],
	Active: [
		'Shifting',
		'Manoeuvring',
		'Transit',
		'Pax Transfer',
		'Idling'
	]
  };
  vesselsCollection:any = {
	Stopped: [],
	Idle: [],
	Active: [],
	Total: []
  };
  vesselBadge:any = {
	Stopped: "danger",
	Idle: "warning",
	Active: "success"
  };
  
  constructor(private stateSvc: StateService, private apiSvc: ApiService, private authSvc: AuthService, private vesselSvc: VesselService, private router: Router){
	//$.widget.bridge('uibutton', $.ui.button);
	this.router.events.subscribe(event => {
	  	if (event instanceof NavigationEnd) {
		  this.pathUrl = this.router.url;
		}else if (event instanceof NavigationError) {
			console.error('Navigation failed:', event.error);
		}
	});
	  
	this.stateSvc.isLoggedIn
	.subscribe((result:any) => {
		this.isLoggedIn = result;
		if(this.isLoggedIn){
			this.authSvc.getCurrentSession()
			.subscribe((user) => {
				this.authSvc.storeUserLocal(user);
				let tmpObj = this.stateSvc.getLocalSession();
				this.userSess = tmpObj.user;
				this.userSess.logoUrl = this.userSess? this.apiSvc.getEndpointUrl() + this.userSess?.business_logo : "";
				this.stateSvc.setUserSession(this.userSess);
			});
			this.stateSvc.sessionVessels
			.subscribe((result:any) => {
				this.vessels = result.vessels;
			});
			
			setInterval(() => {
				this.callVessels();
			}, 600000);
			this.callVessels();
			
			this.stateSvc.shownVessels
			.subscribe((res:any) => {
				this.shownVessels = res?.vessels;
				this.stateName = res?.state;
				this.badgeColor = res?.badge;
			});
		}
	});
	let tempSess = this.stateSvc.checkActiveSession();
	this.stateSvc.setIsLoggedIn(tempSess);
	setTimeout(() => {
		$('#sidebar-overlay').on('click', function () {
			console.log('clicked');
			$('body').removeClass('sidebar-open').addClass('sidebar-closed sidebar-collapse');
		});
	}, 700);	

	this.intervalJs = setInterval(() => {
		this.checkElemExist();
	},500);
  }

  checkElemExist(){
	var list = document.getElementsByTagName('script');
	var i = list.length;
	var flag = false;
	while (i--) {
		if ((list[i].src).indexOf("assets/js/app.js") > -1) {
			flag = true;
			break;
		}
	}
	if(!flag && $('.navbar-menu').length > 0 && $('#fontsLink').length > 0){
		var tag = document.createElement('script');
		tag.src = "assets/js/app.js";
		document.getElementsByTagName('body')[0].appendChild(tag);
		clearInterval(this.intervalJs);
		this.intervalJs = null;
	}
  }
  
  callVessels(){
	this.vesselCall = this.vesselSvc.getVessels()
	.subscribe((res) => {
		if(res?.vessels){
			this.vessels = res.vessels;
			this.stateSvc.setSessionVessels(res);
			this.vesselsCollection.Stopped = [];
			this.vesselsCollection.Idle = [];
			this.vesselsCollection.Active = [];
			this.vesselsCollection.Total = [];
			this.vessels.forEach((ves) => {
				this.getStatusGrp(ves);
			});
			this.stateSvc.setSummaryStateVessels(this.vesselsCollection);
		}
	});
  }
  
  getStatusGrp(ves){
	for(let key in this.stateGrps){
		let objRows = this.stateGrps[key];
		objRows.forEach((stt) => {
			if(stt == ves.VesselState){
				ves.badge = this.vesselBadge[key];
				this.vesselsCollection[key].push(ves);
			}
		});
	}
	this.vesselsCollection.Total.push(ves);
  }
}
