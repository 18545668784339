import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { AuthService } from '../../../services/auth.service';
import { GeneralService } from '../../../services/general.service';
import { StateService } from '../../../services/state.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	email = "";
	password = "";
	remember = false;
	isLoggedIn = false;
	isSubmitting = false;
	clickedLogin = false;
	browserNavi:any = {};
	
  constructor(private apiSvc: ApiService, private authSvc: AuthService, private generalSvc: GeneralService, private stateSvc: StateService) { }

  ngOnInit(): void {
	$('.password-addon').click(function(e){
		let elemNow = e.currentTarget.previousSibling;
		let currAttr = elemNow.getAttribute('type');
		if(currAttr == "text"){
			elemNow.setAttribute("type", "password");
		}else{
			elemNow.setAttribute("type", "text");
		}
	});
	
	this.browserNavi = navigator;
	$('body').addClass('login-w-bg');
	this.stateSvc.isLoggedIn.subscribe(t=>{
		this.isLoggedIn = t;
		this.setupLayout()
	});
	$("#quickForm").validate({
		rules: {
	 		username: {
	 			required: true
	 		},
	 		password: {
	 			required: true
	 		}
	 	},
	 	messages: {
	 		username: {
	 			required: "Please enter an Username"
	 		},
	 		password: {
	 			required: "Please enter a password"
	 		}
	 	},
	 	errorElement: "span",
	 	errorPlacement: function(t, e) {
	 		t.addClass("invalid-feedback");
	 		e.closest(".input-group").append(t);
	 	},
	 	highlight: function(t, e, n) {
	 		$(t).addClass("is-invalid");
	 	},
	 	unhighlight: function(t, e, n) {
	 		$(t).removeClass("is-invalid");
	 	}
	})
  }
  
  loginAction() {
		this.isSubmitting = true;
		if($("#quickForm").valid()){
			var data = {
				email: this.email,
				password: this.password,
				remember: this.remember,
				network: this.browserNavi?.connection?.effectiveType,
				platform: this.browserNavi?.userAgentData?.platform,
				device: this.browserNavi?.userAgentData?.mobile? "mobile" : "desktop",
				width: screen?.width,
				height: screen?.height
			}
			this.authSvc.login(data)
			.subscribe(t=>{
				$('body').removeClass('login-w-bg');
				this.isSubmitting = false;
				this.clickedLogin = true;
				this.authSvc.storeUserLocal(t);
				this.stateSvc.setIsLoggedIn(true);
			}, (err) => {
				this.generalSvc.handleErr(err);
				this.isSubmitting = false;
			});
		}else{
		 	this.isSubmitting = false;
		}
	}
	
	setupLayout() {
		if(this.isLoggedIn){
			$('body').removeClass('login-w-bg');
			this.generalSvc.onSessionLayout();
			this.apiSvc.navigateInternal("/dashboard");
			this.clickedLogin && window.location.reload()
		}else{
			this.generalSvc.offSessionLayout();
		}
	}
	
	forgotPassword() {
		this.apiSvc.navigateInternal("/forgot-password");
	}

}
