<!-- start page title -->
<div class="row">
	<div class="col-12">
		<div class="page-title-box d-sm-flex align-items-center justify-content-between">
			<h4 class="mb-sm-0">Profile</h4>

		</div>
	</div>
</div>
<!-- end page title -->

<div class="row">
	<!--end col-->
	<div class="col-xxl-3">
		<div class="card overflow-hidden">
			<div>
				<img [src]="userImage" alt="" class="card-img-top profile-wid-img object-fit-cover" style="height: 200px;">
				<div>
					<input id="profile-foreground-img-file-input" type="file" class="profile-foreground-img-file-input d-none">
					<label for="profile-foreground-img-file-input" class="profile-photo-edit btn btn-light btn-sm position-absolute end-0 top-0 m-3">
						<i class="ri-image-edit-line align-bottom me-1"></i> Change Image
					</label>
				</div>
			</div>
			<div class="card-body border-top">
				<div class="table-responsive table-card p-2">
					<table class="table align-middle table-borderless">
						<tbody>
							<tr>
								<th>ID</th>
								<td>{{ userID }}</td>
							</tr>
							<tr>
								<th>Name</th>
								<td>{{ name }}</td>
							</tr>
							<tr>
								<th>Account</th>
								<td>{{ account }}</td>
							</tr>
							<tr>
								<th>Registered</th>
								<td>{{ registered }}</td>
							</tr>
							<tr>
								<th>Last Login</th>
								<td>{{ lastLogin }}</td>
							</tr>
							<tr>
								<th>Role</th>
								<td>{{ role }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	<div class="col-xxl-9">
		<div class="d-flex align-items-center flex-wrap gap-2 mb-4">
			<ul class="nav nav-pills arrow-navtabs nav-secondary gap-2 flex-grow-1 order-2 order-lg-1" role="tablist">
				<li class="nav-item" role="presentation">
					<a class="nav-link active" data-bs-toggle="tab" href="#personalDetails" role="tab" aria-selected="true">
						Personal Details
					</a>
				</li>
				<li class="nav-item" role="presentation">
					<a class="nav-link" data-bs-toggle="tab" href="#changePassword" role="tab" aria-selected="false" tabindex="-1">
						Change Password
					</a>
				</li>
				<li class="nav-item" role="presentation">
					<a class="nav-link" data-bs-toggle="tab" href="#education" role="tab" aria-selected="false" tabindex="-1">
						Login Logs
					</a>
				</li>
				<li class="nav-item" role="presentation">
					<a class="nav-link" data-bs-toggle="tab" href="#securityPrivacy" role="tab" aria-selected="false" tabindex="-1">
						Data Changed Logs
					</a>
				</li>
			</ul>
		</div>
		<div class="card">
			<div class="tab-content">

				<div class="tab-pane active" id="personalDetails" role="tabpanel">
					<div class="card-header">
						<h6 class="card-title mb-0">Personal Details</h6>
					</div>
					<div class="card-body">
						<form id="quickPersonal">
							<div class="row">
								<div class="col-lg-12">
									<div class="mb-3">
										<label for="firstnameInput" class="form-label">Name</label>
										<input type="text" class="form-control" name="name" id="name" placeholder="Enter your firstname" [(ngModel)]="name" [ngModelOptions]="{standalone: true}">
									</div>
								</div>
								<!--end col-->
								<div class="col-lg-12">
									<div class="mb-3">
										<label for="lastnameInput" class="form-label">Email</label>
										<input type="text" class="form-control" id="lastnameInput" placeholder="Enter your last name" [(ngModel)]="email" [ngModelOptions]="{standalone: true}">
									</div>
								</div>
								<!--end col-->
								<div class="col-lg-12">
									<div class="mb-3">
										<label for="phonenumberInput" class="form-label">Phone Number</label>
										<input type="text" class="form-control" id="phonenumberInput" placeholder="Enter your phone number" [(ngModel)]="phone" [ngModelOptions]="{standalone: true}">
									</div>
								</div>
								<div class="col-lg-12">
									<div class="hstack gap-2 justify-content-end">
										<button type="submit" class="btn btn-primary" (click)="saveProfile()">Update</button>
										<button type="button" class="btn btn-subtle-danger" routerLink="/users">Back</button>
									</div>
								</div>
								<!--end col-->
							</div>
							<!--end row-->
						</form>
					</div>
				</div>
				<!--end tab-pane-->
				<div class="tab-pane" id="changePassword" role="tabpanel">
					<div class="card-header">
						<h6 class="card-title mb-0">Changes Password</h6>
					</div>
					<div class="card-body">
						<form id="quickPwd">
							<div class="row g-2 justify-content-lg-between align-items-center">
								<div class="col-lg-12">
									<div class="auth-pass-inputgroup">
										<label for="password-input" class="form-label">Current Password*</label>
										<div class="position-relative">
											<input type="password" class="form-control password-input" onpaste="return false" placeholder="Enter new password" aria-describedby="passwordInput" [(ngModel)]="currentPassword" [ngModelOptions]="{standalone: true}" name="currentPassword" id="currentPassword">
											<button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"><i class="ri-eye-fill align-middle"></i></button>
										</div>

									</div>
								</div>
								
								<div class="col-lg-12">
									<div class="auth-pass-inputgroup">
										<label for="password-input" class="form-label">New Password*</label>
										<div class="position-relative">
											<input type="password" class="form-control password-input" onpaste="return false" placeholder="Enter new password" aria-describedby="passwordInput" [(ngModel)]="password" [ngModelOptions]="{standalone: true}" name="password" id="password">
											<button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"><i class="ri-eye-fill align-middle"></i></button>
										</div>

									</div>
								</div>

								<div class="col-lg-12">
									<div class="auth-pass-inputgroup">
										<label for="confirm-password-input" class="form-label">Confirm Password*</label>
										<div class="position-relative">
											<input type="password" class="form-control password-input" onpaste="return false" placeholder="Confirm password" [(ngModel)]="repassword" [ngModelOptions]="{standalone: true}" name="repassword" id="repassword">
											<button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"><i class="ri-eye-fill align-middle"></i></button>
										</div>
									</div>
								</div>
								<div class="col-lg-12">
									<div class="hstack gap-2 justify-content-end">
										<button type="submit" class="btn btn-primary" (click)="savePassword()">Updates</button>
									</div>
								</div>
							</div>
							<!--end row-->
						</form>
					</div>
				</div>
				<!--end tab-pane-->
				<div class="tab-pane" id="education" role="tabpanel">
					<div class="card-header">
						<h6 class="card-title mb-0">Login History</h6>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-lg-12">
								<div class="table-responsive">
									<table class="table table-borderless align-middle mb-0">
										<thead class="table-aqua">
											<tr>
												<th scope="col">IP Address</th>
												<th scope="col">Device</th>
												<th scope="col">Platform</th>
												<th scope="col">Timestamp</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let rec of loginLogs">
												<td>{{ rec.ip_address }}</td>
												<td>{{ rec.device }}</td>
												<td>{{ rec.platform }}</td>
												<td>{{ rec.created_at }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--end tab-pane-->
				<div class="tab-pane" id="securityPrivacy" role="tabpanel">
					<div class="card-header">
						<h6 class="card-title mb-0">Data Changed Log</h6>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-lg-12">
								<div class="table-responsive">
									<table class="table table-borderless align-middle mb-0">
										<thead class="table-aqua">
											<tr>
												<th scope="col">Actor</th>
												<th scope="col">Field</th>
												<th scope="col">Original Value</th>
												<th scope="col">New Value</th>
												<th scope="col">Timestamp</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let dtlog of profileLogs">
												<td>{{ dtlog.actor }}</td>
												<td>{{ dtlog.field_name }}</td>
												<td>{{ dtlog.old_value }}</td>
												<td>{{ dtlog.new_value }}</td>
												<td>{{ dtlog.created_at }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--end tab-pane-->
			</div>
		</div>
	</div>
	<!--end col-->
</div>