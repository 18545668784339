<!-- start page title -->
<div class="row">
	<div class="col-12">
		<div class="page-title-box d-sm-flex align-items-center justify-content-between">
			<h4 class="mb-sm-0">Report - Fuel Usage</h4>

		</div>
	</div>
</div>
<!-- end page title -->

<div class="row mb-2">
	<div class="col-lg-12">
		<div class="card">
			<div class="card-body">
				<div class="row g-3">
					<div class="col-xxl">
						<div>
							<label>Vessel</label>
							<select class="form-control" id="idCategory" [(ngModel)]="vessel">
								<option *ngFor="let ves of vesselOpts" [value]="ves.UnitID">{{ ves.Description }}</option>
							</select>
						</div>
					</div><!--end col-->
					<div class="col-xxl">
						<label>From</label>
						<input class="form-control" type="datetime-local" [(ngModel)]="rptFrom" id="from" placeholder="From" step="1">
					</div><!--end col-->
					<div class="col-xxl">
						<label>To</label>
						<input class="form-control" type="datetime-local" [(ngModel)]="rptTo" id="to" placeholder="To" step="1">
					</div><!--end col-->
					<div class="col-xxl">
						<div class="form-group">
							<label>Interval</label>
							<select class="form-control" id="vess" [(ngModel)]="intervalMin">
								<option *ngFor="let opt of intervalOpts" [value]="opt.id">{{ opt.value }}</option>
							</select>
						</div>
					</div>
					<div class="col-xxl-auto col-sm-6 pt-3">
						<button type="button" class="btn btn-dark w-md" (click)="generateReport()" [disabled]="isSubmitting">
							<span class="d-flex align-items-center" *ngIf="isSubmitting">
								<span class="spinner-border flex-shrink-0" role="status">
									<span class="visually-hidden">Loading...</span>
								</span>
								<span class="flex-grow-1 ms-2">
									Loading...
								</span>
							</span>
							<span *ngIf="!isSubmitting">
								<i class="bi bi-search align-baseline me-1"></i> Search
							</span>
						</button>
					</div><!--end col-->
				</div><!--end row-->
			</div>
		</div>
	</div><!--end col-->
</div><!--end row-->

<div class="row mb-3" *ngIf="allResults.length > 0">
	<div class="col-12 col-md-12">
		<div class="card">
			<div class="card-header align-items-center d-flex">
				<h6 class="card-title mb-0 flex-grow-1">Fuel Usage</h6>
				<div class="dropdown card-header-dropdown flex-shrink-0">
					<a class="text-reset dropdown-btn" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<span class="text-muted"><i class="mdi mdi-chevron-down ms-1"></i></span>
					</a>
					<div class="dropdown-menu dropdown-menu-end">
						<a class="dropdown-item" >Save as Trending Line</a>
						<a class="dropdown-item" (click)="fetchTrendingLine()">Apply Trending Line</a>
					</div>
				</div>
			</div>
			<div class="card-body">
				<button class="btn btn-primary text-center" type="button" disabled *ngIf="allResults.length > 0 && !lineChartDrawn">
					<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Generating chart...
				</button>
				<div id="lineChart" data-colors='["--tb-primary"]' class="apex-charts" dir="ltr" data-height="340"></div>
				<!--<canvas id="lineChart" style="min-height: 250px; height: 250px; max-height: 340px; max-width: 100%;"></canvas>-->
			</div>
		</div>
	</div>
</div>

<div class="row mb-3" *ngIf="allResults.length > 0">
	<div class="col-12 col-md-6">
		<div class="card" style="min-height: 330px;">
			<div class="card-header bg-gray">
			  <h3 class="card-title">Fuel Usage Summary</h3>
			  <div class="card-tools">
				<button type="button" class="btn btn-tool" data-card-widget="collapse">
				  <i class="fas fa-minus"></i>
				</button>
			  </div>
			</div>
			<div class="card-body">
				<table class="table">
					<thead class="table-aqua">
						<tr>
							<th>Engine</th>
							<th>Total Usage (l)</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Engine 1 (l)</td>
							<td class="text-right">{{ resultUsageRaw?.eng1 }}</td>
						</tr>
						<tr>
							<td>Engine 2 (l)</td>
							<td class="text-right">{{ resultUsageRaw?.eng2 }}</td>
						</tr>
						<tr>
							<td>Engine 3 (l)</td>
							<td class="text-right">{{ resultUsageRaw?.eng3 }}</td>
						</tr>
						<tr>
							<td>Auxiliary (l)</td>
							<td class="text-right">{{ resultUsageRaw?.gen2 }}</td>
						</tr>
						<tr>
							<th>Total (l)</th>
							<th class="text-right">{{ resultUsageRaw?.total }}</th>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="col-12 col-md-6">
		<div class="card">
			<div class="card-header bg-gray">
			  <h3 class="card-title">Fuel Usage Breakdown</h3>
			  <div class="card-tools">
				<button type="button" class="btn btn-tool" data-card-widget="collapse">
				  <i class="fas fa-minus"></i>
				</button>
			  </div>
			</div>
			<div class="card-body">
				<button class="btn btn-primary text-center" type="button" disabled *ngIf="allResults.length > 0 && !pieChartDrawn">
					<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Generating chart...
				</button>
				<div id="pieChart" data-colors='["--tb-primary"]' class="apex-charts" dir="ltr" data-height="340"></div>
				<!--<canvas id="pieChart" style="min-height: 250px; height: 250px; max-height: 340px; max-width: 100%;"></canvas>-->
			</div>
		</div>
	</div>
</div>

<div class="card" id="card_result" style="min-height: 300px;" *ngIf="allResults.length > 0">
	<div class="card-header bg-gray">
	  <h3 class="card-title">Report</h3>
	  <div class="card-tools">
		<button type="button" class="btn btn-tool" data-card-widget="collapse">
		  <i class="fas fa-minus"></i>
		</button>
	  </div>
	</div>
	<div class="card-body">
	  <div class="row" >
		<div class="col-md-12">
			<div class="btn-group" role="group" aria-label="Export">
				<button type="button" class="btn btn-secondary" (click)="generateReportExcel()" [disabled]="isSubmittingXls">
					<span class="d-flex align-items-center" *ngIf="isSubmittingXls">
						<span class="spinner-border flex-shrink-0" role="status">
							<span class="visually-hidden">Downloading...</span>
						</span>
						<span class="flex-grow-1 ms-2">
							Downloading...
						</span>
					</span>
					<span *ngIf="!isSubmittingXls">
						Excel
					</span>
				</button>
				<button type="button" class="btn btn-secondary" (click)="generateReportPdf()" [disabled]="isSubmittingPdf">
					<span class="d-flex align-items-center" *ngIf="isSubmittingPdf">
						<span class="spinner-border flex-shrink-0" role="status">
							<span class="visually-hidden">Downloading...</span>
						</span>
						<span class="flex-grow-1 ms-2">
							Downloading...
						</span>
					</span>
					<span *ngIf="!isSubmittingPdf">
						PDF
					</span>
				</button>
			</div>
		</div>
		<div class="col-md-12">
			<table id="example1" class="table table-bordered table-striped table-head-fixed">
			  <thead class="table-aqua">
			  <tr>
				<th>Date Time</th>
				<th>Engine 1 (l)</th>
				<th>Engine 2 (l)</th>
				<th>Engine 3 (l)</th>
				<th>Auxiliary (l)</th>
				<th>Total (l)</th>
			  </tr>
			  </thead>
			  <tbody>
				<tr *ngFor="let res of results;let i = index">
					<td>{{ res.datetimestamp }}</td>
					<td class="text-right">{{ res.eng1 }}</td>
					<td class="text-right">{{ res.eng2 }}</td>
					<td class="text-right">{{ res.eng3 }}</td>
					<td class="text-right">{{ res.gen2 }}</td>
					<td class="text-right">{{ res.total_cons_beauty }}</td>
				</tr>
			  </tbody>
			</table>
		</div>
	  </div>
	  <div class="row mt-3">
		<p *ngIf="dataRender">Rendering {{ allResults.length }} records..</p>
		<p *ngIf="!dataRender && results?.length > 0"><strong>Total {{results.length}} records</strong></p>
	  </div>
	</div>
</div>