import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MapComponent } from './components/map/map.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FleetComponent } from './components/fleet/fleet.component';
import { ReportsComponent } from './components/reports/reports.component';
import { LoginComponent } from './components/auth/login/login.component';
import { ProfileComponent } from './components/auth/profile/profile.component';
import { UsersComponent } from './components/users/users.component';
import { PartialsComponent } from './components/partials/partials.component';
import { NavbarComponent } from './components/partials/navbar/navbar.component';
import { SidebarComponent } from './components/partials/sidebar/sidebar.component';
import { FootbarComponent } from './components/partials/footbar/footbar.component';
import { FleetViewComponent } from './components/fleet/fleet-view/fleet-view.component';
import { UserViewComponent } from './components/users/user-view/user-view.component';
import { FleetEditComponent } from './components/fleet/fleet-edit/fleet-edit.component';
import { IndividuComponent } from './components/map/individu/individu.component';
import { FullMovementComponent } from './components/report/full-movement/full-movement.component';
import { SensorTotalComponent } from './components/report/sensor-total/sensor-total.component';
import { FuelHourComponent } from './components/report/fuel-hour/fuel-hour.component';
import { EngineStatusComponent } from './components/report/engine-status/engine-status.component';
import { EngineHourComponent } from './components/report/engine-hour/engine-hour.component';
import { FuelConsumptionComponent } from './components/report/fuel-consumption/fuel-consumption.component';
import { SensorDensityComponent } from './components/report/sensor-density/sensor-density.component';
import { BunkerComponent } from './components/report/bunker/bunker.component';
import { TripComponent } from './components/map/trip/trip.component';
import { AnomaliesComponent } from './components/report/anomalies/anomalies.component';

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    DashboardComponent,
    FleetComponent,
    ReportsComponent,
    LoginComponent,
    ProfileComponent,
    UsersComponent,
    PartialsComponent,
    NavbarComponent,
    SidebarComponent,
    FootbarComponent,
    FleetViewComponent,
    UserViewComponent,
    FleetEditComponent,
    IndividuComponent,
    FullMovementComponent,
    SensorTotalComponent,
    FuelHourComponent,
    EngineStatusComponent,
    EngineHourComponent,
    FuelConsumptionComponent,
    SensorDensityComponent,
    BunkerComponent,
    TripComponent,
    AnomaliesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
	  FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
