import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { GeneralService } from '../../../services/general.service';
import { StateService } from '../../../services/state.service';
import { VesselService } from '../../../services/vessel.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-fleet-edit',
  templateUrl: './fleet-edit.component.html',
  styleUrls: ['./fleet-edit.component.css']
})
export class FleetEditComponent implements OnInit, OnDestroy {

  vesselID = "";
  
  vesselCall:any;
  vesselsCall:any;
  vessel:any = {};
  profileLogs:any = [];
  
  name = "";
  benchmarks:any = [];
  nowUser:any = {};
  isLoggedIn = false;

  reportFrequency = 0;
  
  map:any;
  constructor(private generalSvc: GeneralService, private apiSvc: ApiService, private stateSvc: StateService, private activatedRoute: ActivatedRoute, private vesselSvc: VesselService) { 
	this.activatedRoute.params.subscribe((res)=>{
		this.vesselID = res.id;
	})
  }

  ngOnInit(): void {
	this.stateSvc.isLoggedIn
	.subscribe((result:any) => {
		this.isLoggedIn = result;
		if(this.isLoggedIn){
			let sessionNow = this.stateSvc.getLocalSession();
			if(sessionNow?.user){
				this.nowUser = sessionNow?.user;
			}
		}
	});
		
	setTimeout(() => {
		this.callVesselInfo();
	}, 1200);
  }
  
  callVesselInfo(){
	/*this.vesselCall = this.vesselSvc.getVessel(this.vesselID)
	.subscribe((res) => {
		this.vessel = res?.vessel;
		this.name = this.vessel?.Description;
	});*/
	this.vesselCall = this.stateSvc.sessionVessels
	.subscribe((result:any) => {
		let sessVessels = result.vessels;
		if(sessVessels?.length > 0){
			sessVessels.forEach((ves) => {
				if(ves.UnitID == this.vesselID){
					this.vessel = ves;
					this.name = this.vessel?.Description;
					
					this.benchmarks = ves?.benchmarks;
					this.profileLogs = ves?.profile_logs;
				}
			});
		}
	});
  }
  
  ngOnDestroy(){
	if(this.vesselCall){
		this.vesselCall.unsubscribe();
	}
	if(this.map){
		this.map.remove();
		this.map = null;
	}
  }
  
  saveVessel(){
	if($("#quickVes").valid()){
		this.generalSvc.showLoading();
		let data:any = {
			vesselID: this.vesselID,
			name: this.name,
			benchmarks: []
		};
		if(this.benchmarks?.length > 0){
			this.benchmarks.forEach((bm) => {
				data.benchmarks.push({
					type: bm.type,
					value: bm.value
				});
			});
		}
		
		this.vesselSvc.updateVessel(data, this.vesselID)
		.subscribe((res) => {
			this.callVessels();
			this.generalSvc.hideLoading();
			this.generalSvc.showToast("Vessel information updated", "success");
		},
		(err)=> {
			this.generalSvc.hideLoading();
			this.generalSvc.handleErr(err);
		});
	}
  }
  
  callVessels(){
	this.vesselsCall = this.vesselSvc.getVessels()
	.subscribe((res) => {
		if(res?.vessels){
			this.stateSvc.setSessionVessels(res);
		}
	});
  }

}
