import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { StateService } from './state.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map,catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private apiservice: ApiService, private http: HttpClient, private stateSvc: StateService) { }
  
	storeUserLocal(t) {
		let e = JSON.stringify(t);
		localStorage.setItem("user-marine", e)
	}
	
	getCurrentSession(): Observable <any>   {
		let t = this.apiservice.getEndpointUrl() + "currentsession";
		let e = this.apiservice.getHttpHeaderWithCredential();
		return this.http.get(t, {
			headers: e
		})
		.pipe(
			map((results:any) => {
				return results;
			}),
			catchError((error:any) => {
				return throwError(error);
			})
		);
	}
	
	makeid(t) {
		let e = "";
		let n = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let r = n.length;
		for (let i = 0; i < t; i++){
			e += n.charAt(Math.floor(Math.random() * r));
		}
		return e;
	}
	
	forgotPassword(t): Observable <any>   {
		let e = this.apiservice.getEndpointUrl();
		let n = this.apiservice.getHttpHeader();
		let r = e + "login/forgotPassword";
		let i = {
			email: t.trim()
		};
		return this.http.post(r, i, {headers: n})
		.pipe(
			map((results:any) => {
				return results;
			}),
			catchError((error:any) => {
				return throwError(error);
			})
		);
	}
	
	login(data): Observable <any>   {
		let e = this.apiservice.getEndpointUrl();
		let n = this.apiservice.getHttpHeader();
		let r = e + "login";
		let randId = this.makeid(12);
		data.client_id = randId;
		let i = data;
		return this.http.post(r, i, {headers: n})
		.pipe(
			map((results:any) => {
				return results;
			}),
			catchError((error:any) => {
				return throwError(error);
			})
		);
	}
	
	logout(data): Observable <any>   {
		let e = this.apiservice.getEndpointUrl();
		let n = this.apiservice.getHttpHeaderWithCredential();
		let r = e + "logout";
		let i = data;
		return this.http.post(r, i, {headers: n})
		.pipe(
			map((results:any) => {
				return results;
			}),
			catchError((error:any) => {
				return throwError(error);
			})
		);
	}
	
	updateProfile(data): Observable <any>   {
		let e = this.apiservice.getEndpointUrl();
		let n = this.apiservice.getHttpHeaderWithCredential();
		let r = e + "profile";
		let i = data;
		return this.http.post(r, i, {headers: n})
		.pipe(
			map((results:any) => {
				return results;
			}),
			catchError((error:any) => {
				return throwError(error);
			})
		);
	}
	
	updatePassword(data): Observable <any>   {
		let e = this.apiservice.getEndpointUrl();
		let n = this.apiservice.getHttpHeaderWithCredential();
		let r = e + "password";
		let i = data;
		return this.http.put(r, i, {headers: n})
		.pipe(
			map((results:any) => {
				return results;
			}),
			catchError((error:any) => {
				return throwError(error);
			})
		);
	}
}
