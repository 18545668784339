<!-- start page title -->
<div class="row">
	<div class="col-12">
		<div class="page-title-box d-sm-flex align-items-center justify-content-between">
			<h4 class="mb-sm-0">Dashboard - {{ activeVessel.Description }}</h4>

		</div>
	</div>
</div>
<!-- end page title -->

<div class="row mb-2">
	<div class="col-lg-12">
		<div class="card">
			<div class="card-body">
				<div class="row g-3">
					<div class="col-xxl-4">
						<div>
							<select class="form-control" id="idCategory" (change)="dropdownVes($event)">
								<option *ngFor="let ves of vessels" [value]="ves.UnitID" [selected]="">{{ ves.Description }}</option>
							</select>
						</div>
					</div><!--end col-->
					<div class="col-xxl">
						<p class="form-control mb-0 b-0">
							Last Updated: {{ activeVessel.Updated }}
						</p>
					</div><!--end col-->
				</div><!--end row-->
			</div>
		</div>
	</div><!--end col-->
</div><!--end row-->

<div class="row">
	<div class="col-xxl-12">
		<div class="row mb-2">
			<div class="col-md-2">
				<div class="card overflow-hidden">
				  <div class="card-body">
					<div class="avatar-sm float-end">
					  <div class="avatar-title bg-info-subtle text-info fs-3xl rounded">
						<i class="bx bxs-ship bx-md"></i>
					  </div>
					</div>
					<p class="text-muted fw-medium text-uppercase mb-1">Status</p>
					<h4>
					  {{ activeVessel.VesselState }}
					</h4>
				  </div>
				  <div class="progress progress-sm rounded-0" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
					<div class="progress-bar bg-info" style="width: 100%"></div>
				  </div>
				</div>
			</div><!--end col-->
			<div class="col-md-2">
				<div class="card overflow-hidden">
				  <div class="card-body">
					<div class="avatar-sm float-end">
					  <div class="avatar-title bg-danger-subtle text-danger fs-3xl rounded">
						<i class="bx bx-tachometer bx-md"></i>
					  </div>
					</div>
					<p class="text-muted fw-medium text-uppercase mb-1">Speed</p>
					<h4>
						{{ activeVessel.Speed }} knot
					</h4>
				  </div>
				  <div class="progress progress-sm rounded-0" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
					<div class="progress-bar bg-danger" style="width: 100%"></div>
				  </div>
				</div>
			</div><!--end col-->
			<div class="col-md-2">
				<div class="card overflow-hidden">
				  <div class="card-body">
					<div class="avatar-sm float-end">
					  <div class="avatar-title bg-success-subtle text-success fs-3xl rounded">
						<i class="bx bx-globe bx-md"></i>
					  </div>
					</div>
					<p class="text-muted fw-medium text-uppercase mb-1">Bearing</p>
					<h4>
						{{ activeVessel.Bearing }}&deg;
					</h4>
				  </div>
				  <div class="progress progress-sm rounded-0" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
					<div class="progress-bar bg-success" style="width: 100%"></div>
				  </div>
				</div>
			</div><!--end col-->
			<div class="col-md-2">
				<div class="card overflow-hidden">
				  <div class="card-body">
					<div class="avatar-sm float-end">
					  <div class="avatar-title bg-warning-subtle text-warning fs-3xl rounded">
						<i class="bx bxs-compass bx-md"></i>
					  </div>
					</div>
					<p class="text-muted fw-medium text-uppercase mb-1">Heading</p>
					<h4>
						{{ activeVessel.Direction }}
					</h4>
				  </div>
				  <div class="progress progress-sm rounded-0" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
					<div class="progress-bar bg-warning" style="width: 100%"></div>
				  </div>
				</div>
			</div><!--end col-->
			<div class="col-md-2">
				<div class="card overflow-hidden">
				  <div class="card-body">
					<div class="avatar-sm float-end">
					  <div class="avatar-title bg-info-subtle text-info fs-3xl rounded">
						<i class="bx bxs-gas-pump bx-md"></i>
					  </div>
					</div>
					<p class="text-muted fw-medium text-uppercase mb-1">Fuel Consumption</p>
					<h4>
					  {{ totalCons }} (l)
					</h4>
				  </div>
				  <div class="progress progress-sm rounded-0" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
					<div class="progress-bar bg-info" style="width: 100%"></div>
				  </div>
				</div>
			</div><!--end col-->
			<div class="col-md-2">
				<div class="card overflow-hidden">
				  <div class="card-body">
					<div class="avatar-sm float-end">
					  <div class="avatar-title bg-success-subtle text-success fs-3xl rounded">
						<i class="bx bxs-tachometer bx-md"></i>
					  </div>
					</div>
					<p class="text-muted fw-medium text-uppercase mb-1">Distance</p>
					<h4>
					  {{ totalDistance }} Nm
					</h4>
				  </div>
				  <div class="progress progress-sm rounded-0" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
					<div class="progress-bar bg-success" style="width: 100%"></div>
				  </div>
				</div>
			</div><!--end col-->
			<!--end col-->
		</div><!--end row--> 
	</div>
	<!--end col-->
</div>

<div class="row">
	<div class="col-xxl-6">
		<div class="row">
			<div class="col-xxl-6">
				<div class="card overflow-hidden">
					<div>
						<img src="assets/images/vessel-bg.jpeg" alt="" class="card-img-top profile-wid-img object-fit-cover" style="height: 245px;">
					</div>
				</div>
			</div>
			<div class="col-xxl-6">
				<div class="card overflow-hidden">
					<div class="card-body pt-1">
						<div class="table-responsive">
							<table class="table align-middle table-borderless mb-0 table-vessel-info">
								<tbody>
									<tr>
										<th>Name</th>
										<td>{{ activeVessel.Description }}</td>
									</tr>
									<tr>
										<th>Type</th>
										<td>{{ activeVessel.VesselType }}</td>
									</tr>
									<tr>
										<th>Account</th>
										<td>{{ activeVessel.Account }}</td>
									</tr>
									<tr>
										<th>Commissioned</th>
										<td>{{ activeVessel.Commissioned }}</td>
									</tr>
									<tr>
										<th>Tonnage</th>
										<td>{{ activeVessel.Tonnage }}</td>
									</tr>
									<tr>
										<th>MMSI</th>
										<td>{{ activeVessel.MMSI }}</td>
									</tr>
									<tr>
										<th>Communication Mode</th>
										<td>{{ activeVessel.ComMode }}</td>
									</tr>
									<tr>
										<th >GPS Status</th>
										<td >{{ activeVessel.GPS }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div><!--end card-->
			</div>
		</div>
	</div>
	<div class="col-xxl-6">
		<div class="card">
			<div class="card-body pt-1">
				<table class="table mb-2">
					<thead class="table-aqua">
						<tr>
							<th>Engine</th>
							<th>State</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Engine 1</td>
							<td><h6><span class="badge bg-{{ activeVessel.engine_1_badge }} rounded-circle align-bottom">&nbsp;</span> {{ activeVessel.is_engine_on == "1" && activeVessel.engine_1 == "1"? "On" : "Off" }}</h6></td>
						</tr>
						<tr>
							<td>Engine 2</td>
							<td><h6><span class="badge bg-{{ activeVessel.engine_2_badge }} rounded-circle align-bottom">&nbsp;</span> {{ activeVessel.is_engine_on == "1" && activeVessel.engine_2 == "1"? "On" : "Off" }}</h6></td>
						</tr>
						<tr>
							<td>Engine 3</td>
							<td><h6><span class="badge bg-{{ activeVessel.engine_3_badge }} rounded-circle align-bottom">&nbsp;</span> {{ activeVessel.is_engine_on == "1" && activeVessel.engine_3 == "1"? "On" : "Off" }}</h6></td>
						</tr>
						<tr>
							<td>Auxiliary</td>
							<td><h6><span class="badge bg-{{ activeVessel.aux_2_badge }} rounded-circle align-bottom">&nbsp;</span> {{ activeVessel.is_engine_on == "1" && activeVessel.aux_2 == "1"? "On" : "Off" }}</h6></td>
						</tr>
						<tr>
							<td>&nbsp;</td>
							<td>&nbsp;</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>

<div class="row mt-3">
	<!--end col-->
	<div class="col-xxl-6">
		<div class="card">
			<div class="card-body">
				<div id="map" style="height: 230px"></div>
			</div>
		</div>
	</div>
	<div class="col-xxl-4">
		<div class="card overflow-hidden">
			<div class="card-body pt-1">
				<div class="table-responsive">
					<table class="table align-middle table-borderless mb-0 table-vessel-info">
						<tbody>
							<tr>
								<th class="pt-0 pb-1">Weather</th>
								<td class="pt-0 pb-1">{{ weather }}</td>
							</tr>
							<tr>
								<th class="pt-0 pb-1">Wave Heigth</th>
								<td class="pt-0 pb-1">{{ waveHeight }}</td>
							</tr>
							<tr>
								<th class="pt-0 pb-1">Wave Direction</th>
								<td class="pt-0 pb-1">{{ waveDirection }}</td>
							</tr>
							<tr>
								<th class="pt-0 pb-1">Swell Heigth</th>
								<td class="pt-0 pb-1">{{ swellHeight }}</td>
							</tr>
							<tr>
								<th class="pt-0 pb-1">Swell Direction</th>
								<td class="pt-0 pb-1">{{ swellDirection }}</td>
							</tr>
							<tr>
								<th class="pt-0 pb-1">Current Velocity</th>
								<td class="pt-0 pb-1">{{ oceanCurrentHeight }}</td>
							</tr>
							<tr>
								<th class="pt-0 pb-1">Current Direction</th>
								<td class="pt-0 pb-1">{{ oceanCurrentDirection }}</td>
							</tr>
							<tr>
								<th class="pt-0 pb-1">Wind Direction</th>
								<td class="pt-0 pb-1">{{ windDirection }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div><!--end card-->
	</div>
	<div class="col-xxl-2">
		<div class="card ribbon-box ribbon-fill" style="min-height: 240px;">
			<div class="card-body">
			  <div class="mt-3 text-center">
				<div class="position-relative d-inline-block">
				  <i class="bx bxs-{{ weatherIcon }} bx-lg"></i>
				</div>
				<h3 class="mt-4 mb-1">
				  {{ temperature }}
				</h3>
				<h5 class="text-muted">{{ weather }}</h5>
			  </div>
			</div>
		</div>
	</div>
</div>
<!--end row-->

<div class="row mt-3">
	<div class="col-md-12">
		<div class="card">
			<div class="card-header bg-gray">
			  <h3 class="card-title">Consumption</h3>
			  <div class="card-tools">
				<button type="button" class="btn btn-tool" data-card-widget="collapse">
				  <i class="fas fa-minus"></i>
				</button>
			  </div>
			</div>
			<div class="card-body">
				<div id="consChart" data-colors='["--tb-primary"]' class="apex-charts" dir="ltr" data-height="340"></div>
			</div>
		</div>
	</div>
</div>

<div class="row mt-3">
	<div class="col-md-6">
		<div class="card" style="min-height: 330px;">
			<div class="card-header bg-gray">
			  <h3 class="card-title">Summary By Engine</h3>
			  <div class="card-tools">
				<button type="button" class="btn btn-tool" data-card-widget="collapse">
				  <i class="fas fa-minus"></i>
				</button>
			  </div>
			</div>
			<div class="card-body">
				<table class="table">
					<thead class="table-aqua">
						<tr>
							<th>Engine</th>
							<th>Total Usage (l)</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Engine 1</td>
							<td class="text-right">{{ resultUsageRaw?.eng1 }}</td>
						</tr>
						<tr>
							<td>Engine 2</td>
							<td class="text-right">{{ resultUsageRaw?.eng2 }}</td>
						</tr>
						<tr>
							<td>Engine 3</td>
							<td class="text-right">{{ resultUsageRaw?.eng3 }}</td>
						</tr>
						<tr>
							<td>Auxiliary</td>
							<td class="text-right">{{ resultUsageRaw?.gen1 }}</td>
						</tr>
						<tr>
							<th>Total (l)</th>
							<th class="text-right">{{ resultUsageRaw?.total }}</th>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="col-md-6">
		<div class="card">
			<div class="card-header bg-gray">
			  <h3 class="card-title">Fuel Usage Breakdown</h3>
			  <div class="card-tools">
				<button type="button" class="btn btn-tool" data-card-widget="collapse">
				  <i class="fas fa-minus"></i>
				</button>
			  </div>
			</div>
			<div class="card-body">
				<div id="pieChart" data-colors='["--tb-primary"]' class="apex-charts" dir="ltr" data-height="340"></div>
			</div>
		</div>
	</div>
</div>