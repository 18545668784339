import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { GeneralService } from '../../../services/general.service';
import { StateService } from '../../../services/state.service';
import { ReportService } from '../../../services/report.service';

@Component({
  selector: 'app-sensor-total',
  templateUrl: './sensor-total.component.html',
  styleUrls: ['./sensor-total.component.css']
})
export class SensorTotalComponent implements OnInit, OnDestroy {

  vesselCall:any;
  datatbl:any;
  isSubmitting = false;
  rptCall:any;
  
  reportTypeOpts = [
    {
      id: "Full Movement",
      name: "Full Movement"
    },
    {
      id: "Fuel Usage",
      name: "Fuel Usage"
    },
    {
      id: "Fuel Sensor Total",
      name: "Fuel Sensor Total"
    },
    {
      id: "Fuel Consumption",
      name: "Fuel Consumption"
    },
    {
      id: "Fuel / Hour",
      name: "Fuel / Hour"
    },
    {
      id: "Engine Hour",
      name: "Engine Hour"
    },
    {
      id: "Engine Status",
      name: "Engine Status"
    }
  ];
  
  intervalOpts = [
    {
      id: 1,
      value: 1
    },
    {
      id: 5,
      value: 5
    },
    {
      id: 10,
      value: 10
    },
    {
      id: 15,
      value: 15
    },
    {
      id: 30,
      value: 30
    },
    {
      id: 45,
      value: 45
    },
    {
      id: 60,
      value: 60
    },
  ];
  
  vesselOpts:any = [];
  dataRender = true;
  initSize = 100;
  loaded = 0;
  totalSize = 0;
  allResults:any = [];
  isLoading = false;
  
  rptType = "Fuel Sensor Total";
  vessel:any = "2";
  rptFrom = "2024-01-02 00:00:00";
  rptTo = "2024-01-06 23:59:59";
  fromPicker:any = {};
  toPicker:any = {};
  intervalMin = 1;
  excludeZero = false;
  
  results:any = [];
  resultUsage:any = {};
  resultPercent:any = {};
  resultUsageRaw:any = {};
  
  constructor(private generalSvc: GeneralService, private apiSvc: ApiService, private stateSvc: StateService, private rptSvc: ReportService) { }

  ngOnInit(): void {
	  this.callVessels();
    this.rptFrom = this.generalSvc.getTodayStart();
	  this.rptTo = this.generalSvc.getToday();

    if(this.stateSvc.getReportStartDate() != ""){
      this.rptFrom = this.generalSvc.convertLastDate(this.stateSvc.getReportStartDate());
    }
    if(this.stateSvc.getReportEndDate() != ""){
      this.rptTo = this.generalSvc.convertLastDate(this.stateSvc.getReportEndDate());
    }

    this.fromPicker = $('#from').flatpickr({
      enableTime: true,
      enableSeconds: true,
      dateFormat: "d/m/Y H:i:s",
      defaultDate: new Date(this.rptFrom),
      onChange: (selectedDates, dateStr, instance) => {
        this.rptFrom = dateStr;
        this.stateSvc.setReportStartDate(this.rptFrom);
        this.toPicker.set('minDate', this.rptFrom);
      }
    });
    this.rptFrom = $('#from').val();
  
    this.toPicker = $('#to').flatpickr({
      enableTime: true,
      enableSeconds: true,
      dateFormat: "d/m/Y H:i:s",
      defaultDate: new Date(this.rptTo),
      onChange: (selectedDates, dateStr, instance) => {
        this.rptTo = dateStr;
        this.stateSvc.setReportEndDate(this.rptTo);
        this.fromPicker.set('maxDate', this.rptTo);
      }
    });
    this.rptTo = $('#to').val();

    this.stateSvc.setReportStartDate(this.rptFrom);
    this.stateSvc.setReportEndDate(this.rptTo);
    this.fromPicker.set('maxDate', this.rptTo);
	  this.toPicker.set('minDate', this.rptFrom);

    $(window).scroll(() => {
      var scrollPos = $(document).scrollTop();
      let lastPos = $(document).height() - ($(window).height() + scrollPos);
      if(lastPos < 80 && this.allResults.length > 0 && this.dataRender && !this.isLoading){
        this.runLoading();
      }
    });
  }
  
  ngOnDestroy(){
    if(this.vesselCall){
      this.vesselCall.unsubscribe();
    }
    if(this.rptCall){
      this.rptCall.unsubscribe();
    }
    if(this.datatbl){
      $("#example1").DataTable().destroy();
    }
  }
  
  callVessels(){
    this.vesselCall = this.stateSvc.sessionVessels
    .subscribe((result:any) => {
      this.vesselOpts = result.vessels;
    });
  }
  
  generateReport(){
    let startDate = new Date(this.generalSvc.convertLastDate(this.rptFrom));
	  let toDate = new Date(this.generalSvc.convertLastDate(this.rptTo));

    if(startDate > toDate){
      this.generalSvc.showToast('To date must be greater than From Date', "error");
      return;
    }
    if(!this.vessel){
      this.generalSvc.showToast('Please select a vessel', "error");
      return;
    }
    // Calculating the time difference of two dates
    let differenceInTime = toDate.getTime() - startDate.getTime();
    // Calculating the no. of days between two dates
    let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    if(differenceInDays > 30){
      this.generalSvc.showToast('Date range selection cannot be more than 30 days', "error");
      return;
    }
    this.isSubmitting = true;
    this.generalSvc.showLoading();
    let data:any = {
      type: this.rptType,
      vessel: this.vessel,
      from: this.generalSvc.convertLastDate(this.rptFrom),
		  to: this.generalSvc.convertLastDate(this.rptTo),
      interval: this.intervalMin,
      excludeZero: this.excludeZero,
      canKivInterval: true
    };
    if(this.datatbl){
      $("#example1").DataTable().destroy();
    }
    this.allResults = [];
    this.results = [];
    this.loaded = 0;
    this.totalSize = 0;
    this.dataRender = true;
	  this.isLoading = false;
    this.rptCall = this.rptSvc.generateReport(data)
    .subscribe((res) => {
      $('.filter-card').addClass('collapsed-card');
      // this.results = res?.result;
      this.allResults = res?.result;
      this.resultUsage = res?.total;
      this.resultUsageRaw = res?.totalRaw;
      this.resultPercent = res?.percent;
      if(res?.result?.length == 0){
        this.generalSvc.showToast('No result found on the selected date time', "error");
        this.isSubmitting = false;
        return;
      }else{
        // res.result.forEach((dt, ix) => {
        // if(ix < this.initSize){
        //     this.results.push(dt);
        //     this.loaded = this.initSize;
        //   }else{
        //     return;
        //   }
        // })
      }
      setTimeout(() => {
        this.datatbl = $("#example1").DataTable({
          "responsive": true, "lengthChange": true, "autoWidth": false,
          "buttons": ["excel", "print", "colvis"], 
          // "pageLength": 100,
          "paging": false,
          "ordering": false,
          "info": false
        }).buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
        this.generalSvc.hideLoading();
        this.isSubmitting = false;

        $('.dataTables_empty').hide();
        this.runLoading();
        // let lastPos = ($(document).height() - 70) - $(window).height();
        // $(window).scrollTop(lastPos);

        // setTimeout(() => {
        //   res.result.forEach((dt, ix) => {
        //     if(ix >= this.initSize){
        //     this.results.push(dt);
        //     }
        //   });
        //   this.dataRender = false;
        // },500)
        
      },1000);
      // document?.getElementById("card_result")?.scrollIntoView();
    }, (err) => {
      this.isSubmitting = false;
      this.generalSvc.handleErr(err);
      this.generalSvc.hideLoading();
    });
  }

  runLoading(){
    this.isLoading = true;
    setTimeout(() => {
      if(this.allResults.length >= this.loaded){
        let endIx = this.loaded + this.initSize;
        this.allResults.forEach((dt, ix) => {
          if(ix >= this.loaded && ix < endIx){
            this.results.push(dt);
            this.isLoading = false;
          }else{
            return;
          }
        })	
        this.loaded = endIx;
      }else{
        this.dataRender = false;
      }
    }, 500);
  }

}
