import { Component, OnInit, OnDestroy } from '@angular/core';
import { StateService } from '../../../services/state.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit,OnDestroy {

  isLoggedIn = false;
  userSess:any = {};
  vesselCall:any;
  
  vessels:any = [];
  nowUser:any = {};
  
  constructor(private stateSvc: StateService) { }

  ngOnInit(): void {
	this.stateSvc.isLoggedIn
	.subscribe((result:any) => {
		this.isLoggedIn = result;
		if(this.isLoggedIn){
			this.vesselCall = this.stateSvc.sessionVessels
			.subscribe((result:any) => {
				this.vessels = result.vessels;
			});
			
			let sessionNow = this.stateSvc.getLocalSession();
			if(sessionNow?.user){
				this.nowUser = sessionNow?.user;
			}
		}
	});
  }
  
  ngOnDestroy(){
	if(this.vesselCall){
		this.vesselCall.unsubscribe();
	}
  }

}
