import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor() { }
  
  showToast(t, e) {
	toastr.options.progressBar = true;
	toastr.options.closeButton = true;
	toastr.options.positionClass = "toast-top-center";
	if(e == "error"){
		toastr.error(t);
	}else{
		toastr.success(t);
	}
	}
	offSessionLayout() {
		$("body").removeClass("sidebar-mini layout-navbar-fixed layout-fixed");
		$("body").addClass("login-page");
	}
	onSessionLayout() {
		$("body").removeClass("login-page");
		$("body").addClass("sidebar-mini layout-navbar-fixed layout-fixed");
	}
	handleErr(t) {
		let e = "";
		if(t.status == 500){
			e = t.statusText;
		}else{
			e = t.error.message;
		}
		
		this.showToastr(e, "error")
	}
	showLoading() {
		$(".preloader").css({
			height: "100vh",
			opacity: 0.5
		}),
		$(".animation__shake").show()
	}
	hideLoading() {
		$(".preloader").css({
			height: "0px",
			opacity: 1
		}),
		$(".animation__shake").hide()
	}
	getFirstDayMonth(){
		let today = new Date();
		let mth2 = (today.getMonth() + 1) <= 9? "0" + (today.getMonth() + 1) : (today.getMonth() + 1);
		let strDate = today.getFullYear() + '-' + mth2 + "-01 00:00:00";
		return strDate;
	}
	getToday(){
		let today = new Date();
		let mth2 = (today.getMonth() + 1) <= 9? "0" + (today.getMonth() + 1) : (today.getMonth() + 1);
		let date2 = (today.getDate()) <= 9? "0" + (today.getDate()) : (today.getDate());
		let strDate = today.getFullYear() + '-' + mth2 + "-" + date2 + " 23:59:59";
		return strDate;
	}

	getTodayStart(){
		let today = new Date();
		let mth2 = (today.getMonth() + 1) <= 9? "0" + (today.getMonth() + 1) : (today.getMonth() + 1);
		let date2 = (today.getDate()) <= 9? "0" + (today.getDate()) : (today.getDate());
		let strDate = today.getFullYear() + '-' + mth2 + "-" + date2 + " 00:00:00";
		return strDate;
	}
	getTodayNow(){
		let today = new Date();
		let mth2 = (today.getMonth() + 1) <= 9? "0" + (today.getMonth() + 1) : (today.getMonth() + 1);
		let date2 = (today.getDate()) <= 9? "0" + (today.getDate()) : (today.getDate());
		let hour2 = (today.getHours()) <= 9? "0" + (today.getHours()) : (today.getHours());
		let minit2 = (today.getMinutes()) <= 9? "0" + (today.getMinutes()) : (today.getMinutes());

		let strDate = today.getFullYear() + '-' + mth2 + "-" + date2 + " " + hour2 + ":" + minit2 + ':00';
		return strDate;
	}
	showToastr(t, e) {
		if(e == "error"){
			toastr.error(t);
		}else{
			toastr.success(t);
		}
	}

	convertLastDate(dateOld){
		let splitDateTime = dateOld.split(' ');
		let splitDateDmy = splitDateTime[0].split('/');
		let strNew = splitDateDmy[2] + '-' + splitDateDmy[1] + '-' + splitDateDmy[0] + ' ' + splitDateTime[1];

		return strNew;
	}
}
