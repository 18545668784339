import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { GeneralService } from '../../../services/general.service';
import { StateService } from '../../../services/state.service';
import { VesselService } from '../../../services/vessel.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-fleet-view',
  templateUrl: './fleet-view.component.html',
  styleUrls: ['./fleet-view.component.css']
})
export class FleetViewComponent implements OnInit, OnDestroy {

  vesselID = "";
  
  vesselCall:any;
  vesselsCall:any;
  vessel:any = {};
  
  name = "";
  benchmarks:any = [];
  nowUser:any = {};
  isLoggedIn = false;
  
  map:any;
  
  constructor(private generalSvc: GeneralService, private apiSvc: ApiService, private stateSvc: StateService, private activatedRoute: ActivatedRoute, private vesselSvc: VesselService) { 
	this.activatedRoute.params.subscribe((res)=>{
		this.vesselID = res.id;
	})
  }

  ngOnInit(): void {
	this.stateSvc.isLoggedIn
	.subscribe((result:any) => {
		this.isLoggedIn = result;
		if(this.isLoggedIn){
			let sessionNow = this.stateSvc.getLocalSession();
			if(sessionNow?.user){
				this.nowUser = sessionNow?.user;
			}
		}
	});
	
	mapboxgl.accessToken = this.apiSvc.getMapBoxKey();
	this.map = new mapboxgl.Map({
		container: 'view_map', // container ID
		center: [103.332442, 5.37285], // starting position [lng, lat]
		zoom: 9 // starting zoom
	});
	setTimeout(() => {
		this.callVesselInfo();
	}, 1200);
					
	var date = new Date()
    var d    = date.getDate(),
        m    = date.getMonth(),
        y    = date.getFullYear()

    var Calendar = FullCalendar.Calendar;
	var calendarEl = document.getElementById('calendar');
	var calendar = new Calendar(calendarEl, {
	  headerToolbar: {
		left  : 'prev,next today',
		center: 'title',
		right : 'dayGridMonth,timeGridWeek,timeGridDay'
	  },
	  themeSystem: 'bootstrap',
	  //events: result.data,
	  editable  : false,
	  droppable : false // this allows things to be dropped onto the calendar !!!
	});

	calendar.render();
  }
  
  callVesselInfo(){
	/*this.vesselCall = this.vesselSvc.getVessel(this.vesselID)
	.subscribe((res) => {
		this.vessel = res?.vessel;
		this.name = this.vessel?.Description;
	});*/
	this.vesselCall = this.stateSvc.sessionVessels
	.subscribe((result:any) => {
		let sessVessels = result.vessels;
		if(sessVessels?.length > 0){
			sessVessels.forEach((ves) => {
				if(ves.UnitID == this.vesselID){
					this.vessel = ves;
					this.name = this.vessel?.Description;
					
					this.benchmarks = ves?.benchmarks;
					
					this.map?.on('load', () => {
						// Add an image to use as a custom marker
						this.map.loadImage(
							'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
							(error, image) => {
								if (error) throw error;
								this.map?.addImage('custom-marker', image);
								// Add a GeoJSON source with 2 points
								this.map?.addSource('points', {
									'type': 'geojson',
									'data': {
										'type': 'FeatureCollection',
										'features': [
											{
												// feature for Mapbox DC
												'type': 'Feature',
												'geometry': {
													'type': 'Point',
													'coordinates': [
													  ves.Lon, ves.Lat
													]
												},
												'properties': {
													'title': ves.Description
												}
											}
										]
									}
								});

								// Add a symbol layer
								this.map?.addLayer({
									'id': 'points',
									'type': 'symbol',
									'source': 'points',
									'layout': {
										'icon-image': 'custom-marker',
										// get the title name from the source's "title" property
										'text-field': ['get', 'title'],
										'text-font': [
											'Open Sans Semibold',
											'Arial Unicode MS Bold'
										],
										'text-offset': [0, 1.25],
										'text-anchor': 'top'
									}
								});
							}
						);
					});
				}
			});
		}
	});
  }
  
  ngOnDestroy(){
	if(this.vesselCall){
		this.vesselCall.unsubscribe();
	}
	if(this.map){
		this.map.remove();
		this.map = null;
	}
  }
  
  saveVessel(){
	if($("#quickVes").valid()){
		this.generalSvc.showLoading();
		let data:any = {
			vesselID: this.vesselID,
			name: this.name,
			benchmarks: []
		};
		if(this.benchmarks?.length > 0){
			this.benchmarks.forEach((bm) => {
				data.benchmarks.push({
					type: bm.type,
					value: bm.value
				});
			});
		}
		
		this.vesselSvc.updateVessel(data, this.vesselID)
		.subscribe((res) => {
			this.callVessels();
			this.generalSvc.hideLoading();
			this.generalSvc.showToast("Vessel information updated", "success");
		},
		(err)=> {
			this.generalSvc.hideLoading();
			this.generalSvc.handleErr(err);
		});
	}
  }
  
  callVessels(){
	this.vesselsCall = this.vesselSvc.getVessels()
	.subscribe((res) => {
		if(res?.vessels){
			this.stateSvc.setSessionVessels(res);
		}
	});
  }

}
