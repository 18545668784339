import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { GeneralService } from '../../../services/general.service';
import { StateService } from '../../../services/state.service';
import { ReportService } from '../../../services/report.service';

@Component({
  selector: 'app-sensor-density',
  templateUrl: './sensor-density.component.html',
  styleUrls: ['./sensor-density.component.css']
})
export class SensorDensityComponent implements OnInit,OnDestroy {

  vesselCall:any;
  datatbl:any;
  isSubmitting = false;
  rptCall:any;
  isSubmittingPdf = false;
  isSubmittingXls = false;
  lineChartDrawn = false;
  linechart:any;
  isFirstLoad = true;
  
  reportTypeOpts = [
    {
      id: "Full Movement",
      name: "Full Movement"
    },
    {
      id: "Fuel Usage",
      name: "Fuel Usage"
    },
    {
      id: "Fuel Sensor Total",
      name: "Fuel Sensor Total"
    },
    {
      id: "Fuel Consumption",
      name: "Fuel Consumption"
    },
    {
      id: "Fuel / Hour",
      name: "Fuel / Hour"
    },
    {
      id: "Engine Hour",
      name: "Engine Hour"
    },
    {
      id: "Engine Status",
      name: "Engine Status"
    }
  ];
  
  intervalOpts = [
    {
      id: 1,
      value: 1
    },
    {
      id: 5,
      value: 5
    },
    {
      id: 10,
      value: 10
    },
    {
      id: 15,
      value: 15
    },
    {
      id: 30,
      value: 30
    },
    {
      id: 45,
      value: 45
    },
    {
      id: 60,
      value: 60
    },
  ];
  
  vesselOpts:any = [];
  dataRender = true;
  initSize = 100;
  loaded = 0;
  totalSize = 0;
  allResults:any = [];
  isLoading = false;
  
  rptType = "Fuel Sensor Density";
  vessel:any = "2";
  rptFrom = "2024-01-02 00:00:00";
  rptTo = "2024-01-06 23:59:59";
  fromPicker:any = {};
  toPicker:any = {};
  intervalMin = 1;
  excludeZero = false;
  
  results:any = [];
  resultUsage:any = {};
  resultPercent:any = {};
  resultUsageRaw:any = {};

  showMinOnly = false;
  
  constructor(private generalSvc: GeneralService, private apiSvc: ApiService, private stateSvc: StateService, private rptSvc: ReportService) { }

  ngOnInit(): void {
	  this.callVessels();
    this.rptFrom = this.generalSvc.getTodayStart();
	  this.rptTo = this.generalSvc.getToday();

    if(this.stateSvc.getReportStartDate() != ""){
      this.rptFrom = this.generalSvc.convertLastDate(this.stateSvc.getReportStartDate());
    }
    if(this.stateSvc.getReportEndDate() != ""){
      this.rptTo = this.generalSvc.convertLastDate(this.stateSvc.getReportEndDate());
    }

    this.fromPicker = $('#from').flatpickr({
      enableTime: true,
      enableSeconds: true,
      dateFormat: "d/m/Y H:i:s",
      defaultDate: new Date(this.rptFrom),
      onChange: (selectedDates, dateStr, instance) => {
        this.rptFrom = dateStr;
        this.stateSvc.setReportStartDate(this.rptFrom);
        this.toPicker.set('minDate', this.rptFrom);
      }
    });
    this.rptFrom = $('#from').val();
  
    this.toPicker = $('#to').flatpickr({
      enableTime: true,
      enableSeconds: true,
      dateFormat: "d/m/Y H:i:s",
      defaultDate: new Date(this.rptTo),
      onChange: (selectedDates, dateStr, instance) => {
        this.rptTo = dateStr;
        this.stateSvc.setReportEndDate(this.rptTo);
        this.fromPicker.set('maxDate', this.rptTo);
      }
    });
    this.rptTo = $('#to').val();

    this.stateSvc.setReportStartDate(this.rptFrom);
    this.stateSvc.setReportEndDate(this.rptTo);
    this.fromPicker.set('maxDate', this.rptTo);
	  this.toPicker.set('minDate', this.rptFrom);

    $(window).scroll(() => {
      var scrollPos = $(document).scrollTop();
      let lastPos = $(document).height() - ($(window).height() + scrollPos);
      if(lastPos < 80 && this.allResults.length > 0 && this.dataRender && !this.isLoading){
        this.runLoading();
      }
    });
  }
  
  ngOnDestroy(){
    if(this.vesselCall){
      this.vesselCall.unsubscribe();
    }
    if(this.rptCall){
      this.rptCall.unsubscribe();
    }
    if(this.datatbl){
      $("#example1").DataTable().destroy();
    }
    if(this.linechart){
      this.linechart.destroy();
    }
  }
  
  callVessels(){
    this.vesselCall = this.stateSvc.sessionVessels
    .subscribe((result:any) => {
      this.vesselOpts = result.vessels;
    });
  }
  
  generateReport(){
    let startDate = new Date(this.generalSvc.convertLastDate(this.rptFrom));
	  let toDate = new Date(this.generalSvc.convertLastDate(this.rptTo));

    if(startDate > toDate){
      this.generalSvc.showToast('To date must be greater than From Date', "error");
      return;
    }
    if(!this.vessel){
      this.generalSvc.showToast('Please select a vessel', "error");
      return;
    }
    if(!this.showMinOnly){
      // Calculating the time difference of two dates
      let differenceInTime = toDate.getTime() - startDate.getTime();
      // Calculating the no. of days between two dates
      let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
      if(differenceInDays > 31){
        this.generalSvc.showToast('Date range selection cannot be more than 31 days', "error");
        return;
      }
    }else{
      // Calculating the time difference of two dates
      let differenceInTime = toDate.getTime() - startDate.getTime();
      // Calculating the no. of days between two dates
      let differenceInDays = differenceInTime / (1000 * 3600 * 24);
      if(differenceInDays > 1){
        this.generalSvc.showToast('Date range selection cannot be more than 1 days', "error");
        return;
      }
    }
    this.isSubmitting = true;
    this.generalSvc.showLoading();
    let data:any = {
      type: this.rptType,
      vessel: this.vessel,
      from: this.generalSvc.convertLastDate(this.rptFrom),
		  to: this.generalSvc.convertLastDate(this.rptTo),
      interval: this.intervalMin,
      excludeZero: this.excludeZero,
      canKivInterval: true,
      showMin: this.showMinOnly
    };
    if(this.datatbl){
      $("#example1").DataTable().destroy();
    }
    if(this.linechart){
      this.linechart.destroy();
    }
    this.allResults = [];
    this.results = [];
    this.loaded = 0;
    this.totalSize = 0;
    this.dataRender = true;
	  this.isLoading = false;
    this.lineChartDrawn = false;
    this.isFirstLoad = true;
    this.rptCall = this.rptSvc.generateReport(data)
    .subscribe((res) => {
      $('.filter-card').addClass('collapsed-card');
      this.allResults = res?.result;
      this.resultUsage = res?.total;
      this.resultUsageRaw = res?.totalRaw;
      this.resultPercent = res?.percent;
      if(res?.result?.length == 0){
        this.generalSvc.showToast('No result found on the selected date time', "error");
        this.isSubmitting = false;
        return;
      }
      
      setTimeout(() => {
        this.datatbl = $("#example1").DataTable({
          "responsive": true, "lengthChange": false, "autoWidth": false,
          // "buttons": ["excel", "print", "colvis"], 
          // "pageLength": 25,
          "paging": false,
          "ordering": false,
          "info": false,
          "searching": false
        }).buttons().container().appendTo('#example1_wrapper .col-md-6:eq(0)');
        this.generalSvc.hideLoading();
        this.isSubmitting = false;

        $('.dataTables_empty').hide();
        this.runLoading();
        
      },1000);
      // document?.getElementById("card_result")?.scrollIntoView();
    }, (err) => {
      this.isSubmitting = false;
      this.generalSvc.handleErr(err);
      this.generalSvc.hideLoading();
    });
  }

  runLoading(){
    this.isLoading = true;
    setTimeout(() => {
      if(this.allResults.length >= this.loaded){
        let endIx = this.loaded + this.initSize;
        this.allResults.forEach((dt, ix) => {
          if(ix >= this.loaded && ix < endIx){
            this.results.push(dt);
            this.isLoading = false;
          }else{
            return;
          }
        })	
        this.loaded = endIx;
      }else{
        this.dataRender = false;
      }

      if(this.allResults.length > 0 && this.isFirstLoad){
        setTimeout(() => {
          let lineLabel:any = [];
          let lineDataset:any = [
          {
            label               : 'Engine1 Supply',
            backgroundColor     : 'rgba(0, 123, 255,0.9)',
            fill				: false,
            borderColor         : 'rgba(0, 123, 255,0.8)',
            pointRadius          : false,
            pointColor          : '#007bff',
            pointStrokeColor    : 'rgba(0, 123, 255,1)',
            pointHighlightFill  : '#fff',
            pointHighlightStroke: 'rgba(0, 123, 255,1)',
            data                : []
          },
          {
            label               : 'Engine1 Return',
            backgroundColor     : 'rgba(253, 126, 20,0.9)',
            fill				: false,
            borderColor         : 'rgba(253, 126, 20,0.8)',
            pointRadius          : false,
            pointColor          : '#fd7e14',
            pointStrokeColor    : 'rgba(253, 126, 20,1)',
            pointHighlightFill  : '#fff',
            pointHighlightStroke: 'rgba(253, 126, 20,1)',
            data                : []
          },
          {
            label               : 'Engine2 Supply',
            backgroundColor     : 'rgba(32, 201, 151,0.9)',
            fill				: false,
            borderColor         : 'rgba(32, 201, 151,0.8)',
            pointRadius          : false,
            pointColor          : '#20c997',
            pointStrokeColor    : 'rgba(32, 201, 151,1)',
            pointHighlightFill  : '#fff',
            pointHighlightStroke: 'rgba(32, 201, 151,1)',
            data                : []
          },
          {
            label               : 'Engine2 Return',
            backgroundColor     : 'rgba(32, 201, 151,0.9)',
            fill				: false,
            borderColor         : 'rgba(32, 201, 151,0.8)',
            pointRadius          : false,
            pointColor          : '#20c997',
            pointStrokeColor    : 'rgba(32, 201, 151,1)',
            pointHighlightFill  : '#fff',
            pointHighlightStroke: 'rgba(32, 201, 151,1)',
            data                : []
          },
          {
            label               : 'Engine3 Supply',
            backgroundColor     : 'rgba(32, 201, 151,0.9)',
            fill				: false,
            borderColor         : 'rgba(32, 201, 151,0.8)',
            pointRadius          : false,
            pointColor          : '#20c997',
            pointStrokeColor    : 'rgba(32, 201, 151,1)',
            pointHighlightFill  : '#fff',
            pointHighlightStroke: 'rgba(32, 201, 151,1)',
            data                : []
          },
          {
            label               : 'Engine3 Return',
            backgroundColor     : 'rgba(32, 201, 151,0.9)',
            fill				: false,
            borderColor         : 'rgba(32, 201, 151,0.8)',
            pointRadius          : false,
            pointColor          : '#20c997',
            pointStrokeColor    : 'rgba(32, 201, 151,1)',
            pointHighlightFill  : '#fff',
            pointHighlightStroke: 'rgba(32, 201, 151,1)',
            data                : []
          },
          {
            label               : 'Auxiliary Supply',
            backgroundColor     : 'rgba(102,16,242,0.9)',
            fill				: false,
            borderColor         : 'rgba(102,16,242,0.8)',
            pointRadius          : false,
            pointColor          : '#6610F2',
            pointStrokeColor    : 'rgba(102,16,242,1)',
            pointHighlightFill  : '#fff',
            pointHighlightStroke: 'rgba(102,16,242,1)',
            data                : []
          },
          {
            label               : 'Auxiliary Return',
            backgroundColor     : 'rgba(102,16,242,0.9)',
            fill				: false,
            borderColor         : 'rgba(102,16,242,0.8)',
            pointRadius          : false,
            pointColor          : '#6610F2',
            pointStrokeColor    : 'rgba(102,16,242,1)',
            pointHighlightFill  : '#fff',
            pointHighlightStroke: 'rgba(102,16,242,1)',
            data                : []
          }
        ];
        this.allResults?.forEach((res) => {
          lineLabel.push(res.datetimestamp);
          lineDataset[0].data.push(res.EngDen1FWD);
          lineDataset[1].data.push(res.EngDen1RET);
          lineDataset[2].data.push(res.EngDen2FWD);
          lineDataset[3].data.push(res.EngDen2RET);
          lineDataset[4].data.push(res.EngDen3FWD);
          lineDataset[5].data.push(res.EngDen1RET);
          lineDataset[6].data.push(res.GenDen2FWD);
          lineDataset[7].data.push(res.GenDen2RET);
        });
        
        var options = {
          chart: {
            type: 'line',
            height: 340,
            events: {
              mounted: () => {
                this.lineChartDrawn = true;
              }
            }
          },
          colors: ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#BDA3F5", "#03ecfc", "#33B2FF", "#2C2C2C"],
          series: [{
            name: 'Engine1 Supply',
            data: lineDataset[0].data
          },{
            name: 'Engine1 Return',
            data: lineDataset[1].data
          },{
            name: 'Engine2 Supply',
            data: lineDataset[2].data
          },{
            name: 'Engine2 Return',
            data: lineDataset[3].data
          },{
            name: 'Engine3 Supply',
            data: lineDataset[4].data
          },{
            name: 'Engine3 Return',
            data: lineDataset[5].data
          },{
            name: 'Auxiliary Supply',
            data: lineDataset[6].data
          },{
            name: 'Auxiliary Return',
            data: lineDataset[7].data
          }],
          xaxis: {
            categories: lineLabel,
            labels: {
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMM \'yy',
              day: 'dd MMM',
              hour: 'HH:mm'
            }
            },
            tickAmount: 12
          },
          yaxis: [{
            seriesName: "Engine1 Supply",
            // title: {
            //   text: '(Liter)'  // Set the y-axis title with units
            // }
          },],
          stroke: {
            curve: 'smooth'
          },
          markers: {
            size: 5
          }
          }
          this.linechart = new ApexCharts(document.querySelector("#lineChart"), options);
          this.linechart.render();
          this.linechart.hideSeries('Eng1');
          this.linechart.hideSeries('Eng2');
          this.linechart.hideSeries('Eng3');
          this.linechart.hideSeries('Auxiliary');
        }, 1)
      }      
    }, 500);
  }

  generateReportExcel(){
    this.isSubmittingXls = true;
    let data:any = {
      type: this.rptType,
      vessel: this.vessel,
      from: this.generalSvc.convertLastDate(this.rptFrom),
		  to: this.generalSvc.convertLastDate(this.rptTo),
      interval: this.intervalMin,
      excludeZero: this.excludeZero,
      canKivInterval: true,
      file_type: "excel",
      showMin: this.showMinOnly
    };
    this.rptCall = this.rptSvc.generateReportExport(data, "report-sensor-density-export")
    .subscribe((res) => {
      let a= document.createElement('a');
      a.target= '_blank';
      a.href= res.url;
      a.click();
      this.isSubmittingXls = false;
    }, (err) => {
      this.generalSvc.handleErr(err);
      this.isSubmittingXls = false;
    });
    }
  
    generateReportPdf(){
    this.isSubmittingPdf = true;
    let data:any = {
      type: this.rptType,
      vessel: this.vessel,
      from: this.generalSvc.convertLastDate(this.rptFrom),
		  to: this.generalSvc.convertLastDate(this.rptTo),
      interval: this.intervalMin,
      excludeZero: this.excludeZero,
      canKivInterval: true,
      file_type: "pdf",
      showMin: this.showMinOnly
    };
    this.rptCall = this.rptSvc.generateReportExport(data, "report-sensor-density-export")
    .subscribe((res) => {
      let a= document.createElement('a');
      a.target= '_blank';
      a.href= res.url;
      a.click();
      this.isSubmittingPdf = false;
    }, (err) => {
      this.generalSvc.handleErr(err);
      this.isSubmittingPdf = false;
    });
    }

}
