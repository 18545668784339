<!-- start page title -->
<div class="row">
	<div class="col-12">
		<div class="page-title-box d-sm-flex align-items-center justify-content-between">
			<h4 class="mb-sm-0">Users</h4>

		</div>
	</div>
</div>
<!-- end page title -->

<div class="row mb-2">
	<div class="col-xxl-3 col-md-6">
		<div class="card overflow-hidden">
		  <div class="card-body">
			<div class="avatar-md float-end">
			  <div class="avatar-title bg-primary-subtle text-primary fs-3xl rounded">
				<i class="bx bx-user bx-md"></i>
			  </div>
			</div>
			<h4>
			  Total Users
			</h4>
			<p class="text-muted mb-1">{{ infos.total }}</p>
		  </div>
		  <div class="progress progress-sm rounded-0" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
			<div class="progress-bar bg-primary" style="width: 100%"></div>
		  </div>
		</div>
	</div><!--end col-->
	<div class="col-xxl-3 col-md-6">
		<div class="card overflow-hidden">
		  <div class="card-body">
			<div class="avatar-md float-end">
			  <div class="avatar-title bg-success-subtle text-success fs-3xl rounded">
				<i class="bx bx-user bx-md"></i>
			  </div>
			</div>
			<h4>
			  Logged In
			</h4>
			<p class="text-muted mb-1">{{ infos.logged }}</p>
		  </div>
		  <div class="progress progress-sm rounded-0" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
			<div class="progress-bar bg-success" style="width: 100%"></div>
		  </div>
		</div>
	</div><!--end col-->
	<div class="col-xxl-3 col-md-6">
		<div class="card overflow-hidden">
		  <div class="card-body">
			<div class="avatar-md float-end">
			  <div class="avatar-title bg-danger-subtle text-danger fs-3xl rounded">
				<i class="bx bx-user bx-md"></i>
			  </div>
			</div>
			<h4>
			  Admin
			</h4>
			<p class="text-muted mb-1">{{ infos.admin }}</p>
		  </div>
		  <div class="progress progress-sm rounded-0" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
			<div class="progress-bar bg-danger" style="width: 100%"></div>
		  </div>
		</div>
	</div><!--end col-->
	<div class="col-xxl-3 col-md-6">
		<div class="card overflow-hidden">
		  <div class="card-body">
			<div class="avatar-md float-end">
			  <div class="avatar-title bg-warning-subtle text-warning fs-3xl rounded">
				<i class="bx bx-user bx-md"></i>
			  </div>
			</div>
			<h4>
			  Normal User
			</h4>
			<p class="text-muted mb-1">{{ infos.user }}</p>
		  </div>
		  <div class="progress progress-sm rounded-0" role="progressbar" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">
			<div class="progress-bar bg-warning" style="width: 100%"></div>
		  </div>
		</div>
	</div><!--end col-->
</div><!--end row-->

<div class="row mb-2">
	<div class="col-lg-12">
		<div class="card">
			<div class="card-body">
				<div class="row g-3">
					<div class="col-xxl">
						<div class="search-box">
							<input type="text" class="form-control search" placeholder="Search by name or email" [(ngModel)]="filterName" (keyup.enter)="callUsers()">
							<i class="ri-search-line search-icon"></i>
						</div>
					</div><!--end col-->
					<div class="col-xxl col-sm-6">
						<div>
							<select class="form-control" id="idCategory" data-choices data-choices-search-false data-choices-removeItem [(ngModel)]="filterRole">
								<option value="">Select Role</option>
								<option value="Admin">Admin</option>
								<option value="User">User</option>
							</select>
						</div>
					</div><!--end col-->
					<div class="col-xxl col-sm-6">
						<div>
							<select class="form-control" id="idDiscount"  data-choices data-choices-search-false data-choices-removeItem [(ngModel)]="filterStatus">
								<option value="">Select Status</option>
								<option value="1">Active</option>
								<option value="0">Inactive</option>
							</select>
						</div>
					</div><!--end col-->
					<div class="col-xxl-auto col-sm-6">
						<button type="button" class="btn btn-dark w-md" (click)="callUsers()"><i class="bi bi-search align-baseline me-1"></i> Search</button>
					</div><!--end col-->
				</div><!--end row-->
			</div>
		</div>
	</div><!--end col-->
</div><!--end row-->

<div class="row" id="ticketsList">
	<div class="col-lg-12">
		<div class="card">
			<div class="card-body">
				<div class="">
					<table class="table table-centered align-middle table-custom-effect table-nowrap mb-0">
						<thead class="table-aqua">
							<tr>
								<th scope="col" class="sort cursor-pointer" data-sort="assign"></th>
								<th scope="col" class="sort cursor-pointer" data-sort="ticket_title">ID</th>
								<th scope="col" class="sort cursor-pointer" data-sort="ticket_title">Name</th>
								<th scope="col" class="sort cursor-pointer" data-sort="create_date">Role</th>
								<th scope="col" class="sort cursor-pointer" data-sort="client_name">Last Login</th>
								<th scope="col" class="sort cursor-pointer" data-sort="due_date">Status</th>
								<th scope="col">Action</th>
							</tr>
						</thead>
						<tbody class="list form-check-all">
							<tr *ngFor="let user of users">
								<td class="assign">
									<a href="apps-tickets-overview.html" class="d-inline-block" data-bs-toggle="tooltip" data-bs-title="Lucian Griffith">
										<img [src]="user.image" alt="" class="avatar-sm rounded-circle">
									</a>
								</td>
								<td class="ticket_title">{{ user.UserID }}</td>
								<td class="ticket_title">{{ user.UserName }}</td>
								<td class="create_date">{{ user.UserRole }}</td>
								<td class="client_name">{{ user.LastLogin }}</td>
								<td class="due_date">
									<span class="badge bg-success-subtle text-success mb-0" *ngIf="user.IsActive == 1">Active</span>
									<span class="badge bg-danger-subtle text-danger mb-0" *ngIf="user.IsActive != 1">Inactive</span>
								</td>
								<td>
									<ul class="d-flex gap-2 list-unstyled mb-0">
										<li>
											<a routerLink="/user-view/{{ user.UserID }}" *ngIf="nowUser.UserID != user.UserID" class="btn btn-icon btn-sm edit-item-btn"><i class="ph-pencil"></i></a>
											<a routerLink="/profile" *ngIf="nowUser.UserID == user.UserID" class="btn btn-icon btn-sm edit-item-btn"><i class="ph-pencil"></i></a>
										</li>
									</ul>
								</td>
							</tr>
							
						</tbody><!-- end tbody -->
					</table><!-- end table -->
					<div class="noresult" style="display: none">
						<div class="text-center py-4">
							<i class="ph-magnifying-glass fs-1 text-primary"></i>
							<h5 class="mt-2">Sorry! No Result Found</h5>
							<p class="text-muted mb-0">We've searched more than 6k+ tickets We did not find any tickets for you search.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div><!--end col-->
</div><!--end row-->