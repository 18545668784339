import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { GeneralService } from '../../../services/general.service';
import { StateService } from '../../../services/state.service';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.css']
})
export class UserViewComponent implements OnInit, OnDestroy {

  userID = "";
  userCall:any;
  user:any = {};
  profileLogs:any = [];
  loginLogs:any = [];
  
  name = "";
  phone = "";
  email = "";
  role = "";
  roleOpts = [
	{
		id: 'Admin',
		name: 'Admin'
	},
	{
		id: 'User',
		name: 'User'
	}
  ];
  
  password = "";
  repassword = "";
  
  constructor(private generalSvc: GeneralService, private apiSvc: ApiService, private stateSvc: StateService, private activatedRoute: ActivatedRoute, private userSvc: UserService) {
	this.activatedRoute.params.subscribe((res)=>{
		this.userID = res.id;
		this.callUserInfo();
	})
  }

  ngOnInit(): void {
	$('.password-addon').click(function(e){
		let elemNow = e.currentTarget.previousSibling;
		let currAttr = elemNow.getAttribute('type');
		if(currAttr == "text"){
			elemNow.setAttribute("type", "password");
		}else{
			elemNow.setAttribute("type", "text");
		}
	});
	
	$("#quickUser").validate({
		rules: {
	 		name: {
	 			required: true
	 		},
			role: {
	 			required: true
	 		}
	 	},
	 	messages: {
	 		name: {
	 			required: "Please enter a Name"
	 		},
			role: {
	 			required: "Please enter a Role"
	 		}
	 	},
	 	errorElement: "span",
	 	errorPlacement: function(t, e) {
	 		t.addClass("invalid-feedback");
	 		e.closest(".form-group").append(t);
	 	},
	 	highlight: function(t, e, n) {
	 		$(t).addClass("is-invalid");
	 	},
	 	unhighlight: function(t, e, n) {
	 		$(t).removeClass("is-invalid");
	 	}
	});
	
	$("#quickUserPwd").validate({
		rules: {
	 		password: {
	 			required: true
	 		},
			repassword: {
	 			required: true,
				equalTo: "#password"
	 		}
	 	},
	 	messages: {
	 		password: {
	 			required: "Please enter a New Password"
	 		},
			repassword: {
	 			required: "Please enter a Re-type New Password",
				equalTo: "Value must equal to New Password"
	 		}
	 	},
	 	errorElement: "span",
	 	errorPlacement: function(t, e) {
	 		t.addClass("invalid-feedback");
	 		e.closest(".auth-pass-inputgroup").append(t);
	 	},
	 	highlight: function(t, e, n) {
	 		$(t).addClass("is-invalid");
	 	},
	 	unhighlight: function(t, e, n) {
	 		$(t).removeClass("is-invalid");
	 	}
	});
  }
  
  ngOnDestroy(): void {
	if(this.userCall){
		this.userCall.unsubscribe();
	}
  }
  
  callUserInfo(){
	this.userCall = this.userSvc.getUser(this.userID)
	.subscribe((res) => {
		this.user = res?.user;
		
		this.name = this.user.UserName;
		this.email = this.user.Email;
		this.phone = this.user.PhoneMobile;
		this.role = this.user.UserRole;
		
		this.profileLogs = res?.profile_logs;
		this.loginLogs = res?.login_logs;

		if(!res.user.image){
			this.user.image = "assets/images/unknown.jpg";
		}
	});
  }
  
  saveProfile(){
	if($("#quickUser").valid()){
		this.generalSvc.showLoading();
		let data = {
			userID: this.userID,
			name: this.name,
			phone: this.phone,
			email: this.email,
			role: this.role
		};
		
		this.userSvc.updateUser(data, this.userID)
		.subscribe((res) => {
			this.callUserInfo();
			this.generalSvc.hideLoading();
			this.generalSvc.showToast("User information updated", "success");
		},
		(err)=> {
			this.generalSvc.hideLoading();
			this.generalSvc.handleErr(err);
		});
	}
  }
  
  savePassword(){
	if($("#quickUserPwd").valid()){
		this.generalSvc.showLoading();
		let data = {
			new_password: this.password
		};
		
		this.userSvc.updatePassword(data, this.userID)
		.subscribe((res) => {
			this.callUserInfo();
			this.generalSvc.hideLoading();
			this.generalSvc.showToast("Password updated", "success");
		},
		(err)=> {
			this.generalSvc.hideLoading();
			this.generalSvc.handleErr(err);
		});
	}
  }

}
