<!-- start page title -->
<div class="row">
	<div class="col-12">
		<div class="page-title-box d-sm-flex align-items-center justify-content-between">
			<h4 class="mb-sm-0">Full Map</h4>

		</div>
	</div>
</div>
<!-- end page title -->

<div id="map"></div>

<div class="map-overlay" *ngIf="showVes">
    <div class="listing">
		<h5 class="offcanvas-title" id="offcanvasScrollingLabel">Vessels</h5>
		<div class="offcanvas-body p-0">
			<div class="card-body px-0">
				<div data-simplebar class="card-body py-0" style="max-height: 100%;">
					<ul class="list-group list-group-flush">
						<li class="list-group-item ps-0">
							<div class="d-flex align-items-start">
								<div class="search-box form-control">
									<input type="text" class="form-control bg-light border-light" [(ngModel)]="searchVessel" (keyup)="filterVessel()" placeholder="Search here...">
								</div>
							</div>
						</li>
						<li class="list-group-item ps-0" *ngFor="let ves of displayVessels">
							<div class="d-flex align-items-start">
								<div class="form-check ps-0 flex-sharink-0">
									<input type="checkbox" class="form-check-input ms-0" [(ngModel)]="ves.shown" (change)="toggleVessel(ves, 'vessel')">
								</div>
								<div class="flex-grow-1 overflow-hidden">
									<label class="form-check-label mb-0 ps-2" for="task_one">
										<span class="d-block fw-semibold mb-1 text-truncate">{{ ves.Description }}</span>
									</label>
								</div>
								<div class="flex-shrink-0 ms-2">
									<p class="text-muted fs-xs fw-medium mb-0">{{ ves.Updated }}</p>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="map-overlay" *ngIf="showPoi">
    <div class="listing">
		<h5 class="offcanvas-title" id="offcanvasScrollingLabel">POIs</h5>
		<div class="offcanvas-body p-0">
			<div class="card-body px-0">
				<div data-simplebar class="card-body py-0" style="max-height: 100%;">
					<ul class="list-group list-group-flush">
						<li class="list-group-item ps-0">
							<div class="d-flex align-items-start">
								<div class="search-box form-control">
									<input type="text" class="form-control bg-light border-light" [(ngModel)]="searchPoi" (keyup)="filterPoi()" placeholder="Search here...">
								</div>
							</div>
						</li>
						<li class="list-group-item ps-0" *ngFor="let poi of displayPois">
							<div class="d-flex align-items-start">
								<div class="form-check ps-0 flex-sharink-0">
									<input type="checkbox" class="form-check-input ms-0" [(ngModel)]="poi.shown" (change)="toggleVessel(poi, 'poi')">
								</div>
								<div class="flex-grow-1 overflow-hidden">
									<label class="form-check-label mb-0 ps-2" for="task_one">
										<span class="d-block fw-semibold mb-1 text-truncate">{{ poi.Name }}</span>
									</label>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasVesInfo" aria-labelledby="offcanvasScrollingLabel">
    <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasScrollingLabel">Vessels</h5>
		<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" (click)="dismisOffcanvas()"></button>
    </div>
    <div class="offcanvas-body p-0">
		<div class="card overflow-hidden">
			<div>
				<img src="assets/images/vessel-bg.jpeg" alt="" class="card-img-top profile-wid-img object-fit-cover" style="height: 200px;">
				<div>
					<input id="profile-foreground-img-file-input" type="file" class="profile-foreground-img-file-input d-none">
				</div>
			</div>
			<div class="card-body border-top">
				<h4 class="text-capitalize mb-3">{{ selectVessel.Description }} 
					<span *ngIf="selectVessel.VesselState == 'Stopped'" class="badge rounded-pill bg-danger-subtle text-danger">{{ selectVessel.VesselState }}</span>
					<span *ngIf="selectVessel.VesselState == 'Idling'" class="badge rounded-pill bg-warning-subtle text-warning">{{ selectVessel.VesselState }}</span>
					<span *ngIf="selectVessel.VesselState == 'Transit' || selectVessel.VesselState == 'Shifting' || selectVessel.VesselState == 'Pax_Transfer' || selectVessel.VesselState == 'Manoeuvring'" class="badge rounded-pill bg-success-subtle text-success">{{ selectVessel.VesselState }}</span>
				</h4>
				<div class="hstack gap-3 flex-wrap mb-4">
					<div class="text-muted"><b class="text-body fw-medium">IMO</b> <br> {{ selectVessel.IMO }}</div>
					<div class="vr"></div>
					<div class="text-muted"><b class="text-body fw-medium">MMSI</b> <br/> {{ selectVessel.MMSI }}</div>
					<div class="vr"></div>
					<div class="text-muted"><b class="text-body fw-medium">Type</b> <br/> {{ selectVessel.VesselType }}</div>
				</div>
				<div class="table-responsive table-card p-2">
					<table class="table align-middle table-borderless">
						<tbody>
							<tr>
								<th>Speed</th>
								<td>{{ selectVessel.Speed }} knot</td>
							</tr>
							<tr>
								<th class="pt-0 pb-1">Bearing </th>
								<td class="pt-0 pb-1">{{ selectVessel.Bearing }}&deg;</td>
							</tr>
							<tr style="border-bottom: 1px solid lightgray"> 
								<th class="pt-0 pb-1">Heading</th>
								<td class="pt-0 pb-1">{{ selectVessel.Direction }}</td>
							</tr>
							<tr>
								<th class="pt-0 pb-1">Location</th>
								<td class="pt-0 pb-1">{{ selectVessel.Location }}</td>
							</tr>
							<tr>
								<th class="pt-0 pb-1">Weather</th>
								<td class="pt-0 pb-1">{{ weather }}</td>
							</tr>
							<tr>
								<th class="pt-0 pb-1">Wave Heigth</th>
								<td class="pt-0 pb-1">{{ waveHeight }}</td>
							</tr>
							<tr>
								<th class="pt-0 pb-1">Wave Direction</th>
								<td class="pt-0 pb-1">{{ waveDirection }}</td>
							</tr>
							<tr>
								<th class="pt-0 pb-1">Swell Heigth</th>
								<td class="pt-0 pb-1">{{ swellHeight }}</td>
							</tr>
							<tr>
								<th class="pt-0 pb-1">Swell Direction</th>
								<td class="pt-0 pb-1">{{ swellDirection }}</td>
							</tr>
							<tr>
								<th class="pt-0 pb-1">Current Velocity</th>
								<td class="pt-0 pb-1">{{ oceanCurrentHeight }}</td>
							</tr>
							<tr>
								<th class="pt-0 pb-1">Current Direction</th>
								<td class="pt-0 pb-1">{{ oceanCurrentDirection }}</td>
							</tr>
							<tr>
								<th class="pt-0 pb-1">Wind Direction</th>
								<td class="pt-0 pb-1">{{ windDirection }}</td>
							</tr>
							<tr style="border-bottom: 1px solid lightgray">
								<th class="pt-0 pb-1">Temperature</th>
								<td class="pt-0 pb-1">{{ temperature }}</td>
							</tr>
							<tr>
								<th class="pt-0 pb-1">GPS</th>
								<td class="pt-0 pb-1">{{ selectVessel.GPS }}</td>
							</tr>
							<tr>
								<th class="pt-0 pb-1">Communication Mode</th>
								<td class="pt-0 pb-1">{{ selectVessel.ComMode }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
    </div>
</div>

<div class="d-md-block" style="position:fixed; z-index:1000;right: 0.25rem;top: 5rem;" title="POIs" (click)="togglePoi()">
	<button class="btn p-2 btn-icon shadow-lg" [ngClass]="{'btn-dark': showPoi, 'btn-light': !showPoi}">
		<i class="bx bxs-map-pin bx-sm"></i>
	</button>
</div>
<div class="d-md-block" style="position:fixed; z-index:1000;right: 0.25rem;top:7.7rem;"title="Vessels" (click)="toggleVes()">
	<button class="btn p-2 btn-icon shadow-lg" [ngClass]="{'btn-dark': showVes, 'btn-light': !showVes}">
		<i class="bx bxs-ship bx-sm"></i>
	</button>
</div>
<div class="d-md-block" style="position:fixed; z-index:1000;right: 0.25rem;top:10.4rem;"title="Vessels" (click)="toggleMap('standard')">
	<button class="btn p-2 btn-icon shadow-lg" title="Standard" [ngClass]="{'btn-dark': mapStyle == 'standard', 'btn-light': mapStyle != 'standard'}">
		<i class="bx bx-map-alt bx-sm"></i>
	</button>
</div>
<div class="d-md-block" style="position:fixed; z-index:1000;right: 0.25rem;top:13.1rem;"title="Vessels" (click)="toggleMap('standard-satellite')">
	<button class="btn p-2 btn-icon shadow-lg" [ngClass]="{'btn-dark': mapStyle != 'standard', 'btn-light': mapStyle == 'standard'}" title="Satellite">
		<i class="bx bx-globe bx-sm"></i>
	</button>
</div>