<!-- start page title -->
<div class="row">
	<div class="col-12">
		<div class="page-title-box d-sm-flex align-items-center justify-content-between">
			<h4 class="mb-sm-0">Multi-vessels Tracking</h4>

		</div>
	</div>
</div>
<!-- end page title -->

<div class="row mb-2">
	<div class="col-lg-12">
		<div class="card">
			<div class="card-body">
				<div class="row g-3">
					<div class="col-xxl col-sm-6">
						<div>
							<select class="form-control ves-opt" (change)="callVessels()" multiple data-choices data-choices-search-false data-choices-removeItem data-choices-text-unique-true>
								<option value="2" selected>Marine Courage</option>
								<option value="1" selected>Marine Test</option>
							</select>
						</div>
					</div><!--end col-->
					<div class="col-xxl-auto col-sm-6">
						<button type="button" class="btn btn-dark w-md" (click)="callVessels()"><i class="bi bi-funnel align-baseline me-1"></i> Filters</button>
					</div><!--end col-->
				</div><!--end row-->
			</div>
		</div>
	</div><!--end col-->
</div><!--end row-->

<div class="row">
	<div class="col-lg-6" *ngFor="let ves of shownVessels">
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col-md-6">
						<h5 class="card-title mb-0 flex-grow-1">{{ ves.Description }}</h5>
					</div>
					<div class="col-md-6 text-right">
						Last Updated: {{ ves.Updated }}
					</div>
				</div>
			</div>
			<div class="card-body">
				<div id="map-{{ves.UnitID}}" style="height: 285px"></div>
			</div>
			<div class="card-footer">
				<div class="row gy-3">
					<div class="col-sm-6 col-lg-6 col-xl-3">
						<div class="text-center border border-dashed p-1 rounded">
							<p class="text-muted mb-2">State</p>
							<h6 class="fs-md mb-0">{{ ves.VesselState }}</h6>
						</div>
					</div>
					<div class="col-sm-6 col-lg-6 col-xl-3">
						<div class="text-center border border-dashed p-1 rounded">
							<p class="text-muted mb-2">Bearing</p>
							<h6 class="fs-md mb-0">{{ ves.Bearing }}</h6>
						</div>
					</div>
					<div class="col-sm-6 col-lg-6 col-xl-3">
						<div class="text-center border border-dashed p-1 rounded">
							<p class="text-muted mb-2">Heading</p>
							<h6 class="fs-md mb-0">{{ ves.Direction }}</h6>
						</div>
					</div>
					<div class="col-sm-6 col-lg-6 col-xl-3">
						<div class="text-center border border-dashed p-1 rounded">
							<p class="text-muted mb-2">Speed</p>
							<!--<span class="badge bg-danger-subtle text-danger mb-0">{{ ves.Speed }}</span>-->
							<h6 class="fs-md mb-0">{{ ves.Speed }}</h6>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div><!--end col-->
</div><!--end row-->